.listing-details-sliders .img-fluid {
    display: inline-block;
    max-height: 450px;
}

.listing-details-sliders .carousel-item {
    text-align: center;
}

.carousel-inner .carousel-control .fa {
    color: #fff;
}