/*------------------------------------------------------------------
Author       : Code-Theme
Template Name: Find Houses - HTML5 Template
Version      : 1.2
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Typography
2. Section Headings
3. Section Recently Properties
4. Section Welcome
5. Section Property Services
6. Section Featured Properties
7. Section Popular Places
8. Section Popular Places 2
9. Section Agents
10.Section Top Location
11.Section Blog
12.Section Testimonials
13.Section Partnes
14.Section Counter Up
15.Section Footer
16.Section Home 1 Image
17.Section Home 3 Image
18.Section Home 4 Image Video
19.Section Home 5 Image
20.Section Home 2, 3 and 4 Search Area
21.Section Home 6 Parallax Image
22.Section Home 7 Video
23.Section Info Sidebar Home 13
24.Section Banner Slider Home 13
25.Section Featured Properties Home 13
26.Section Banner Slider Home 14
27.Section Typed Image Home 15
28.Section Info Home 15
29.Section Featured Properties Home 15
30.Section Info Help Home 15
31.Section Visited Cities Home 15
32.Section Recently Properties Home 16
33.Seccion Parallax Home 17
34.Seccion Info Help Home 17
35.Seccion Agents Home 17
36.Seccion Image Home 18
37.Seccion Why Choose Us Home 18
38.Seccion Testimonials Home 18
39.Seccion Header Slider Home 19
40.Seccion Header Search Home 19
41.Seccion Header Video Home 20
42.Inner Page Properties List
43.Inner Page Properties List Right Sidebar
44.Inner Page Properties Grid
45.Inner Page Property Map
46.Inner Page Property Top Map
47.Inner Page Agents Listing Grid
48.Inner Page Property Details
49.Inner Page Agents Listing Row
50.Inner Page Agents Details
51.Inner Page Dashboard
52.Inner Page Payment-Method
53.Inner Page About Us
54.Inner Page Faq
55.Inner Page Pricing
56.Inner Page 404
57.Inner Page Login
58.Inner Page Coming Soon
59.Inner Page Under Construction
60.Inner Page Blog Grid
61.Inner Page Blog Grid Sidebar
62.Inner Page Blog List Sidebar
63.Inner Page Blog Details
64.Inner Page Contact Us
65.Inner Page Submit Property
66.PreLoader
67.Header Google Maps
68.Header Filter
69.UI Element
70.Page Shop
71.Medias Queries
-------------------------------------------------------------------*/

//-----------------------------------//
//------ COLORS SCHEMES ------
//-----------------------------------//
$text:#666;
$firstcolor:#274abb;
$secondcolor:#121B22;

/*-----------------------------------*/
/*------ 1. TYPOGRAPHY ------*/
/*-----------------------------------*/
html {
    font-size: 15px;
}
body {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $text;
    overflow-x: hidden !important;
}
p {
    line-height: 26px;
}
h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}
h2 {
    font-family: 'Roboto', sans-serif;
    color: #222;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
}
h2 span {
    font-weight: 600;
    color: $firstcolor;
}
h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #333;
    font-weight: 600;
}
h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #333;
    font-weight: 600;
}
h5 {
    color: #444;
}
.top-headings-section {
    padding-bottom: 2rem;
}
.top-headings-section p {
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    color: #555;
    max-width: 550px;
}
.top-headings-section h2::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
*::after,
*::before {
    box-sizing: border-box;
}
.section-title {
    padding-left: 35px;
    position: relative;
    margin-bottom: 2.5rem;
}
.section-title:before {
    top: 0;
    left: 0;
    bottom: 0;
    width: 15px;
    content: '';
    border-radius: 3px;
    position: absolute;
    background-color: $firstcolor;
}
.section-title h3 {
    font-size: 18px;
    color: #111;
    text-transform: uppercase;
}
.section-title h2 {
    font-size: 28px;
    color: $firstcolor;
    text-transform: uppercase;
}
.section-title.col-md-5 {
    margin-left: 1rem;
}
img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.bg-white {
    background: #fff !important;
}
.bg-gray {
    background: #f7f7f7 !important;
}

/*-----------------------------------*/
/*------ 2. SECTION HEADINGS ------*/
/*-----------------------------------*/
.header-top {
    background: $firstcolor;
    color: #7f8997;
    display: block;
    padding: 1rem 0;
}
.header.head-tr {
    position: absolute;
    width: 100%;
    z-index: 99999;
}
.header-top.head-tr {
    background: rgba(255, 255, 255, 0.06);
    color: #7f8997;
    display: block;
    padding: 1rem 0;
}
@media screen and (max-width: 767px) {
    .header-top {
        padding: 0px;
    }
}
.header-top .container {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}
.header-top .the-top {
    display: flex;
    justify-content: space-between;
    padding-right: 60px;
    padding-left: 60px;
}
.top-info {
    display: flex;
}
.header-top p {
    margin-right: 2rem;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
}
.header-top p i {
    color: #fff;
    margin-right: .5rem;
    font-size: 1.3rem;
}
.top-social {
    display: flex;
}
.top-social .login-wrap ul {
    justify-content: flex-end;
    list-style: none;
    margin-bottom: 0px;
}
.top-social .login-wrap ul li {
    padding: 0px 12px;
    position: relative;
}
.top-social .login-wrap ul li a {
    color: #fff;
    text-decoration: none;
    transition: all .5s ease;
}
.top-social .login-wrap ul li a:hover {
    color: #fff;
}
.top-social .login-wrap ul li a i {
    margin-right: 5px;
}
.header-top .social-icons {
    text-align: right;
    margin-left: .6rem;
}
.header-top .social-icons i {
    line-height: 26px;
    font-size: 1.2rem;
    margin-right: 1rem;
    transition: all .5s ease;
}
.social-icons-header .fa-facebook {
    color: #fff;
}
.social-icons-header .fa-twitter {
    color: #fff;
}
.social-icons-header .fa-google-plus {
    color: #fff;
}
.social-icons-header .fa-linkedin {
    color: #fff;
}
.social-icons-header .fa-skype {
    color: #fff;
}
.header-top .social-icons:hover {
    color: $firstcolor;
}
.header-top .dropdown {
    display: inline-block;
    background: transparent
}
.header-top .dropdown button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    transition: all 0.4s ease 0s
}
.header-top .dropdown button:hover {
    color: #fff;
}
.header-top .dropdown .dropdown-menu {
    border-radius: 0;
    left: auto;
    margin: 0;
    min-width: 115px;
    padding: 0px;
    position: absolute;
    right: 0;
    z-index: 9999999
}
.header-top .dropdown-menu img {
    margin-right: 5px;
}
.header-top .dropdown-menu li {
    color: #333;
    cursor: pointer;
    padding: 5px 10px
}
.header-top .dropdown-menu li:hover {
    background: #eee none repeat scroll 0 0
}
.heading {
    background: $secondcolor;
    color: #fff;
    position: relative;
    padding: 1rem 0;
    transition: all .5s ease;
}
.sticky,
.sticky.head-tr {
    animation: 600ms ease-in-out 0s normal none 1 running fadeInDown;
    background: $secondcolor none repeat scroll 0 0;
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}
@media screen and (max-width: 991px) {
    .heading.head-tr {
        background: rgba(255, 255, 255, 0.06);
        padding-bottom: 0px;
    }
    .header.head-tr #main-menu {
        background: #fff;
        width: 100%;
    }
    .header.head-tr #main-menu ul a,
    .header.head-tr #main-menu .po a::after {
        color: #000 !important;
    }
    .sticky.head-tr {
        background: #121B22 none repeat scroll 0 0;
    }
    .header.head-tr .heading .logo img {
        padding-left: 15px;
        padding-bottom: 1rem;
    }
    .header.head-tr .button-menu {
        padding-right: 18px !important;
    }
    .heading.head-tr .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .header.head-tr .search-button,
    .header.head-tr .button-menu {
        margin-bottom: 1rem !important;
    }
}
.heading .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.heading .logo img {
    width: 60px;
}
@media screen and (max-width: 414px) {
    .heading .logo img {
        width: 60px;
    }
}
@media screen and (max-width: 575px) {
    .header.head-tr #bloq-search {
        padding-left: 15px;
        padding-right: 15px;
    }
    .tp-caption.slid-2 {
        padding-top: 7.7rem;
    }
}
.search-button,
.button-menu {
    border: 0;
    font-size: 1.5rem;
    margin: 0;
    padding: 0 .5rem;
    background: transparent;
    color: #fff;
}
.search-button:focus,
.button-menu:focus {
    outline: 0;
}
.search-button {
    color: #fff;
    margin-left: auto;
    margin-right: .8rem;
}
.get-quote {
    background: #fff;
    margin-left: 2rem;
    color: #333;
    padding: 1rem;
    order: 4;
    border-radius: 5px;
    transition: all .5s ease;
}
.get-quote:hover {
    background: #fff;
    color: #fff !important;
}
.get-quote p {
    
    margin: 0px;
    font-weight: 600;
}
.get-quote a {
    text-decoration: none;
    color: #333;
    transition: all .5s ease;
}
.get-quote a:hover {
    color: $firstcolor;
}
#bloq-search {
    flex: 1 1 100%;
}
.bloq-search {
    display: flex;
    height: 3rem;
    margin: 1.3rem 0;
    z-index: 1;
}
.bloq-search input[type="text"] {
    border: 1px solid $firstcolor;
    border-radius: 5px 0 0 5px;
    margin-bottom: 0px;
    padding: 1rem;
    flex: 1 1 auto;
}
.bloq-search input[type="submit"] {
    flex: 1 1 auto;
    border: 0;
    width: auto;
    border-radius: 0 5px 5px 0;
    background: $firstcolor;
    color: #fff;
    cursor: pointer;
    margin-bottom: 0px;
    padding: 0 1rem;
    transition: all .5s ease;
}
.bloq-search input[type="submit"]:hover {
    background: $secondcolor;
    color: #fff;
}
#main-menu {
    flex: 1 1 100%;
}
#main-menu ul {
    padding: 0;
    list-style: none;
    margin: 1rem 0 0 0;
}
#main-menu ul a {
    display: block;
    padding: .8rem 0;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    border-top: 1px solid #e5e5e5;
    text-decoration: none;
}
#main-menu ul a:hover,
#main-menu ul li.active a {
    color: $firstcolor;
}
#main-menu .card {
    background: transparent;
}
#main-menu .card a:hover {
    background: transparent;
}
#main-menu .po a::after {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #fff;
    margin-left: .3rem;
}
.text-heading {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
}
/*------ ARROW DOWN ------*/
.arrow-down a {
    display: inline-block;
    color: #fff;
    font-size: 3rem;
    animation: animate-arrow 2s ease-in-out infinite;
}
@keyframes animate-arrow {
    from {
        transform: translateY(0);
    }
    50% {
        transform: translateY(6px);
    }
    to {
        transform: translateY(0);
    }
}
/*------ HEADER SLIDER ------*/
.rev_slider_wrapper {
    width: 100%;
}
.slotholder {
    position: relative;
}
.slotholder:after {
    background: rgba(0, 0, 0, 0.20);
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
}
.tp-leftarrow {
    transition: all .5s ease;
}
.tp-leftarrow:hover {
    background: $firstcolor;
}
.tp-rightarrow {
    transition: all .5s ease;
}
.tp-rightarrow:hover {
    background: $firstcolor;
}
.tp-parallax-wrap {
    top: 10px;
}
.tp-bannertimer {
    z-index: 21;
}
.mfp-close-btn-in .mfp-close {
    color: #fff;
    font-size: 48px;
}
.mfp-bg {
    z-index: 1112;
}
.mfp-wrap {
    z-index: 1113;
}
.rev_slider a {
    box-shadow: none;
}
.text-theme-colored2 {
    color: #0098ef !important;
}
.tp-caption.font-p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
}
@media screen and(max-width: 496px) {
    .tp-caption.font-p {
        margin-top: .8rem;
    }
}
.btn-default.btn-theme-colored2 {
    color: #fff;
    font-weight: 600;
    background-color: $secondcolor;
    border-color: #fff;
    font-size: 16px;
    padding: 13px 32px;
    margin-top: .8rem;
    margin-right: 1rem;
    line-height: 1.3;
    transition: all .5s ease;
}
.btn-default.btn-theme-colored2:hover {
    background: $firstcolor;
    color: #fff;
    border-color: #fff;
}
.btn-dark.btn-theme-colored {
    color: #fff;
    background-color: $firstcolor;
    border-color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 32px;
    margin-top: .8rem;
    line-height: 1.3;
    transition: all .5s ease;
}
.btn-dark.btn-theme-colored:hover {
    background: $secondcolor;
    color: #fff;
    border-color: #fff;
}
/*------ BUTTOM HOVER EFFECT ------*/
/* Box colors */
.bg-1 {
    background: $firstcolor;
    color: #fff;
    display: inline-block;
    justify-content: flex-start;
    margin: 0px;
}
.bg-2 {
    background: #fff;
    color: #000;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
}
.bg-3 {
    background: transparent;
    color: #e54242;
    display: flex;
    justify-content: center;
    margin: 0px;
}
.bg-4 {
    background: transparent;
    color: #fff;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
/* Common button styles */
.box .button {
    float: left;
    min-width: 150px;
    max-width: 250px;
    display: block;
    margin-bottom: 0px;
    padding: 1em 2em;
    border: 2px solid;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}
.button:focus {
    outline: none;
}
.button > span {
    vertical-align: middle;
}
/* Text color adjustments */
.bg-1 .button {
    color: #fff;
    border-color: #fff;
}
.bg-2 .button {
    color: #000 !important;
    border-color: $firstcolor;
}
.bg-2 .button:hover {
    color: #fff !important;
}
.bg-4 .button {
    color: #fff;
    border-color: transparent;
}
.bg-3 .button {
    color: $firstcolor;
    border-color: $firstcolor;
}
.bg-3 .button:hover {
    border-color: $firstcolor;
    color: #fff;
}
@media screen and (max-width: 768px) {
    .bg-3 {
        padding-top: 1rem;
    }
}
/* Sizes */
.button--size-s {
    font-size: 15px;
    font-weight: bold;
}
.button--size-m {
    font-size: 16px;
}
.button--size-l {
    font-size: 18px;
}
/* Typography and Roundedness */
.button--text-upper {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.button--text-thin {
    font-weight: 300;
}
.button--text-medium {
    font-weight: 500;
}
.button--text-thick {
    font-weight: 600;
}
.button--round-s {
    border-radius: 5px;
}
.button--round-m {
    border-radius: 15px;
}
.button--round-l {
    border-radius: 40px;
}
/* Borders */
.button--border-thin {
    border: 1px solid;
}
.button--border-medium {
    border: 2px solid;
}
.button--border-thick {
    border: 3px solid;
}
/* Wayra */
.button--wayra {
    overflow: hidden;
    cursor: pointer;
    width: 210px;
    transition: border-color 0.3s, color 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: $firstcolor;
    z-index: -1;
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform-origin: 0% 100%;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.button--wayra:hover {
    color: #fff;
    border-color: #fff;
}
.button--wayra.button--inverted:hover {
    color: #fff;
    border-color: $firstcolor;
}
.button--wayra:hover::before {
    opacity: 1;
    background-color: $firstcolor;
    transform: rotate3d(0, 0, 1, 0deg);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra.button--inverted:hover::before {
    background-color: $firstcolor;
}
.portfolio .box a {
    background: $secondcolor;
    text-decoration: none;
    text-align: center;
}
/* Moema */
.button--moema {
    padding: 1.5em 3em;
    border-radius: 50px;
    background: #7986cb;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
}
.button--moema.button--inverted {
    background: #ECEFF1;
    color: #37474f;
}
.button--moema::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    background: inherit;
    border-radius: 50px;
    z-index: -1;
    opacity: 0.4;
    transform: scale3d(0.8, 0.5, 1);
}
.button--moema:hover {
    transition: background-color 0.1s 0.3s, color 0.1s 0.3s;
    color: #ECEFF1;
    background-color: #3f51b5;
    animation: anim-moema-1 0.3s forwards;
}
.button--moema.button--inverted:hover {
    color: #ECEFF1;
    background-color: #7986cb;
}
.button--moema:hover::before {
    animation: anim-moema-2 0.3s 0.3s forwards;
}
@-webkit-keyframes anim-moema-1 {
    60% {
        transform: scale3d(0.8, 0.8, 1);
    }
    85% {
        transform: scale3d(1.1, 1.1, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}
@keyframes anim-moema-1 {
    60% {
        transform: scale3d(0.8, 0.8, 1);
    }
    85% {
        transform: scale3d(1.1, 1.1, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}
@-webkit-keyframes anim-moema-2 {
    to {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }
}
@keyframes anim-moema-2 {
    to {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }
}

/*------------------------------------*/
/*-- 3. SECTION RECENTLY PROPERTIES --*/
/*------------------------------------*/
section.recently.portfolio {
    padding: 6rem 0;
    background: #f7f7f7;
}
.portfolio .sec-title {
    margin-left: 0;
}
.portfolio .sec-title h2 span {
    font-weight: 600;
    color: #111;
}
.portfolio .sec-title h2 {
    color: $firstcolor;
    margin-bottom: .5rem;
}
.homes.no-mb {
    margin-bottom: 0px;
}
.homes-img {
    display: block;
    transition: transform 0.6s ease-in;
}
.homes,
.homes-img {
    position: relative;
    overflow: hidden;
}
.homes-tag.featured {
    width: 84px !important;
    background: #18ba60 !important;
}
.recently .homes .button {
    font-size: 13px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    color: #fff;
    border: none;
    background-color: $firstcolor;
    display: inline-block;
    z-index: 99;
}
.recently .homes-tag.featured {
    left: 15px;
    margin-top: 15px;
}
.recently .homes-price,
.recently .homes-tag {
    position: absolute;
    z-index: 99;
}
.recently .homes-tag.sale {
    background: #e54242 !important;
    width: 84px !important;
    height: 31px;
}
.recently .homes-tag.sale {
    right: 15px;
    margin-top: 15px;
    top: 0;
}
.recently .homes-tag.sale.rent {
    background: #ffb200 !important;
    color: #fff;
    z-index: 99;
}
.recently .price-properties {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.recently .compare {
    margin-top: 1.1rem;
    line-height: 0px;
}
.recently .compare a {
    margin-right: 1.5rem;
}
.recently .compare a:last-child {
    margin-right: 0px;
}
.recently .compare i {
    font-size: 1rem;
    transition: all .5s ease;
}
.recently .fa-exchange-alt {
    color: #666;
}
.recently .fa-exchange-alt:hover {
    color: #18ba60;
}
.recently .fa-share-alt {
    color: #666;
}
.recently .fa-share-alt:hover {
    color: #ffb200;
}
.recently .fa-heart-o {
    color: #666;
}
.recently .fa-heart-o:hover {
    color: #e54242;
}
.recently .homes-price {
    color: #fff;
    font-size: 13px !important;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    background: #3a2c5f;
    bottom: 15px;
    left: 15px;
    z-index: 99;
}
.recently .homes-content {
    background: #fff !important;
    padding: 20px 20px !important;
}
.recently .homes-content h3.title a {
    font-size: 1.1rem;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    text-decoration: none;
}
.recently .homes-content h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 3px;
}
.price-properties h3 {
    margin-bottom: 0px;
}
.recently .homes-content .homes-address {
    margin-top: 0px;
}
.recently .homes-content .homes-address .fa-map-marker {
    font-size: 1rem;
    text-decoration: none;
    color: #18ba60;
}
.recently .homes-content .homes-address a span {
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 1px;
    text-decoration: none;
}
.recently .homes-address span {
    color: $text;
}
.recently .homes-content .homes-address a i {
    margin-right: 10px;
}
.recently .homes-content .homes-list {
    padding: 0;
    margin: 0;
}
.recently .homes-content .homes-list li {
    width: 45%;
    float: left;
    font-size: 14px;
    line-height: 40px;
    list-style: none;
    color: $firstcolor;
}
.recently .homes-content .homes-list li i {
    font-size: 1rem;
    margin-right: 5px;
    color: $firstcolor;
}
.recently .homes-content .homes-list span {
    color: $text;
}
.recently.portfolio .item {
    padding-bottom: 0px;
}
.recently.portfolio .homes {
    margin-bottom: 0px;
}
.recently .footer {
    background: none;
    border-top: 1px solid #ebebeb;
    padding-top: 1rem;
    font-size: 13px;
    color: $text;
}
.recently .footer a {
    color: $text;
    text-decoration: none;
}
.recently .footer span {
    float: right;
}
.recently .footer i {
    margin-right: 5px;
    font-size: 1rem;
    color: $text;
}
.hidden.photo-gallery {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
}
/*----- HOVER EFFECT -----*/
.recently .project-single {
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.recently .project-inner {
    position: relative;
    overflow: hidden;
}
.recently .project-head {
    position: relative;
    text-align: center;
}
.recently .project-head:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    z-index: 33;
    background: $secondcolor;
    color: #fff;
    text-align: center;
    transition: all .5s ease;
}
.recently .project-single:hover .project-head:before {
    opacity: 0.8;
}
.recently .project-head img {
    width: 100%;
    z-index: 2;
}
.recently .project-bottom {
    text-align: center;
    padding: 10px 0 0;
    position: absolute;
    width: 100%;
    text-align: left;
    z-index: 333;
    text-align: center;
    top: 50%;
    margin-top: -23.5px;
}
.recently .project-bottom h4 {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 800;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
}
.recently .project-bottom h4:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
}
.recently .project-single:hover .project-bottom h4 {
    opacity: 1;
    visibility: visible;
}
.recently .project-bottom h4 a {
    color: #fff;
    text-decoration: none;
}
.recently .project-bottom h4 span {
    display: block;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
}
.recently .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    padding: 8px 15px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.recently .project-single:hover .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.recently .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 100%;
    width: 31px;
    height: 31px;
    line-height: 31px;
    background: #fff;
    z-index: 99;
}
.recently .button-effect .btn:hover {
    color: #fff;
}
.recently .button-effect .btn:last-child {
    margin: 0;
}
.recently .load-button {
    margin-top: 50px;
}
.recently .load-button .btn {
    padding: 14px 30px;
    color: #fff;
    font-weight: 500;
    border: none;
    transition: all 0.4s ease;
    margin: 0;
}
.recently .load-button .btn:hover {
    background: #353535;
}
.recently .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #000;
}
.recently .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #000;
}
.recently .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #000;
}
.recently.portfolio .item {
    padding-bottom: 0;
}
.recently.portfolio .project-single {
    margin-bottom: 0px;
    box-shadow: none;
}
.recently.portfolio.bshd .project-single {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.listing-hidden-content.blue {
    background: $firstcolor;
}
/*----- SLICK CAROUSEL -----*/
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: $secondcolor;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    display: block;
    top: calc(50% - 15px);
    transform: translateY(-50%);
    transition: 0.3s;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    z-index: 10;
}
.slick-prev:hover,
.slick-next:hover {
    color: transparent;
    outline: none;
    background: $firstcolor;
}
.slick-prev:hover:before,
.slick-next:hover:before {
    opacity: 1;
    outline: none;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    pointer-events: none;
    cursor: default;
    background-color: #e8e8e8;
    box-shadow: none;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    color: $secondcolor;
}
.slick-prev:before,
.slick-next:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    font-size: 26px;
    line-height: 1;
    opacity: 1;
    transition: 0.3s;
    position: relative;
    color: #fff;
}
.slick-prev {
    left: -85px;
}
.slick-prev:before {
    content: '\f053';
    left: -1px;
}
.slick-next {
    right: -85px;
}
.slick-next:before {
    content: '\f054';
    right: -1px;
}
/* Dots */
.slick-dots {
    margin: 0 auto;
    padding: 0;
    text-align: center;
}
ul.slick-dots {
    margin-top: 20px;
    list-style-type: disc;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 3px;
    cursor: pointer;
}
.slick-dots li {
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: $firstcolor;
    border: 0;
    position: relative;
    outline: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 3px;
    opacity: 1;
    border-radius: 50%;
    line-height: 0;
    box-shadow: inset 0 0 0 2px $firstcolor;
    transition: all 0.3s;
}
.slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px $firstcolor;
    transition: all 0.3s;
}
.slick-dots li:after {
    display: block;
    height: 13px;
    width: 13px;
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.3s;
    opacity: 0;
    background-color: $firstcolor;
}
.slick-dots li.slick-active:after {
    opacity: 1;
}
.slick-dots button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    cursor: pointer;
}
.agents-grid {
    position: relative;
    background: #ffffff;
    margin: 0 30px 0 0;
    border-radius: 6px;
    border: none;
}
@media screen and (max-width: 767px) {
    .recently.portfolio .item.no-pb.pbp-3 {
        padding-bottom: 1.6rem !important;
    }
    .agents-grid {
        margin-right: 0px;
    }
    .recently.portfolio .item.landscapes.no-pb {
        padding-bottom: 0px;
        margin-bottom: 10px !important;
    }
    .recently.portfolio .item.landscapes.no-pb:last-child {
        margin-bottom: 0px !important;
    }
}
@media screen and (max-width: 991px) {
    .recently.portfolio .item {
        padding-bottom: 1.6rem;
    }
    .recently.portfolio .project-single {
        margin-bottom: 10px;
    }
    .recently.portfolio .item.no-pb {
        padding-bottom: 0px;
    }
    .recently.portfolio .project-single.no-mb {
        margin-bottom: 0px;
    }
    .recently.portfolio .item.landscapes.no-pb {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .recently .compare {
        margin-top: .8rem;
        margin-right: .2rem;
    }
}
@media screen and (max-width: 420px) {
    .recently .compare {
        margin-right: 0;
    }
}

/*-----------------------------------*/
/*------ 4. SECTION WELCOME ------*/
/*-----------------------------------*/
section.welcome {
    padding: 6rem 0;
    background: #fff;
}
.welcome-title h2 {
    color: $secondcolor;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: .8rem;
}
.welcome-img {
    text-align: right;
}
.welcome-title h2 span,
.welcome-title h2 span {
    color: $firstcolor;
    font-weight: 800;
}
.welcome-title h4 {
    color: #000;
    font-size: 22px;
    text-transform: uppercase;
}
.welcome-title {
    padding-bottom: 17px;
}
.welcome-content > p {
    color: $text;
    font-weight: 400;
}
.welcome-content > p span {
    color: $text;
    font-size: 16px;
    font-weight: 600;
}
.services-img,
.services-desc {
    display: inline-block;
}
.services-img {
    float: left;
    margin-right: 16px;
}
.services-desc > h6 {
    color: #555;
    font-weight: 600;
}
.services-desc > p {
    color: $text;
    font-weight: 400;
    margin: 0;
}
.w-single-services {
    margin-bottom: 31px;
}
.w-single-services.no-mb {
    margin-bottom: 0px;
}
.welcome-services {
    padding-top: 34px;
}
.single-services .welcome-title {
    padding-top: 43px;
}
.single-services .welcome-content {
    padding-top: 17px;
}
.welcome .popup-youtube {
    background: #fff none repeat scroll 0 0;
    border-radius: 100%;
    color: $firstcolor;
    font-size: 30px;
    height: 70px;
    left: 50%;
    line-height: 70px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    z-index: 9;
}
.welcome .popup-youtube i {
    margin-left: 5px
}
.welcome .wprt-image-video .iq-waves {
    height: 352px;
    height: 22rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 352px;
    width: 22rem;
    z-index: 2;
}
.welcome .wprt-image-video .iq-waves .waves {
    animation: 3s ease-in-out 0s normal none infinite running waves;
    background: rgb(39, 74, 187) none repeat scroll 0 0 padding-box;
    border-radius: 320px;
    height: 352px;
    height: 22rem;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    width: 352px;
    width: 22rem;
}
.welcome .wprt-image-video .iq-waves .wave-1 {
    animation-delay: 0s;
}
.welcome .wprt-image-video .iq-waves .wave-2 {
    animation-delay: 1s;
}
.welcome .wprt-image-video .iq-waves .wave-3 {
    animation-delay: 2s;
}
@-webkit-keyframes waves {
    0% {
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        transform: scale(0.6, 0.6);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
@keyframes waves {
    0% {
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        transform: scale(0.6, 0.6);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
@media screen and (max-width: 991px) {
    .welcome .wprt-image-video {
        margin-top: 2.5rem;
    }
}
@media screen and (max-width: 767px) {
    .w-single-services.no-mb.mbx {
        margin-bottom: 31px;
    }
}
@media screen and (max-width: 575px) {
    .w-single-services.no-mb.mbx {
        margin-bottom: 31px;
    }
    .welcome-title h2 {
        font-size: 20px;
    }
    .welcome-title h4 {
        font-size: 16px;
    }
    .welcome-content > p span {
        font-size: 15px;
    }
}

/*------------------------------------*/
/*--- 5. SECTION PROPERTY SERVICES ---*/
/*------------------------------------*/
section.services-home {
    padding: 6rem 0;
    background-attachment: fixed;
}
.services-home .service .media {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 50px;
    height: 75px;
    margin-top: -40px;
    width: 75px;
}
.services-home .service .media img {
    width: 100%;
}
.services-home .service .media i {
    font-size: 34px;
    width: 100%;
    text-align: center;
    height: 100%;
    line-height: 65px;
}
.services-home .bg-base {
    background: $firstcolor;
}
.services-home .text-white {
    color: #ffffff !important;
}
.services-home .box-shadow-1,
.box-shadow-1-hover:hover,
.box-shadow-1:focus,
.box-shadow-1.focus {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.06) !important;
}
.services-home .rounded-100 {
    border-radius: 100px;
}
.services-home .p-left-5 {
    padding-left: 5px !important;
}
.services-home .p-bottom-5 {
    padding-bottom: 5px !important;
}
.p-right-5 {
    padding-right: 5px !important;
}
.services-home .p-top-5 {
    padding-top: 5px !important;
}
.services-home .p-left-25 {
    padding-left: 25px !important;
}
.services-home .p-right-25 {
    padding-right: 25px !important;
}
.services-home .p-top-35 {
    padding-top: 35px !important;
}
.services-home .p-bottom-30 {
    padding-bottom: 30px !important;
}
.services-home .blog-section,
.agent-section,
.agency-section,
.profile-section {
    display: inline-block;
    width: 100%;
}
.services-home .text-bold-700 {
    color: #fff;
    font-weight: 600;
}
.services-home .m-bottom-15 {
    margin-bottom: 15px;
}
.services-home .agent-section p {
    color: #fff;
}
.services-home a.text-base {
    color: #fff;
    text-decoration: none;
    transition: all .5s ease;
}
.services-home .border-light {
    border-color: rgba(234, 234, 234, 1) !important;
}
.services-home .border-1 {
    border-style: solid;
    border-width: 1px !important;
}
.services-home .badge-light-2,
.bg-light-2 {
    background: $secondcolor;
}
.services-home a.text-base:hover {
    color: $firstcolor;
}
@media screen and (max-width: 991px) {
    .services-home .m-bottom-40 {
        margin-top: 4.5rem;
    }
    .services-home .m-top-0 {
        margin-top: 0px;
    }
}

/*------------------------------------*/
/*-- 6. SECTION FEATURED PROPERTIES --*/
/*------------------------------------*/
section.portfolio {
    padding: 6rem 0;
}
.portfolio .sec-title {
    margin-left: 0;
}
.portfolio .sec-title h2 span {
    font-weight: 600;
    color: #111;
}
.portfolio .sec-title h2 {
    color: $firstcolor;
    margin-bottom: .5rem;
}
.portfolio .item {
    padding-bottom: 1.6rem;
}
.portfolio .filters-group {
    margin-top: 1.4rem;
    margin-bottom: 0px;
    padding-left: 0px;
    text-align: right;
    border-radius: 5px;
}
.portfolio .filters-group ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
.portfolio .filters-group ul li {
    list-style: none;
    color: #000;
    display: inline-block;
    padding: 10px 15px;
    
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    transition: all .5s ease;
}
.portfolio .filters-group ul li:hover {
    background: $firstcolor;
    color: #fff;
}
@media screen and (max-width: 543px) {
    .portfolio .filters-group ul {
        padding-left: 0px;
    }
    .portfolio .filters-group ul li:hover {
        background: none;
        color: #000;
    }
}
.portfolio .filters-group .active {
    background: $secondcolor;
    color: #fff;
}
.project-single {
    margin-bottom: 10px;
}
.project-single.no-mb {
    margin-bottom: 0px;
}
.portfolio .item.no-pb {
    padding-bottom: 0px;
}
@media screen and (max-width: 767px) {
    .project-single.no-mb.last:last-child {
        margin-bottom: 0px;
    }
    .portfolio .filters-group {
        text-align: left;
        margin-bottom: 2rem;
    }
    .filters-group.col-md-6 {
        margin-left: 1rem;
    }
    .portfolio .item.sale.no-pb {
        padding-bottom: 1.6rem;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 991px) {
    .portfolio .item.landscapes.no-pb {
        padding-bottom: 1.6rem;
        margin-bottom: 10px;
    }
    .portfolio .item.landscapes.h-17.no-pb {
        padding-bottom: 30px;
        margin-bottom: 0px;
    }
    .project-single.mbx-10 {
        margin-bottom: 10px;
    }
    .featured .compare {
        margin-top: .9rem !important;
    }
}
.homes-img {
    display: block;
}
.homes,
.homes-img {
    position: relative;
    overflow: hidden;
}
.homes-tag.featured {
    width: 84px !important;
    background: #18ba60 !important;
}
.portfolio .homes .button {
    font-size: 13px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    color: #fff;
    border: none;
    background-color: $firstcolor;
    display: inline-block;
    z-index: 99;
}
.portfolio .homes-tag.featured {
    left: 15px;
    margin-top: 15px;
}
.portfolio .homes-price,
.portfolio .homes-tag {
    position: absolute;
    z-index: 99;
}
.portfolio .homes-tag.sale {
    background: #e54242 !important;
    width: 84px !important;
    height: 31px;
}
.portfolio .homes-tag.sale {
    right: 15px;
    margin-top: 15px;
    top: 0;
}
.portfolio .homes-tag.sale.rent {
    background: #ffb200 !important;
    color: #fff;
    z-index: 99;
}
.portfolio .price-properties {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.portfolio .compare {
    margin-top: 1.1rem;
    line-height: 0px;
}
.portfolio .compare a {
    margin-right: 1.5rem;
}
.portfolio .compare a:last-child {
    margin-right: 0px;
}
.portfolio .compare i {
    font-size: 1rem;
    transition: all .5s ease;
}
.portfolio .fa-exchange-alt {
    color: #666;
}
.portfolio .fa-exchange-alt:hover {
    color: #18ba60;
}
.portfolio .fa-share-alt {
    color: #666;
}
.portfolio .fa-share-alt:hover {
    color: #ffb200;
}
.portfolio .fa-heart-o {
    color: #666;
}
.portfolio .fa-heart-o:hover {
    color: #e54242;
}
.portfolio .homes-price {
    color: #fff;
    font-size: 13px !important;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    background: #3a2c5f;
    bottom: 15px;
    left: 15px;
    z-index: 99;
}
.portfolio .homes-content {
    background: #fff !important;
    padding: 20px 20px !important;
}
.portfolio .homes-content h3.title a {
    font-size: 1.1rem;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    text-decoration: none;
}
.portfolio .homes-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 3px;
}
.portfolio .homes-content h3 a {
    font-size: 1.2rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 3px;
    text-decoration: none;
    transition: all .3s ease;
}
.portfolio .homes-content h3 a:hover {
    color: $firstcolor;
}
.price-properties h3 {
    margin-top: 1.3 !important;
    margin-bottom: 0px;
}
.portfolio .homes-content .homes-address {
    margin-top: 0px;
}
.portfolio .homes-content .homes-address .fa-map-marker {
    font-size: 1rem;
    text-decoration: none;
    color: #18ba60;
}
.portfolio .homes-content .homes-address a {
    text-decoration: none;
}
.portfolio .homes-content .homes-address a span {
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 1px;
    text-decoration: none;
}
.portfolio .homes-address span {
    color: $text;
}
.portfolio .homes-content .homes-address a i {
    margin-right: 10px;
}
.portfolio .homes-content .homes-list {
    padding-left: 0px;
    padding-bottom: .9rem;
    margin: 0;
}
.portfolio .homes-content .homes-list li {
    width: 45%;
    float: left;
    font-size: 14px;
    line-height: 40px;
    list-style: none;
    color: $firstcolor;
}
.portfolio .homes-content .homes-list li i {
    font-size: 1rem;
    margin-right: 5px;
    color: $firstcolor;
}
.portfolio .homes-content .homes-list span {
    color: $text;
}
.portfolio .footer {
    background: none;
    border-top: 1px solid #e8e8e8;
    padding-top: 1rem;
    font-size: 13px;
    color: $text;
}
.portfolio .footer a {
    color: $text;
    text-decoration: none;
}
.portfolio .footer span {
    float: right;
}
.portfolio .footer i {
    margin-right: 5px;
    font-size: 1rem;
    color: $text;
}
/*----- HOVER EFFECT -----*/
.portfolio .project-single {
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.portfolio .project-inner {
    position: relative;
    overflow: hidden;
}
.portfolio .project-head {
    position: relative;
    text-align: center;
}
.portfolio .project-head:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    z-index: 33;
    background: $secondcolor;
    color: #fff;
    text-align: center;
    transition: all .5s ease;
}
.portfolio .project-single:hover .project-head:before {
    opacity: 0.8;
}
.portfolio .project-head img {
    width: 100%;
    z-index: 2;
}
.portfolio .project-bottom {
    text-align: center;
    padding: 10px 0 0;
    position: absolute;
    width: 100%;
    text-align: left;
    z-index: 333;
    text-align: center;
    top: 50%;
    margin-top: -23.5px;
}
.portfolio .project-bottom h4 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
}
.portfolio .project-bottom h4:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
}
.portfolio .project-single:hover .project-bottom h4 {
    opacity: 1;
    visibility: visible;
}
.portfolio .project-bottom h4 a {
    color: #fff;
    text-decoration: none;
}
.portfolio .project-bottom h4 span {
    display: block;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
}
.portfolio .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    padding: 8px 15px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.portfolio .project-single:hover .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.portfolio .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 100%;
    width: 31px;
    height: 31px;
    line-height: 31px;
    background: #fff;
    z-index: 99;
}
.portfolio .button-effect .btn:hover {
    color: #fff;
}
.portfolio .button-effect .btn:last-child {
    margin: 0;
}
.portfolio .load-button {
    margin-top: 50px;
}
.portfolio .load-button .btn {
    padding: 14px 30px;
    color: #fff;
    font-weight: 500;
    border: none;
    transition: all 0.4s ease;
    margin: 0;
}
.portfolio .load-button .btn:hover {
    background: #353535;
}
.portfolio .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #000;
}
.portfolio .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #000;
}
.portfolio .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #000;
}
/*------ BUTTOM HOVER EFFECT ------*/
.bg-all {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transition: all .3s ease;
}
.btn.btn-outline-light {
    background-color: $firstcolor;
    color: #fff;
    border-color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 3rem;
    padding: .9rem 2.5rem .9rem 2rem;
    transition: all .3s ease;
}
.btn.btn-outline-light:hover {
    background: $secondcolor;
    color: #fff;
    transition: all .3s ease;
}
.btn.btn-outline-light:after {
    content: '\f0a9';
    font-family: 'FontAwesome';
    position: relative;
    left: .5rem;
    transition: all .2s ease;
}
.btn.btn-outline-light:hover:after {
    left: 1rem;
    color: #fff;
    transform: rotateZ(-360deg);
}

/*------------------------------------*/
/*--- 7. SECTION POPULAR PLACES ---*/
/*------------------------------------*/
section.popular-places {
    padding: 6rem 0;
    background: #fff;
}
.popular-places .img-box {
    height: 350px;
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1.5rem;
    z-index: 90;
    border-radius: 3px;
}
.popular-places .img-box.no-mb {
    margin-bottom: 0px;
}
.popular-places .img-box:hover {
    background: $firstcolor;
}
.popular-places .img-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-color: $secondcolor;
    opacity: 0.2;
    z-index: 99;
    transition: 0.3s ease-out;
}
.popular-places .listing-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    overflow: hidden;
    height: 100px;
}
.popular-places .listing-badges .featured {
    background-color: #18ba60;
    float: left;
    transform: rotate(-45deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 12.5px;
    margin: 0;
}
.popular-places .listing-badges span {
    color: #fff;
    display: inline-block;
    padding: 1px 10px;
    float: right;
    background-color: rgba(74, 74, 76, 0.7);
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute;
}
.popular-places .img-box-content {
    position: absolute;
    z-index: 101;
    top: 50%;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    transform: translate(0, -50.5%);
}
.popular-places .img-box-content h4 {
    font-size: 24px;
    line-height: 36px;
    color: #fff;
    margin: 0;
}
.popular-places .img-box-content span {
    font-size: 16px;
    color: #fff;
}
.popular-places .img-box img {
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
    transition: all 0.55s;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    transition: transform 0.35s ease-out;
}
@media screen and (max-width: 767px) {
    .popular-places .img-box.no-mb {
        margin-bottom: 1.5rem;
    }
    .popular-places .img-box.no-mb.x3 {
        margin-bottom: 0px;
    }
}
/*----- HOVER EFFECT -----*/
.popular-places .hover-effect img {
    transform: scale(1);
    transition: all .8s ease;
}
.popular-places .hover-effect:hover img {
    transform: scale(1.3);
}

/*--------------------------------------*/
/*--- 8. SECTION POPULAR PLACES 2 ---*/
/*--------------------------------------*/
section.feature-cities-1 {
    padding: 6rem 0;
    background: #f5f6ff;
}
.feature-cities-1 .img-box {
    height: 290px;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    z-index: 90;
    border-radius: 3px;
}
.feature-cities-1 .col-lg-4.col-md-6 a {
    padding: 20px;
}
.feature-cities-1 .img-box.no-mb {
    margin-bottom: 0px;
}
.feature-cities-1 .img-box:hover {
    background: $firstcolor;
}
.feature-cities-1 .img-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    display: block;
    content: "";
    background-color: $secondcolor;
    opacity: 0.3;
    z-index: 99;
    transition: 0.3s ease-out;
}
.feature-cities-1 .listing-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 0px;
    overflow: hidden;
    height: 100px;
}
.feature-cities-1 .listing-badges .featured {
    background-color: $firstcolor;
    float: right;
    border-radius: 20px;
    right: 23px;
    top: 23px;
    font-weight: 400;
    position: relative;
    text-align: center;
    width: 120px;
    font-size: 15px;
    margin: 0;
}
.feature-cities-1 .listing-badges span {
    color: #fff;
    display: inline-block;
    padding: 5px;
    float: right;
    background-color: rgba(74, 74, 76, 0.9);
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute;
}
.feature-cities-1 .img-box-content {
    position: absolute;
    z-index: 101;
    top: 73%;
    margin: 0 auto;
    text-align: left;
    transform: translate(0, -50.5%);
}
.feature-cities-1 .img-box-content h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: #fff;
}
.feature-cities-1 .img-box-content span,
.feature-cities-1 .img-box-content pre {
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #fff;
}
.feature-cities-1 .img-box img {
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all 0.55s;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    transition: transform 0.35s ease-out;
}
/*----- HOVER EFFECT -----*/
.feature-cities-1 .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    z-index: 2
}
.hover-effect img {
    transform: scale(1);
    transition: all .8s ease;
}
.hover-effect:hover img {
    transform: scale(1.3);
}
@media screen and (max-width: 767px) {
    .feature-cities-1 .img-box.no-mb,
    .feature-cities-1 .img-box {
        margin-bottom: 30px;
    }
    .feature-cities-1 .mt {
        margin-top: 30px;
    }
    .feature-cities-1 .img-box.no-mb.x3 {
        margin-bottom: 0px;
    }
    .feature-cities-1 .img-box {
        height: 350px;
    }
    .feature-cities-1 .img-box img {
        width: 100%;
    }
}
@media screen and (max-width: 450px) {
    .feature-cities-1 .img-box img,
    .feature-cities-1 .img-box {
        height: 286px;
    }
}

/*-----------------------------------*/
/*------ 9. SECTION OUR AGENTS ------*/
/*-----------------------------------*/
section.team {
    padding: 6rem 0;
    background: #f6f6f6;
}
.team .sec-title {
    margin-left: 1rem;
}
.team .sec-title h2 span {
    font-weight: 600;
    color: #111;
}
.team .sec-title h2 {
    color: $firstcolor;
    margin-bottom: .5rem;
}
.team .team-all {
    padding: 0;
    margin: 0;
}
.team-wrap {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.team-img {
    position: relative;
    z-index: 9;
}
.team-img img {
    width: 100%;
}
.team-content {
    height: 80px;
    width: 100%;
    bottom: 0;
    border: 1px solid #ccc;
    position: absolute;
    text-align: center;
    overflow: hidden;
    transition: all 0.6s;
    z-index: 9;
    background: #fff;
}
.team-wrap:hover .team-content {
    height: 150px;
}
.team-info {
    background: #fff;
    padding: 20px;
    transition: all .3s;
}
.team-wrap:hover .team-content .team-info {
    background: #fff;
}
.team-content h3 {
    
    margin-bottom: 0px;
    color: #444;
    font-size: 16px;
}
.team-content p {
    color: $text;
    font-style: italic;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
}
.team-content ul {
    opacity: 0;
    display: flex;
    justify-content: center;
    transform: translateY(100%);
    backface-visibility: hidden;
    transition: transform 0.6s, opacity 0.4s;
}
.team-wrap:hover .team-content ul {
    opacity: 1;
    transform: translateY(0px);
    transition: transform 0.6s, opacity 0.4s;
    margin-bottom: 10px;
}
.team-content ul li {
    opacity: 0;
    padding: .3rem 0;
    transform: translateY(40px);
    transition: transform 0.6s, opacity 0.1s;
    display: inline-block;
}
.team-wrap:hover .team-content ul li {
    opacity: 1;
    transform: translateY(0px);
}
.team-wrap:hover .team-content ul li:nth-child(1) {
    transition-delay: 100ms;
}
.team-wrap:hover .team-content ul li:nth-child(2) {
    transition-delay: 200ms;
}
.team-wrap:hover .team-content ul li:nth-child(3) {
    transition-delay: 300ms;
}
.team-wrap:hover .team-content ul li:nth-child(4) {
    transition-delay: 400ms;
}
.team-wrap:hover .team-content ul li:nth-child(5) {
    transition-delay: 500ms;
}
.team-socials ul li a {
    display: flex;
}
.team-content span a {
    color: #000;
    transition: all 1s;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
}
.team-wrap:hover .team-content span a {
    bottom: 6px;
}
.team-content span a:hover {
    color: $secondcolor;
}
.team .team-pro ul {
    padding: 0;
    margin: 0;
}
.team .team-pro ul li {
    list-style: none;
    display: flex;
    justify-content: center;
}
.team .team-pro .fa {
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    margin: 0 .5rem;
    width: 2.1rem;
    height: 2.1rem;
    line-height: 2.1rem;
}
.team .team-all.padding {
    padding-bottom: 2.5rem;
}
@media screen and (max-width: 991px) {
    .team .team-pro {
        padding-bottom: 2.5rem;
    }
    .team .team-pro:last-child {
        padding-bottom: 0px;
    }
    .team .pb-none {
        padding-bottom: 0px;
    }
    .team .team-all.padding {
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 767px) {
    .team .pb-none {
        padding-bottom: 2.5rem;
    }
}
.team .team-pro .fa.fa-facebook {
    background: #3b5998;
}
.team .team-pro .fa.fa-twitter {
    background: #1da1f2;
}
.team .team-pro .fa.fa-instagram {
    background: #e1306c;
}

/*------------------------------------*/
/*----- 10. SECTION TOP LOCATION -----*/
/*------------------------------------*/
section.top-location {
    padding: 6rem 0;
    background: #fff;
    text-align: center;
}
.top-location .item {
    position: relative;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
}
.top-location .item:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(16, 37, 46, 0) 0%, rgba(16, 37, 46, 1) 150%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
}
.top-location .tp-caption {
    position: absolute;
    left: 0;
    bottom: -50px;
    padding: 20px;
    width: 100%;
    z-index: 99;
    transition: all ease-in-out 0.3s;
}
.top-location .tp-caption h6 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 5px;
}
.top-location .tp-caption strong {
    color: #fff;
    font-weight: 400;
    margin: 0 0 10px;
    display: block;
}
.top-location .tp-caption p {
    color: #18ba60;
    font-weight: bold;
    opacity: 0;
    transition: all ease-in-out 0.3s;
}
.top-location .item:hover .tp-caption {
    bottom: 0px;
}
.top-location .item:hover .tp-caption p {
    opacity: 1;
}
.top-location h4 {
    font-weight: 600;
    font-size: 1.2rem;
    top: 27px;
    z-index: 9;
    color: #fff;
    margin: 0px !important;
    padding: 0px !important;
    line-height: 0px !important;
    position: relative;
}
.top-location div#tp-carousel {}
.top-location .owl-theme .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 9999;
    width: 18%;
    margin: auto;
    background: $secondcolor;
    height: 54px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    overflow: hidden;
}
@media screen and (max-width: 991px) {
    .top-location .owl-theme .owl-nav {
        width: 50%;
    }
}
@media screen and (max-width: 575px) {
    .top-location .owl-theme .owl-nav {
        width: 70%;
    }
}
@media screen and (max-width: 400px) {
    .top-location h4 {
        font-size: .9rem;
    }
}
.top-location .owl-theme .owl-nav [class*=owl-] {
    margin: 0;
    padding: 0;
    background: $firstcolor;
    display: inline-block;
    cursor: pointer;
    border-radius: 0;
    font-size: 0;
}
.top-location .owl-prev {
    width: 54px;
    height: 54px;
    background: #fff;
    position: relative;
    float: left;
    border-right: 1px solid $secondcolor;
}
.top-location .owl-prev:after {
    content: "\f104";
    font-family: FontAwesome;
    font-size: 20px;
    color: #fff !important;
}
.top-location .owl-next {
    width: 54px;
    height: 54px;
    background: #fff;
    position: relative;
    float: right;
    border-left: 1px solid $secondcolor;
}
.top-location .owl-prev:after {
    content: "\f104";
    font-family: FontAwesome;
    font-size: 36px;
    color: #fff !important;
    line-height: 52px;
}
.top-location .owl-next:after {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 36px;
    color: #fff !important;
    line-height: 52px;
}

/*------------------------------------*/
/*--------- 11. SECTION BLOG ---------*/
/*------------------------------------*/
section.blog-section {
    padding: 6rem 0;
    background: #f7f7f7;
}
.blog-section .news-item {
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.blog-section .news-item.-news-page {
    display: flex;
    flex-direction: column;
}
.blog-section .news-item-descr.big-news {
    height: 106px;
}
.blog-section .news-item h3 {
    color: #222;
    transition: all .25s;
}
.blog-section .news-item.-news-slider-item {
    height: 100%;
}
.blog-section .news-item.-news-slider {
    height: auto;
}
.blog-section .news-item a:hover {
    text-decoration: none;
}
.blog-section .news-item a:hover h3 {
    color: $firstcolor;
    transition: all .25s;
}
.blog-section .news-item .news-img-link.-news-page {
    display: block;
    position: relative;
    padding-bottom: 69%;
    width: 100%;
}
.blog-section .news-item .news-img-link.-news-page .resp-img {
    background-size: cover;
}
.blog-section .resp-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.blog-section .news-item-img .resp-img {
    background-size: cover;
}
.blog-section .news-item-img {
    position: relative;
    width: 100%;
}
.blog-section .news-item-img .resp-img {
    background-size: cover;
}
.blog-section .news-item-text {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.blog-section .news-item-text-header {
    display: block;
}
.blog-section .news-item .date {
    display: block;
    margin-bottom: 9px;
    font-size: 13px;
    color: #333;
}
.blog-section .dates {
    display: flex;
    margin-top: .3rem;
}
.blog-section .news-item-bottom img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.blog-section .admin {
    display: flex;
}
.blog-section .admin p {
    font-size: 13px;
    font-weight: 600;
    margin-top: .5rem;
    margin-right: 1rem;
    margin-bottom: 0px;
}
.blog-section .news-item-descr {
    margin: 6px 0px 20px 0px;
    height: 80px;
}
.blog-section .news-item-descr p {
    color: $text;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
.blog-section .news-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog-section .action-list {
    display: flex;
    font-size: .8rem;
    color: #333;
}
.blog-section .action-list .fa-comment {
    color: $firstcolor;
}
.blog-section .action-list .fa-share-alt {
    color: $firstcolor;
}
.blog-section .action-list .fa-heart {
    color: $firstcolor;
}
.blog-section ul.action-list {
    margin-bottom: 0px;
}
.blog-section .action-item {
    display: inline-block;
    padding-left: 15px;
}
.blog-section .news-slider-wrap .slick-track {
    display: flex;
}
.blog-section .news-item-sm {
    display: flex;
    margin-bottom: 29px;
}
.blog-section .news-item-sm:last-child {
    margin-bottom: 0px;
}
.blog-section .news-item-sm .news-img-link {
    flex-basis: 49%;
    position: relative;
}
.blog-section .news-item-sm .news-img-link .news-item-img {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.blog-section .news-item-sm .news-item-text {
    flex-basis: 66.6%;
    padding: 15px 20px;
}
.blog-section .news-item-sm .news-item-descr {
    height: 52px;
}
.blog-section .news-link {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: $firstcolor;
    transition: all 0.25s;
}
.blog-section .news-link:hover {
    color: $secondcolor;
    text-decoration: none;
    transition: all 0.25s;
}
@media screen and (max-width: 1199px) {
    .blog-section .news-item.news-item-sm.mb,
    .blog-section .news-item {
        margin-bottom: 2.5rem;
    }
    .blog-section .news-item.no-mb {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 991px) {
    .blog-section .news-item {
        margin-bottom: 2.5rem;
    }
    .blog-section .news-item.no-mb:last-child {
        margin-bottom: 0px;
    }
    .blog-section .news-item-sm .news-img-link {
        flex-basis: 33.5%;
    }
}
@media screen and (max-width: 577px) {
    .blog-section .news-item-sm {
        display: block;
    }
    .blog-section .news-item-sm .news-img-link .news-item-img {
        position: relative;
        padding-bottom: 69%;
    }
    .blog-section .news-item-sm {
        border-radius: 0px;
    }
}
@media screen and (max-width: 414px) {
    .blog-section .news-item-descr p,
    .blog-section .news-item-descr {
        overflow: hidden;
    }
    .blog-section ul.action-list {
        padding-left: 0px;
    }
    .blog-section .news-link {
        font-size: .9rem;
    }
}

/*-----------------------------------*/
/*----- 12. SECTION TESTIMONIALS ----*/
/*-----------------------------------*/
section.testimonials {
    background: #fff;
    padding: 6rem 0;
}
.testimonials .title-style h2,
.testimonials .title-style p {
    color: $firstcolor;
}
.testimonials .title-style span {
    color: #000;
}
.testimonials .title-icon:before {
    content: "";
    width: 100px;
    height: 2px;
    background: $secondcolor;
    position: absolute;
    top: 14px;
    left: -80px;
}
.testimonials .title-icon:after {
    content: "";
    width: 100px;
    height: 2px;
    background: $secondcolor;
    position: absolute;
    top: 14px;
    right: -80px;
}
.testimonials .title-icon i {
    color: $firstcolor;
    font-size: 1.8rem;
    margin-top: 1px;
}
.testimonials .owl-carousel .test-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    color: #000;
    padding: 2rem;
    border-radius: 10px;
}
.testimonials .test-1 img {
    width: 90px;
    border-radius: 50%;
    margin: 0 auto;
}
.testimonials .test-1 p {
    text-align: center;
    font-weight: 400;
    font-style: italic;
    margin: .3rem auto 0;
    max-width: 500px;
    color: $text;
}
.testimonials .test-1 p::before {
    content: "\f10d ";
    font-size: 1.2rem;
    font-family: fontawesome;
    left: 136px;
    top: -24px;
    position: relative;
    color: $firstcolor;
}
.testimonials .test-1 p::after {
    bottom: 4px;
    content: "\f10e";
    font-size: .7rem;
    font-family: fontawesome;
    color: #fff;
    right: -6px;
    top: 0px;
    position: relative;
    color: #fff;
}
.testimonials .test-1 h3 {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: .8rem;
    color: $firstcolor;
    font-weight: 600;
}
.testimonials .test-1 h6 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 0px;
    color: #000;
}
.owl-dots {
    text-align: center;
    margin-top: 1.3rem;
    line-height: 0;
}
.owl-dot {
    border-radius: 50px;
    height: 10px;
    width: 10px;
    display: inline-block;
    background: $secondcolor !important;
    margin-left: 5px;
    margin-right: 5px;
}
.owl-dot.active {
    background: $firstcolor !important;
}
.starts {
    list-style: none;
    margin-top: .4rem;
    padding: 0px;
}
.starts li {
    text-align: center;
    display: inline-block;
    margin-bottom: 1.8rem;
}
.starts .fa {
    color: #ffc107 !important;
}
@media screen and (max-width: 767px) {
    .testimonials .test-1 p::before {
        left: 190px !important;
    }
}
@media screen and (max-width: 414px) {
    .testimonials .test-1 p::before {
        left: 135px !important;
    }
}
@media screen and (max-width: 360px) {
    .testimonials .test-1 p::before {
        left: 105px !important;
    }
}

/*-----------------------------------*/
/*------ 13. SECTION PARTNERS ------*/
/*-----------------------------------*/
div.partners-1,
div.partners {
    padding: 3rem 0;
    background: #f7f7f7;
    color: #000;
}
.partners-1 .style2 .owl-item img,
.partners .style2 .owl-item img {
    transform-style: preserve-3d;
}
.partners-1 .style2 img {
    background: #fff;
    width: 100px;
    opacity: 0.5;
    border-radius: 10px;
}
.partners .style2 img {
    width: 100%;
    opacity: 0.5;
    background: transparent;
    cursor: pointer;
    transition: all .5s ease;
}

/*-----------------------------------*/
/*------ 14. SECTION COUNTER UP -----*/
/*-----------------------------------*/
section.counterup {
    padding: 6rem 0;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(../images/bg/bg-counter2.jpg) no-repeat center center;
    background-attachment: fixed;
    color: #fff;
    text-align: center;
}
.counterup .section-title {
    padding-bottom: 3.5rem;
}
.counterup .title-icon:before {
    content: "";
    width: 100px;
    height: 2px;
    background: $firstcolor;
    position: absolute;
    top: 14px;
    left: -80px;
}
.counterup .title-icon:after {
    content: "";
    width: 100px;
    height: 2px;
    background: $firstcolor;
    position: absolute;
    top: 14px;
    right: -80px;
}
.counterup .title-icon i {
    color: $secondcolor;
    font-size: 1.8rem;
    margin-top: 1px;
}
.counterup .countr .fa {
    font-size: 3rem;
    color: $firstcolor;
    border-radius: 5px;
    margin-right: 1.5rem;
}
.counterup .countr p {
    font-size: 30px;
    font-weight: 700;
    color: $secondcolor;
}
.counterup .title-style h2,
.counterup .title-style p {
    color: $firstcolor;
}
.counterup .title-style span {
    color: $secondcolor;
}
.counterup .countr {
    display: flex;
}
.counterup .count-me h3 {
    font-family: 'Roboto', sans-serif;
    
    color: $secondcolor;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .counterup .countr h3 {
        margin-bottom: 5rem;
    }
    .counterup .countr.mb-0 h3 {
        margin-bottom: 0px;
    }
    .counterup .countr {
        justify-content: center;
    }
    .counterup .countr p {
        padding: 0px;
    }
    h3.specific:last-child {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 767px) {
    .counterup .countr.mb-0 h3 {
        margin-bottom: 5rem;
    }
    .counterup .countr.mb-0.last {
        margin-right: 1rem;
    }
    .counterup .countr.mb-0.last h3 {
        margin-bottom: 0px;
    }
    .counterup .countr .fa {
        margin-top: 0px;
    }
}

/*-----------------------------------*/
/*------ 15. SECTION FOOTER ------*/
/*-----------------------------------*/
.first-footer .top-footer {
    background: #303441;
    color: #333;
    padding: 6rem 0;
    border-top: 1px solid #e5e5e5;
}
.first-footer .logo img {
    width: 180px;
    height: auto;
    margin-bottom: 1.6rem;
}
@media screen and (max-width: 991px) {
    .first-footer .netabout {
        margin-bottom: 0px;
    }
    .first-footer .netabout p {
        margin-bottom: 2rem !important;
    }
}
.first-footer .netabout h3 {
    margin-bottom: 2rem;
}
.first-footer .netabout h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: #fff;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.first-footer .netabout p {
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: rgba(245, 255, 255, 0.8);
}
.first-footer .netsocials {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.first-footer .netsocials a .fa {
    font-size: 1rem;
    text-align: center;
    background: transparent;
    border: 1px solid #fff;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 1.5rem;
    transition: all .5s ease;
}
.first-footer .netsocials a .fa-facebook {
    background: #3b5998;
    color: #fff;
    border: 1px dotted #3b5998;
}
.first-footer .netsocials a .fa-facebook:hover {
    background: #3b5998;
    color: #fff;
}
.first-footer .netsocials a .fa-twitter {
    background: #1da1f2;
    color: #fff;
    border: 1px dotted #1da1f2;
}
.first-footer .netsocials a .fa-twitter:hover {
    background: #1da1f2;
    color: #fff;
}
.first-footer .netsocials a .fa-google-plus {
    background: #dd4b39;
    color: #fff;
    border: 1px dotted #dd4b39;
}
.first-footer .netsocials a .fa-google-plus:hover {
    background: #dd4b39;
    color: #fff;
}
.first-footer .netsocials a .fa-youtube {
    background: #cd201f;
    color: #fff;
    border: 1px dotted #cd201f;
}
.first-footer .netsocials a .fa-youtube:hover {
    background: #cd201f;
    color: #fff;
}
// ------ CONTACT US ------ //
.first-footer h3 {
    margin-bottom: 2rem;
    color: #fff;
}
.first-footer .contactus h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.first-footer .contactus ul li:last-child,
.first-footer .contactus .info:last-child {
    margin-bottom: 0px !important;
}
@media screen and (max-width: 767px) {
    .first-footer .top-footer {
        padding-bottom: 6rem;
    }
}
.first-footer .contactus .info {
    display: flex;
    margin-bottom: .6rem;
}
.first-footer .contactus .ti {
    margin-bottom: 0px;
}
.first-footer .contactus .info p {
    margin: 0px;
    font-size: 15px;
    color: rgba(245, 255, 255, 0.8);
    margin-left: 1rem;
    line-height: 1.1;
    font-weight: 400;
}
.first-footer .contactus ul li {
    margin-bottom: 1.1rem;
}
.first-footer .contactus ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.first-footer .contactus ul li .fa {
    font-size: 1rem;
    color: #fff;
}
// ---------- NAVIGATION --------- //
.navigation h3 {
    margin-bottom: 2rem;
}
.first-footer .nav-footer {
    display: flex;
}
.first-footer .navigation h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
@media screen and (max-width: 991px) {
    .first-footer .navigation h3 {
        margin-top: 0px;
    }
    .navigation ul {
        margin-bottom: 0px;
    }
    .navigation ul li.no-mgb {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 767px) {
    .first-footer .navigation h3 {
        margin-top: 3rem;
    }
}
.navigation ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
}
.navigation ul li {
    margin-bottom: 1rem;
    border-bottom: 1px dotted $text;
}
.navigation ul li.no-mgb {
    margin-bottom: 0px;
}
.navigation ul li a {
    text-decoration: none;
    color: rgba(245, 255, 255, 0.8);
    font-weight: 400;
    transition: all .5s ease;
}
.navigation ul li a:hover {
    color: #fff;
}
.navigation a {
    display: block;
    margin-bottom: .6rem;
}
.navigation .nav-right {
    margin-left: 2rem;
}
// ---------- TWITTER FEEDS --------- //
.twitter-widget.contuct .twitter-area {}
.first-footer .widget h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.twitter-widget.contuct .single-item {
    position: relative;
    margin-bottom: 25px;
}
.twitter-widget.contuct .single-item:last-child {
    margin-bottom: 0px;
}
.twitter-widget.contuct .twitter-area .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
}
.twitter-widget.contuct .twitter-area .icon-holder i {
    color: #fff;
}
.twitter-widget.contuct .twitter-area .text {
    padding: 0px 0px 0px 30px;
}
.twitter-widget.contuct .twitter-area .text h5,
.inner-pages .twitter-widget.contuct .twitter-area .text h5 {
    font-size: 14px;
    color: rgba(245, 255, 255, 0.8);
    font-weight: 400;
    
    line-height: 1.3rem;
}
.twitter-widget.contuct .twitter-area .text h5 a,
.inner-pages .twitter-widget.contuct .twitter-area .text h5 a {
    color: #fff;
    font-weight: 400;
}
.twitter-widget.contuct .twitter-area .text h4,
.inner-pages .twitter-widget.contuct .twitter-area .text h4 {
    font-size: 14px;
    color: rgba(245, 255, 255, 0.8);
    font-weight: 500;
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .first-footer .widget,
    .inner-pages .first-footer .widget {
        padding-top: 0px !important;
    }
    .first-footer .widget h3 {
        margin-top: 3rem;
    }
}
.inner-pages .first-footer .twitter-widget.contuct h5::after {
    display: none;
}
// ------ EMAIL NEWSLETTERS ------ //
.bloq-email {
    display: flex;
    flex: 1 1 auto;
}
.bloq-email .email {
    display: flex;
    height: 3rem;
    flex-wrap: wrap;
}
.newsletters h3 {
    margin-bottom: 2rem;
}
.first-footer .newsletters h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
@media screen and (max-width: 991px) {
    .first-footer .newsletters h3 {
        margin-top: 3rem;
    }
    .bloq-email .email {
        flex-wrap: nowrap;
    }
}
.newsletters p {
    margin-bottom: 2rem;
    color: rgba(245, 255, 255, 0.8);
    font-weight: 400;
}
.first-footer .bloq-email .email input[type="email"] {
    border: 0px;
    background: rgba(255, 255, 255, .1);
    color: #333;
    padding: .8rem;
    flex: 1 1 auto;
}
.first-footer .bloq-email .email input[type="submit"] {
    border: 0px;
    padding: .8rem;
    background: $firstcolor;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1.5rem;
    cursor: pointer;
    flex: 1 1 auto;
    transition: all .5s ease;
}
.first-footer .email input[type="submit"]:hover {
    background: rgba(245, 255, 255, .1);
}
@media screen and (max-width: 991px) {
    .first-footer .bloq-email .email input[type="submit"] {
        margin-top: 0px;
    }
}
@media screen and (max-width: 767px) {
    .first-footer .bloq-email .email input[type="submit"] {
        margin-top: 0px;
    }
}
@media screen and (max-width: 320px) {
    .bloq-email .email {
        flex-wrap: wrap;
    }
    .first-footer .bloq-email .email input[type="submit"] {
        margin-top: 1.5rem;
    }
}
.first-footer .bloq-email label.error {
    color: #ff0000;
}
.first-footer .bloq-email label {
    color: #fff;
}
.first-footer .bloq-email .email input::placeholder {
    color: rgba(245, 255, 255, 0.8);
    opacity: 1;
}
/*------ HOVER EFFECT ------*/
.hover-effect figure {
    position: relative;
    overflow: hidden;
    margin: 0;
}
.hover-effect figure::before {
    position: absolute;
    top: 0;
    left: -90%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(-25deg);
}
.hover-effect:hover figure::before {
    animation: shine .75s;
}
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}
.hover-effect figure img {
    transform: scale(1);
    transition: .5s ease;
}
.hover-effect:hover figure img {
    transform: scale(1.3);
}
// ------ SECOND FOOTER ------ //
.second-footer {
    padding: 1.5rem 0;
    background: #303441;
    border-top: 1px solid rgba(245, 255, 255, .1);
    color: #fff;
}
.second-footer h3 {
    margin: 0;
    font-weight: 300;
}
.second-footer p {
    margin: 0;
    font-weight: 400;
}
.second-footer .container {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}
.second-footer p .fa-heart:before {
    color: #fff;
}
/*------ ARROW GO UP ------*/
element.style {
    display: inline;
}
.go-up {
    position: fixed;
    bottom: 1.5rem;
    right: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    background: #2a41e8;
    padding: .9rem;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    z-index: 99999;
    line-height: 0;
    display: none;
    transition: all .4s ease;
}
.go-up:hover,
.go-up:focus {
    outline: 0;
    text-decoration: none;
    color: #fff;
}

/*---------------------------------------*/
/*--------- 16. HOME 1 Image ----------*/
/*---------------------------------------*/
.bg-img3 {
    background-attachment: scroll;
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url(../images/bg/video-poster2.png) no-repeat center top;
    border-radius: 8px;
    height: 700px;
}
.m0a {
    margin: 0 auto;
}
.maxw1600 {
    max-width: 1600px;
}
.homepage-2 #header.cloned {
    width: 1600px;
    z-index: 1000;
    position: fixed;
    top: 0px;
    margin-left: 0px;
    width: 1600px;
    left: 151.5px;
}
.maxw1600 .hero-main {
    padding: 152px 0;
}
.homepage-2 .btn.btn-yellow:hover {
    background: #fff !important;
    color: #687389;
}
.homepage-2 .info-help:before {
    border-radius: 10px;
}
.homepage-2 .info-help.h18 {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(../images/bg/info-service3.png) no-repeat scroll center center;
}
.homepage-2 .team .team-social li a.facebook,
.homepage-2 .team .team-social li a.twitter,
.homepage-2 .team .team-social li a.instagram,
.homepage-2 .team .team-social li a.linkedin {
    background: rgba(74, 74, 76, 0.5);
}
.homepage-2 .first-footer .top-footer {
    background: #fff;
    border-top: 1px solid #e5e5e5;
}
@media only screen and (min-width: 992px) {
    .homepage-2 .first-footer .bloq-email .email input[type="submit"] {
        margin-top: 0px;
    }
    .homepage-2 .twitter-widget.contuct .single-item,
    .homepage-2 .first-footer .netabout p {
        width: 280px;
    }
}
@media only screen and (max-width: 991px) {
    .homepage-2 #header.cloned {
        position: absolute;
    }
}
@media only screen and (max-width: 575px) {
    .maxw1600 .hero-main {
        padding: 70px 0;
    }
}
.homepage-2 .second-footer .container-fluid {
    display: flex;
    justify-content: space-between;
}
.homepage-2 .portfolio .homes-price {
    font-size: 20px !important;
    font-weight: 500;
    background: transparent !important;
}
.homepage-2 .featured .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.homepage-2 .featured .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(0%);
    opacity: 0;
    visibility: visible;
    padding: 8px 0px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.homepage-2 .featured .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(74,74,76,0.7);
    z-index: 99;
}
.homepage-2 .featured .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-2 .featured .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-2 .featured .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-2 .portfolio .project-head {
    background-color: #1d293e;
    border-radius: 8px 8px 0 0;
}
.homepage-2 .portfolio .homes-price {
    padding-left: 0px;
}
.homepage-2 .portfolio .project-head:before {
    background: transparent !important;
}
.homepage-2 .portfolio .project-head img {
    background-color: #1d293e;
    opacity: .7;
}
.homepage-2 .portfolio .project-single {
    border-radius: 0 0 8px 8px;
}
.homepage-2 .portfolio .homes-content .homes-address .fa-map-marker {
    color: #666;
}
.homepage-2 .portfolio .homes-content h3 a:hover,
.homepage-2 .blog-section .news-item h3:hover {
    color: #687389;
}
.homepage-2 .btn:hover,
.homepage-2 .popular-places .listing-badges .featured,
.homepage-2 .homes-tag.featured {
    background: #687389 !important;
}
.homepage-2 .portfolio .homes-tag.sale {
    background: rgba(74, 74, 76, 0.7) !important;
}
.homepage-2 .popular-places .img-box {
    border-radius: 10px;
}
.homepage-2 .portfolio .footer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.homepage-2 .portfolio .footer {
    border-top: 1px solid #eee;
}
.homepage-2 .portfolio .footer span {
    margin-top: 12px;
}

/*---------------------------------------*/
/*--------- 17. HOME 3 Image ----------*/
/*---------------------------------------*/
.homepage-3 .head-tr.bottom {
    background: transparent;
    border: 0px;
}
.homepage-3 a.button.border, a.button, .homepage-4 .header-image .left .btn-white {
    background: #fff;
    color: #8731E8 !important;
}
.homepage-3 #navigation.style-1 ul li:hover {
    background: #8731E8;
}
.homepage-3 .welcome-area {
    background: rgba(0, 0, 0, 0) url(../images/bg/video-poster2.png) no-repeat scroll center center / cover;
}
.homepage-3 .welcome-area {
    height: 840px;
    z-index: 1;
}
.homepage-3  .banner-inner h1 {
    margin-bottom: 15px;
}
.homepage-3 .banner-inner .sub-title {
    margin-bottom: 60px;
}
.homepage-3 .bg-overlay, .overlay-dark {
    position: relative;
    z-index: 0;
}
.homepage-3 .hero-main {
    padding: 200px 0;
}
.homepage-3 .rld-main-search {
    padding: 30px;
}
.homepage-3 .rld-banner-tab {
    display: flex;
    justify-content: center;
}
.homepage-3 .rld-banner-tab .nav-item a {
    border-radius: 25px;
}
.homepage-3 .rld-banner-tab .nav-item a.active:before {
    content: "";
    height: 20px;
    margin-left: 20px;
    position: absolute;
    top: 40px;
    width: 20px;
    transform: rotate(45deg);
}
.homepage-3 .tab-content > .active {
    margin-top: 30px;
}
.homepage-3 .btn, .homepage-3 .btn:active, 
.homepage-3 .btn.sApp-btn:before, 
.homepage-3 .bg-overlay::after, 
.homepage-3 .benifits-item:after {
    background: linear-gradient(-47deg, #4528DC 0%, #8731E8 100%);
}
.homepage-3 a.btn.btn-yellow:hover {
    background: #fff !important;
    color: #8731E8;
}
.homepage-3 .bg-overlay::after {
    opacity: 0.87;
    z-index: -1;
}
.homepage-3 .rld-banner-tab .nav-item a.active,
.homepage-3 .rld-banner-tab .nav-item a:hover,
.homepage-3 .rld-banner-tab .nav-item a.active:before {
    background: #963aff;
}
.homepage-3 .popular-searchs {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.homepage-3 .popular-searchs .box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homepage-3 .popular-searchs li {
    display: inline-block;
}
.homepage-3 .popular-searchs li a {
    display: inline-block;
    text-decoration: none;
    width: 115px;
    height: 100px;
    margin-right: 15px;
    background: rgba(255, 255, 255, 0.12);
    padding-top: 11px;
    color: #ffffff;
    font-size: 28px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all ease .5s;
}
.homepage-3 .popular-searchs li a .box span {
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-top: 10px;
    font-weight: 400;
    display: block;
}
@media (min-width: 992px) {
    .homepage-3 .rld-main-search .rld-single-input, 
    .homepage-3 .rld-main-search .rld-single-select .single-select,
    .homepage-3 .popular-searchs {
        margin-bottom: 0px;
    }
}
@media (max-width: 575px) {
    .homepage-3 .popular-searchs {
        display: none;
    }
}
.homepage-3 .rec-pro .container-fluid {
    max-width: 1560px; 
}
.homepage-3 .portfolio .homes-price {
    font-size: 20px !important;
    font-weight: 500;
    background: transparent !important;
}
.homepage-3 .featured .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.homepage-3 .featured .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(0%);
    opacity: 0;
    visibility: visible;
    padding: 8px 0px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.homepage-3 .featured .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(74,74,76,0.7);
    z-index: 99;
}
.homepage-3 .featured .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-3 .featured .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-3 .featured .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-3 .portfolio .project-head {
    background-color: #1d293e;
    border-radius: 8px 8px 0 0;
}
.homepage-3 .portfolio .homes-price {
    padding-left: 0px;
}
.homepage-3 .portfolio .project-head:before {
    background: transparent !important;
}
.homepage-3 .portfolio .project-head img {
    background-color: #1d293e;
    opacity: .7;
}
.homepage-3 .portfolio .project-single {
    border-radius: 0 0 8px 8px;
    margin-bottom: 0px;
    box-shadow: none;
    border: 1px solid #e7e7e7;
}
.homepage-3 .portfolio .homes-content .homes-address .fa-map-marker {
    color: #666;
}
.homepage-3 .portfolio .homes-content h3 a:hover,
.homepage-3 .blog-section .news-item h3:hover {
    color: #687389;
}
.homepage-3 .btn:hover,
.homepage-3 .popular-places .listing-badges .featured,
.homepage-3 .homes-tag.featured {
    background: #8731E8 !important;
}
.homepage-3 .portfolio .homes-tag.sale {
    background: rgba(74, 74, 76, 0.7) !important;
}
.homepage-3 .popular-places .img-box {
    border-radius: 10px;
}
.homepage-3 .portfolio .footer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.homepage-3 .portfolio .footer {
    border-top: 1px solid #eee;
}
.homepage-3 .portfolio .footer span {
    margin-top: 12px;
}
.homepage-3 .sec-title {
    text-align: center;
    padding-bottom: 2rem;
}
.homepage-3 .sec-title h2 {
    color: #111;
    font-weight: 600;
    
}
.homepage-3 .sec-title h2 span {
    position: relative;
    color: #444;
    font-weight: 500;
}
.homepage-3 .sec-title p {
    margin-top: 13px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    max-width: 550px;
}
.homepage-3 .slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px #8731E8;
}
.homepage-3 .slick-dots li {
    color: #8731E8;
}
.homepage-3 .slick-dots li:after {
    background: #8731E8;
}
.homepage-3 .popular-places .img-box {
    height: 280px;
}
.homepage-3 .popular-places .img-box-content h4 {
    font-size: 20px;
}
.homepage-3 .info-help .info-head .info-text .btn-pro {
    background: #8731E8;
}
.homepage-3 .info-help.h17 {
    background: url(../images/bg/bg-home-3.png) no-repeat scroll center center;
}
.homepage-3 .blog-section .action-list .fa-heart,
.homepage-3 .blog-section .action-list .fa-comment,
.homepage-3 .blog-section .action-list .fa-share-alt,
.homepage-3 .blog-section .news-link {
    color: #8731E8;
}
.homepage-3 .first-footer .top-footer {
    background: #232733;
}
.homepage-3 .first-footer .navigation h3::after, 
.homepage-3 .first-footer .widget h3::after, 
.homepage-3 .first-footer .newsletters h3::after, 
.homepage-3 .first-footer .bloq-email .email input[type="submit"], 
.homepage-3 .go-up {
    background: #8731E8;
}
.bg-white-3 {
    background: url(../images/bg/bg-white-3.png) 0 0 repeat !important;
}
.homepage-3 .singleJobClinet {
    position: relative;
    background: #fff;
    border: 1px solid #ededed;
    padding: 44px 53px 55px;
    margin-bottom: 60px;
}
.homepage-3 .singleJobClinet p {
    letter-spacing: 0.32px;
    margin-bottom: 25px;
}
.homepage-3 .detailJC {
    position: relative;
    padding-left: 88px;
    padding-top: 7px;
}
.homepage-3 .detailJC span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 68px;
    height: 68px;
    padding: 4px;
    border-radius: 50%;
    background: rgb(240,71,134);
    background: linear-gradient(90deg, rgba(240,71,134,0.75) 0%, rgba(125,107,255,0.75) 100%);
}
.homepage-3 .detailJC span img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.homepage-3 .detailJC h5 {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.38px;
    margin-bottom: 0;
}
.homepage-3 .detailJC p {
    color: #666;
    font-size: 15px;
    letter-spacing: .30px;
    margin: 0;
}
.homepage-3 .detailJC:after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 68px;
    height: 58px;
    background: url(../images/icons/quoe.png) no-repeat center center / cover;
}
.homepage-3 .job_clientSlide .owl-nav .owl-prev, 
.homepage-3 .job_clientSlide .owl-nav .owl-next {
    margin: 0 5px;
    padding: 0 0;
    text-align: center;
    width: 43px;
    height: 43px;
    border: 2px solid #ddd;
    border-radius: 50%;
    font-size: 18px;
    color: #70778b;
    line-height: 43px;
    background: transparent;
    transition: all ease .5s;
}
.homepage-3 .job_clientSlide .owl-nav {
    position: relative;
    display: flex;
    justify-content: center;
}
.homepage-3 .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.homepage-3 .job_clientSlide .owl-nav .owl-prev:hover, 
.homepage-3 .job_clientSlide .owl-nav .owl-next:hover {
    background: #8731E8;
    color: #fff;
}

/*---------------------------------------*/
/*--- 18. IMAGE VIDEO HOME 4  ---*/
/*---------------------------------------*/
.welcome-area {
    height: 800px;
    z-index: 1;
}
.welcome-thumb {
    max-width: 300px;
    padding-top: 0px;
}
@media (min-width: 992px) {
    .download-page .welcome-intro > h1 {
        font-size: 3.8em;
    }

    .download-page .welcome-intro p {
        font-size: 16px;
    }
}
@media (max-width: 575px) {
    .homepage-4 .header-image {
        padding: 100px 30px 70px 30px;
    }
    .homepage-4 .info-help .info-head .info-text {
        margin-top: 0px !important;
    }
    .homepage-4 .info-help .info-head .info-text .btn-pro,
    .homepage-4 .portfolio .project-single.specials {
        margin-bottom: 30px;
    }
    .download-page .welcome-intro p {
        font-size: 16px;
    }
}
.welcome-area .video-icon {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translateY(-50%);
}
.play-btn {
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;
    background-color: #fff;
    border-radius: 50%;
}
.btn-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: #fff;
}
.play-icon {
    font-size: 22px;
    color: #FF385C;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@-webkit-keyframes grow {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(1.5);
        opacity: 0;
    }
}
@keyframes grow {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(1.5);
        opacity: 0;
    }
}
.play-animation {
    animation: grow 3s infinite;
}
.animation-short {
    animation-delay: .5s;
}
.welcome-area.bg-overlay::after {
    z-index: -2;
}
.welcome-area .shape-bottom {
    z-index: -1;
}
.shape-bottom {
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;
}
/*BACKGROUND OVERLAY*/
.bg-overlay,
.overlay-dark {
    position: relative;
    z-index: 0;
}
.bg-overlay::after,
.overlay-dark:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.bg-overlay::after {
    opacity: 0.90;
    z-index: -1;
}
.overlay-dark::after {
    background-color: rgba(255, 56, 92, 1);
    opacity: 0.80;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    z-index: -1;
}
/*BACKGROUND COLORS*/
.homepage-4 .bg-gray {
    background-color: #f6f9fe;
}
.bg-inherit {
    background: inherit !important;
}
.homepage-4 #navigation.style-1 ul li:hover {
    background: #FF385C;
}
.homepage-4 .btn,
.homepage-4 .btn:active,
.homepage-4 .btn.sApp-btn:before,
.homepage-4 .bg-overlay::after,
.homepage-4 .benifits-item:after {
    background: linear-gradient( 148deg, rgb(253,85,89) 0%, rgb(255,51,125) 100%);
}
.homepage-4 .head-tr.bottom {
    background: transparent;
    border: 0px;
}
.homepage-4 a.button.border, a.button,
.homepage-4 .header-image .left .btn-white {
    background: #fff;
    color: #FF385C !important;
}
.homepage-4 .home18 .sec-title h2 {
    color: #FF385C;
}
.homepage-4 .home18 .sec-title h2 span::before {
    background: #FF385C;
}
.homepage-4 .portfolio .homes-tag.sale {
    background: rgba(74,74,76,0.7) !important;
}
.homepage-4 .homes-tag.featured {
    background: #FF385C !important;
}
.homepage-4 .portfolio .homes-price {
    font-size: 20px !important;
    font-weight: 500;
    background: transparent !important;
}
.homepage-4 .featured .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.homepage-4 .featured .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(0%);
    opacity: 0;
    visibility: visible;
    padding: 8px 0px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.homepage-4 .featured .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(74,74,76,0.7);
    z-index: 99;
}
.homepage-4 .featured .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-4 .featured .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-4 .featured .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}
.homepage-4 .portfolio .project-head {
    background-color: #1d293e;
    border-radius: 8px 8px 0 0;
}
.homepage-4 .portfolio .homes-price {
    padding-left: 0px;
}
.homepage-4 .portfolio .project-head:before {
    background: transparent !important;
}
.homepage-4 .portfolio .project-head img {
    background-color: #1d293e;
    opacity: .7;
}
.homepage-4 .portfolio .project-single {
    border-radius: 0 0 8px 8px;
}
.homepage-4 .portfolio .homes-content .homes-address .fa-map-marker {
    color: #666;
}
.homepage-4 .portfolio .homes-content h3 a:hover,
.homepage-4 .blog-section .news-item h3:hover {
    color: #FF385C;
}
.homepage-4 .btn:hover,
.homepage-4 .popular-places .listing-badges .featured {
    background: #FF385C;
}
.homepage-4 .popular-places .img-box {
    border-radius: 10px;
}
.homepage-4 .portfolio .footer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.homepage-4 .portfolio .footer {
    border-top: 1px solid #eee;
}
.homepage-4 .portfolio .footer span {
    margin-top: 12px;
}
.homepage-4 .info-help {
    padding: 6rem 0;
    background: linear-gradient(rgba(255,255,255, 0.3), rgba(255,255,255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 3;
}
.homepage-4 .info-help .info-head .info-text {
    margin-top: 120px;
}
.homepage-4 .info-help .info-head .info-text .btn-pro {
    background: #FF385C;
}
.homepage-4 .testimonials .test-1 h3 {
    color: #666;
}
.homepage-4 .starts .fa {
    color: #FF385C !important;
}
.homepage-4 .testimonials .test-1 p::before {
    content: "\f10d ";
    font-size: 1.2rem;
    font-family: fontawesome;
    left: 236px;
    top: -24px;
    position: relative;
    color: #FF385C;
}
.homepage-4 .owl-dots {
    margin-top: 0px;
}
.homepage-4 .owl-dot.active {
    background: #666 !important;
}
.homepage-4 .owl-dot {
    background: #dedede !important;
}
.homepage-4 .starts {
    list-style: none;
    margin-top: .7rem;
    padding: 0px;
}
.homepage-4 .starts li {
    margin-bottom: 2.4rem;
}
.homepage-4 .testimonials .owl-carousel .test-1 {
    background: transparent;
}
.homepage-4 .blog-section .action-list .fa-heart,
.homepage-4 .blog-section .action-list .fa-comment,
.homepage-4 .blog-section .action-list .fa-share-alt,
.homepage-4 .blog-section .news-link {
    color: #FF385C;
}
.homepage-4 .first-footer .top-footer {
    background: #232733;
}
.homepage-4 .first-footer .navigation h3::after,
.homepage-4 .first-footer .widget h3::after,
.homepage-4 .first-footer .newsletters h3::after,
.homepage-4 .first-footer .bloq-email .email input[type="submit"],
.homepage-4 .go-up {
    background: #FF385C;
}
.homepage-4 .welcome-area {
    background: rgba(0, 0, 0, 0) url("../images/bg/bg-demo.jpg") no-repeat scroll center center / cover;
}

.homepage-4 .welcome-area .shape-bottom svg {
    transform: scale(1.05);
}

/*---------------------------------------*/
/*--- 19. HOME 5 IMAGE  ---*/
/*---------------------------------------*/
.homepage-5 .parallax-searchs.home17 {
    background: url(../images/bg/bg-home-5.jpg) no-repeat center top;
    background-size: cover;
    background-attachment: scroll !important;
    width: 100%;
    height: 85vh;
}
.homepage-5 .banner-inner-wrap {
    height: 90vh;
}
.homepage-5 .banner-inner .sub-title {
    margin-bottom: 80px;
}
.homepage-5 .rld-banner-tab .nav-item a:hover, 
.homepage-5 .rld-banner-tab .nav-item a:focus, 
.homepage-5 .rld-banner-tab .nav-item a.active {
    background: #3e4452;
    color: #fff;
}
.homepage-5 .btn-yellow {
    background: #3e4452;
}
.homepage-5 .banner-inner h1,
.homepage-5 .banner-inner .sub-title,
.homepage-5 .info-help .info-head .info-text h3,
.homepage-5 .info-help .info-head .info-text p {
    color: #3e4452;
}
.homepage-5 .portfolio.right-slider {
    position: relative;
}
.homepage-5 .recently-slider {
    padding-left: calc((100% - 1140px) / 2 + 15px) !important;
    padding-right: 0 !important;
}
.container-fluid.recently-slider {
    width: 100%;
}
.homepage-5 .home5-right-slider.owl-carousel .owl-prev,
.homepage-5 .home5-right-slider.owl-carousel .owl-next {
    background-color: #ffffff !important;
    border-radius: 50%;
    color: #555;
    height: 54px;
    line-height: 35px;
    position: absolute;
    top: 45%;
    width: 54px;
    transform: translate(-50%, -50%) scale(1, 1);
    z-index: 10;
    overflow: hidden;
    transition: all ease .5s;
}
.home5-right-slider.owl-carousel .owl-prev {
    left: 0px;
}
.home5-right-slider.owl-carousel .owl-next {
    right: 20px;
}
@media only screen and (max-width: 1440px) {
    .home5-right-slider.owl-carousel .owl-next {
        right: -5%;
    }
}
@media only screen and (max-width: 991px) {
    .homepage-5 .recently-slider {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    .home5-right-slider.owl-carousel .owl-next {
        right: 0.5%;
    }
    .home5-right-slider.owl-carousel .owl-prev {
        left: 5%;
    }
    .homepage-5 .team .team-block {
        padding-bottom: 1px;
    }
}
@media only screen and (max-width: 575px) {
    .homepage-5 .banner-inner .sub-title {
        margin-bottom: 30px;
    }
    .home5-right-slider.owl-carousel .owl-prev {
        left: 50px;
    }
}
.home5-right-slider.owl-carousel .owl-prev:hover,
.home5-right-slider.owl-carousel .owl-next:hover {
    background: #3e4452 !important;
    color: #fff;
}
.home5-right-slider.owl-carousel .owl-prev i,
.home5-right-slider.owl-carousel .owl-next i {
    margin-left: 16px;
    margin-top: 16px;
    font-size: 26px;
}
.homepage-5 .owl-carousel .owl-stage {
    padding-left: 0px !important;
}
.homepage-5 .info-help.h18 {
    padding: 9rem 0;
    background: url(../images/bg/bg-service-home-5.png) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 0 !important;
}
.homepage-5 .info-help:before {
    background: transparent;
}
.homepage-5 .popular-places .listing-badges .featured,
.homepage-5 .team .team-social li a.facebook,
.homepage-5 .team .team-social li a.twitter,
.homepage-5 .team .team-social li a.instagram,
.homepage-5 .team .team-social li a.linkedin {
    background: #3e4452;
}
.homepage-5 .popular-places .img-box {
    height: 300px;
}
.homepage-5 .popular-places .img-box-content h4 {
    font-size: 20px;
}
.homepage-5 .sec-title {
    text-align: center;
    padding-bottom: 2rem;
}
.homepage-5 .sec-title h2 {
    color: #111;
    font-weight: 600;
    
}
.homepage-5 .sec-title h2 span {
    position: relative;
    color: #3e4452;
    font-weight: 600;
}
.homepage-5 .sec-title p {
    margin-top: 13px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    max-width: 550px;
}
.homepage-5 .info-help.h17 {
    padding: 9rem 0;
    background: url(../images/bg/bg-2-home-5.png) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 0 !important;
}
.homepage-5 .team-block .inner-box {
    box-shadow: none;
}

/*---------------------------------------*/
/*--- 20. HOME 2, 3 AND 4 SEARCH AREA ---*/
/*---------------------------------------*/
.main-search-field {
    padding-top: 2rem;
    padding-bottom: 0;
    background: $firstcolor;
    background-repeat: repeat;
}
.main-search-field.mt10 {
    margin-top: -10px;
}
.main-search-field h3 {
    font-size: 2rem;
    margin-bottom: 2.5rem !important;
    color: #fff;
    font-weight: 800;
    margin: 0 auto 15px;
}
.main-search-field h3 span {
    color: #222;
    font-weight: 300;
}
.main-search-field select {
    width: 100%;
    height: 45px;
    padding: 5px 10px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16pxrem;
    border-radius: 0;
    background: transparent;
    // 
    transition: all 0.5s ease-in-out;
}
.main-search-field .no-mb select,
.main-search-field .at-col-default-mar.no-mb,
.main-search-field .at-input {
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .main-search-field .no-mb select,
    .main-search-field .at-input {
        margin-bottom: 20px;
    }
}
.main-search-field select:focus {
    outline: none;
}
.main-search-field option {
    background: #fff;
    color: #333;
}
.main-search-field select:focus {
    background: transparent;
}
.main-search-field .at-input {
    width: 100%;
    height: 45px;
    padding: 5px 10px;
    color: #000 !important;
    border: 1px solid #fff;
    border-radius: 0;
    background: #fff;
}
.main-search-field .at-input:focus {
    outline: none;
}
.main-search-field .at-col-default-mar {
    margin-bottom: 10px;
}
.main-search-field .at-input-price {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #fff;
    height: 25px;
    width: 180px;
}
.at-pricing-range h4 {
    color: #fff;
}
.main-search-field .hvr-bounce-to-right {
    width: 100%;
}
.main-search-field .hide {
    display: none !important;
}
.main-search-field .at-pricing-range label {
    color: #ffffff;
}
/* BTN CSS START*/
.main-search-field .at-btn-default {
    font-size: 18px;
    background: #fff;
    color: #ffffff;
    border: none;
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
}
.main-search-field .hvr-bounce-to-right {
    font-size: 14px;
    background: $secondcolor;
    color: #ffffff;
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    padding: 12px 22px;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
}
.main-search-field .hvr-bounce-to-right:hover {
    background: #fff;
    color: #000;
    transition: all .5s ease;
}
.main-search-field .hvr-bounce-to-right:before {
    background-color: #bf864f;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
.slider_amount {
    border: none;
    background: none;
    padding: 0px;
    margin-bottom: 10px;
    width: 100%;
    color: #fff;
}
.ui-widget.ui-widget-content {
    border-radius: 2px;
    background: #fff;
    border: none;
}
.ui-slider-horizontal {
    height: 10px;
}
.ui-widget-header {
    background: $secondcolor;
}
@media screen and (max-width: 767px) {
    .hvr-bounce-to-right {
        margin-top: 30px;
    }
    .main-search-field.pt-3 {
        padding-top: 0px !important;
    }
}

/*---------------------------------------*/
/*--- 21. HOME 6 PARALLAX IMAGE ---*/
/*---------------------------------------*/
.parallax-search {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/bg/bg-demo.jpg') no-repeat center top;
    background-size: cover;
    background-attachment: fixed !important;
    width: 100%;
    height: auto;
}
.parallax-search .hero-inner {
    text-align: center;
    padding: 300px 0;
}
.parallax-search .welcome-text p {
    color: #fff;
    font-size: 18px;
    
    font-weight: 500;
}
.parallax-search .welcome-text h1 {
    font-size: 40px;
    color: #fff;
    
}
/* Search Form */
.parallax-search .trip-search {
    background: #fff;
    padding: 10px;
    border: 1px solid #A7CCE8;
    border-radius: 50px;
    text-align: left;
    margin-top: 30px;
}
.parallax-search .trip-search .form {
    line-height: 0px;
}
.parallax-search .form-group {
    width: 190px;
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 0px;
}
.parallax-search .form-group:last-child {
    margin-right: 0px;
}
.parallax-search .form-group .nice-select {
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    color: #757575;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.parallax-search .form-group .nice-select:hover {
    color: $firstcolor;
}
.parallax-search .form-group .nice-select::after {
    right: 20px;
    color: #757575;
}
.parallax-search .form-group .nice-select i {
    color: $firstcolor;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-right: 10px;
}
.parallax-search .form-group .list li {
    color: #757575;
}
.parallax-search .form-group .list li.selected {
    color: #757575;
    font-weight: 400;
}
.parallax-search .form-group .list li:hover {
    color: #fff;
    background: $firstcolor;
}
.parallax-search .form-group.location {
    width: 150px;
}
.parallax-search .form-group.button {
    width: 145px;
    position: relative;
    height: 50px;
}
.parallax-search .form-group .btn {
    display: block;
    width: 100%;
    background: $firstcolor;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 0;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-radius: 50px;
}
.parallax-search .form-group .btn:hover {
    background: $secondcolor;
    color: #fff;
}
@media only screen and (max-width: 1024px) {
    .parallax-search .trip-search .form-group {
        margin-top: 15px;
        margin-right: 20px;
        width: 100%;
    }
}
@media only screen and (max-width: 991px) {
    .parallax-search .trip-search .form-group {
        margin-top: 15px;
        margin-right: 20px;
    }
    .parallax-search .trip-search {
        border-radius: 1px;
    }
    .parallax-search .hero-inner {
        margin: 97px 0;
    }
    .parallax-search.home15 .hero-inner {
        margin: 0 !important;
    }
    .int_dark_bg .portfolio .item.landscapes.no-pb {
        margin-bottom: 0px;
    }
    .int_dark_bg .portfolio .item.landscapes.no-pb {
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .parallax-search .welcome-text h1 {
        font-size: 30px;
    }
    .parallax-search .hero-inner {
        margin: 69px 0;
    }
    .parallax-search .trip-search .form-group {
        margin-top: 10px;
        width: 100%;
    }
}
@media only screen and (max-width: 450px) {
    .parallax-search .welcome-text h1 {
        font-size: 26px !important;
    }
    .parallax-search .hero-inner {
        padding: 40px 0;
    }
    .parallax-search .welcome-text p {
        font-size: 14px;
    }
    .parallax-search .hero-inner {
        margin: 70px 0;
    }
    .parallax-search .trip-search {
        margin-top: 30px;
    }
}
/* Owl Nav */
.hero-area.style2 .owl-controls {}
.hero-area.style2 .owl-controls .owl-nav {}
.hero-area.style2 .owl-controls .owl-nav div {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    padding: 0;
    font-size: 25px;
    border-radius: 25px;
    background: transparent;
    border: 1px solid #fff;
    transition: all 0.4s ease;
    margin: 0;
}
.hero-area.style2 .owl-controls .owl-nav div:hover {
    background: #FF7550;
    border-color: transparent;
    color: #fff;
}
.hero-area.style2 .owl-controls .owl-nav div.owl-prev {
    left: 0;
}
.hero-area.style2 .owl-controls .owl-nav div.owl-next {
    right: 0;
}
/* Slider Animation */
.hero-area .owl-item.active .welcome-text p {
    animation: fadeInDown 0.8s both 1s;
}
.hero-area .owl-item.active .welcome-text h1 {
    animation: fadeInLeft 0.8s both 1.4s;
}
.hero-area .owl-item.active .welcome-text .btn {
    animation: fadeInRight 0.8s both 1.6s;
}

/*---------------------------------------*/
/*--------- 22. HOME 7 VIDEO ----------*/
/*---------------------------------------*/
/* Search Form */
.search-2 .trip-search.vid {
    background: #fff;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.88);
    border-radius: 50px;
    text-align: left;
    margin-top: 30px;
}
.search-2 .trip-search.vid .form {
    line-height: 0px;
}
.search-2 .form-group {
    width: 190px;
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 0px;
}
.search-2 .form-group:last-child {
    margin-right: 0px;
}
.search-2 .form-group .nice-select {
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    color: #757575;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.search-2 .form-group .nice-select:hover {
    color: $firstcolor;
}
.search-2 .form-group .nice-select::after {
    right: 20px;
    color: #757575;
}
.search-2 .form-group .nice-select i {
    color: $firstcolor;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-right: 10px;
}
.search-2 .form-group .list li {
    color: #757575;
}
.search-2 .form-group .list li.selected {
    color: #757575;
    font-weight: 400;
}
.search-2 .form-group .list li:hover {
    color: #fff;
    background: $firstcolor;
}
.search-2 .form-group.looking {
    width: 350px;
    margin: 0px;
}
.search-2 .first-select {
    height: 30px;
    border-radius: 5px;
    padding-right: 3px;
    border: none;
    box-shadow: none;
    color: #757575;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.main-search-input-item {
    float: left;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    position: relative;
}
.nice-select.wide {
    width: 100%;
}
.search-2 .form-group.looking input {
    float: left;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
}
.search-2 .form-group.location {
    width: 150px;
}
.search-2 .form-group.categories {
    width: 150px;
}
.search-2 .form-group.button {
    width: 135px;
    position: relative;
    height: 50px;
}
.search-2 .form-group .btn {
    display: block;
    width: 100%;
    background: $firstcolor;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 0;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-radius: 50px;
}
.search-2 .form-group .btn:hover {
    background: $secondcolor;
    color: #fff;
}
#map-container {
    position: relative;
    top: 0;
    left: 0;
    height: 520px;
    width: 100%;
    z-index: 8;
    margin-bottom: 60px
}
#map-container.fullwidth-home-map {
    height: 690px;
    margin-bottom: 0
}
#map-container.fullwidth-home-map .main-search-inner {
    position: relative;
    display: block;
    bottom: 60px;
    top: auto;
    transform: none;
    padding-bottom: 0;
    z-index: 9999;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%
}
.dark-overlay .main-search-inner {
    bottom: 580px !important;
}
.video-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    overflow: hidden
}
.fullwidth-home-map.dark-overlay {
    height: 90vh !important;
    margin-bottom: 0;
}
.fullwidth-home-map.dark-overlay video {
    top: 50%;
    transform: translateY(-52%);
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -10;
    background-size: cover;
    transition: 1s opacity;
    position: absolute;
}
.dark-overlay:before {
    background: rgba(0, 0, 0, 0.5) !important;
}
.dark-overlay:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.99) 20%, rgba(255, 255, 255, 0.7) 70%, rgba(255, 255, 255, 0) 95%);
}
.dark-overlay h1 {
    font-size: 40px;
    color: #fff;
    line-height: 57px;
    
    text-align: center;
}
.dark-overlay p {
    color: rgba(255, 255, 255, 0.99);
    font-size: 17px;
    
    font-weight: 400;
    text-align: center;
}
@media only screen and (max-width: 1024px) {
    .search-2 .trip-search .form-group {
        margin-top: 15px;
        margin-right: 20px;
        width: 100%;
    }
}
@media only screen and (max-width: 991px) {
    .search-2 .hero-inner {
        margin: 97px 0;
    }
    .hero-inner2 {
        margin-top: 50px;
    }
    .dark-overlay h1 {
        font-size: 30px;
    }
    .dark-overlay .main-search-inner {
        bottom: 770px !important;
    }
    .search-2 .trip-search.vid {
        border-radius: 1px;
    }
}
@media only screen and (max-width: 767px) {
    .search-2 .hero-inner {
        margin: 69px 0;
    }
    .search-2 .trip-search .form-group {
        margin-top: 10px;
        width: 100%;
    }
}
@media only screen and (max-width: 576px) {
    .dark-overlay h1 {
        font-size: 25px !important;
        line-height: 37px;
    }
    .search-2 .welcome-text p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 450px) {
    .search-2 .welcome-text p {
        font-size: 14px;
    }
    .hero-inner2 {
        margin-top: 0px;
    }
    .search-2 .hero-inner {
        margin: 70px 0;
    }
    .search-2 .trip-search {
        margin-top: 30px;
    }
    .dark-overlay h1 {
        font-size: 21px !important;
    }
    .dark-overlay .main-search-inner {
        bottom: 600px !important;
    }
}
@media only screen and (max-width: 360px) {
    .dark-overlay .main-search-inner {
        bottom: 488px !important;
    }
    .search-2 .trip-search.vid {
        margin-top: 10px;
    }
}

/*-----------------------------------*/
/*- 23. INFO SIDEBAR HOME 13 --*/
/*-----------------------------------*/
.int_infosidebar {
    width: 60px;
    background: #282d32;
    position: absolute;
    left: 0;
    height: 950px;
    z-index: 1;
}
.int_infosidebar .siderbar_social > ul > li {
    margin-bottom: 10px;
    list-style: none;
}
.int_infosidebar .siderbar_social > ul > li > a {
    color: #ffffff;
}
.int_infosidebar .siderbar_social {
    margin: 167px 0px 24px 0px;
    text-align: center;
}
.int_infosidebar .siderbar_contact,
.int_infosidebar .siderbar_email {
    text-align: center;
    padding: 30px 0 22px;
    border-top: 1px solid #353c43;
}
.int_infosidebar .siderbar_contact > a,
.int_infosidebar .siderbar_email > a {
    color: #ffffff;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-transform: lowercase;
}
.int_infosidebar .siderbar_contact > a > svg,
.int_infosidebar .siderbar_email > a > svg {
    transform: rotate(90deg);
    margin-bottom: 5px;
    width: 16px;
    height: 15px;
    vertical-align: baseline;
}
.int_infosidebar .siderbar_contact > a > svg path,
.int_infosidebar .siderbar_email > a > svg path {
    fill: #c18847;
}
.int_infosidebar .siderbar_social > ul > li > a {
    transition: all 0.1s;
}
.int_infosidebar .siderbar_social > ul > li > a:hover {
    color: #c18847;
}
.int_content_wraapper {
    margin-left: 80px;
}
@media(max-width: 480px) {
    .main_contentblock {
        width: 100%;
    }
}
@media (max-width: 1600px) {
    .int_infosidebar {
        top: -100px;
    }
}

/*-----------------------------------*/
/*- 24. BANNER SLIDER HOME 13 --*/
/*-----------------------------------*/
.int_bannerr_slider .swiper-container {
    width: 100%;
    height: 100%;
}
.int_bannerr_slider .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
body.int_dark_bg {
    background-color: #212529;
}
body.int_index3_bg {
    background-color: #0e0f21;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}
.bg-black-1 {
    background: #282d32 !important;
}
.bg-black-2 {
    background: #212529 !important;
}
.int_dark_bg .section-title h3 {
    color: #fff;
}
.int_dark_bg .section-title h2 {
    color: #c18847;
}
.int_dark_bg .section-title:before {
    background: #c18847;
}
.int_dark_bg .slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px #c18847;
}
.int_dark_bg .slick-dots li:after {
    background: #c18847;
}
.int_dark_bg .slick-dots li {
    box-shadow: inset 0 0 0 2px #c18847;
}
.int_dark_bg a.button.border,
a.button {
    background: #c18847;
}
.int_dark_bg section.services-home {
    padding: 6rem 0;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../images/bg/bg-counter.jpg) no-repeat center center;
    background-attachment: fixed;
}
.int_dark_bg .services-home .bg-base {
    background: #c18847;
}
.int_dark_bg .services-home .border-1 {
    border: 1px dashed #c18847 !important;
}
.int_dark_bg .services-home a.text-base:hover {
    color: #c18847;
}
.int_dark_bg .portfolio .project-single {
    border: 0px;
    margin-bottom: 0px;
}
.int_dark_bg .btn.btn-outline-light {
    background: #c18847;
}
.int_dark_bg .bg-all {
    margin-top: 0px;
}
.int_dark_bg .portfolio .project-single {
    box-shadow: none;
}
.int_dark_bg .feature-cities-1 .listing-badges .featured {
    background: #c18847;
}
.int_dark_bg .team-content {
    background: #212529;
    border: 0px;
}
.int_dark_bg .team-info {
    background: #282d32;
    border: 0px;
}
.int_dark_bg .team-wrap:hover .team-content .team-info {
    background: #c18847;
}
.int_dark_bg .team-content h3,
.int_dark_bg .team-content p,
.int_dark_bg .team-content span a {
    color: #fff;
}
.int_dark_bg .team .team-pro .fa {
    background: #c18847;
    border: 0px;
}
.int_dark_bg .plan {
    background: #212529;
    border: 1px dashed #c18847;
}
.int_dark_bg .plan ul {
    background: #212529;
    color: #f9f9f9;
}
.int_dark_bg .plan ul li {
    border-top: 1px dashed #c18847;
}
.int_dark_bg .plan a.btn {
    background: #c18847;
}
.int_dark_bg .plan a.btn.pro2 {
    background: #282d32;
}
.int_dark_bg .plan.featured .plan-price {
    background: #282d32;
}
.int_dark_bg .plan.featured .plan-price:after {
    border-top-color: #282d32;
}
.int_dark_bg .plan-name,
.int_dark_bg .plan.featured .plan-name {
    color: #fff;
}
.int_dark_bg .plan a.btn.pro {
    background: #121B22;
}
.int_dark_bg .plan-price {
    background: #c18847;
}
.int_dark_bg .plan-price:after {
    border-top-color: #c18847;
}
.int_dark_bg .testimonials .owl-carousel .test-1 {
    background: #282d32;
}
.int_dark_bg .testimonials .test-1 h3 {
    color: #fff;
    margin-bottom: 1.5rem;
}
.int_dark_bg .testimonials .test-1 h6,
.int_dark_bg .testimonials .test-1 p::before,
.int_dark_bg .testimonials .test-1 p {
    color: #fff;
}
.int_dark_bg .testimonials .test-1 img {
    margin-bottom: .5rem;
}
.int_dark_bg .starts .fa {
    color: #c18847 !important;
    margin-top: .5rem;
    margin-bottom: 1rem;
}
.int_dark_bg .owl-dot.active {
    background: #c18847 !important;
}
.int_dark_bg .testimonials .test-1 p::after {
    display: none;
}
.int_dark_bg .blog-section .news-item {
    background: #212529;
}
.int_dark_bg .blog-section .news-item h3,
.int_dark_bg .blog-section .news-item-descr p {
    color: #fff;
}
.int_dark_bg .blog-section .news-item .date,
.int_dark_bg .blog-section .news-link {
    color: #c18847;
}
.int_dark_bg .blog-section .action-list .fa {
    color: #fff;
}
.int_dark_bg .blog-section .news-item h3:hover {
    color: #c18847;
}
.int_dark_bg .first-footer .top-footer {
    border-top: 0px;
}
.int_dark_bg .first-footer .netabout p,
.int_dark_bg .first-footer .contactus ul li .fa,
.int_dark_bg .first-footer h3,
.int_dark_bg .navigation ul li a,
.int_dark_bg .twitter-widget.contuct .twitter-area .icon-holder i,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h5,
.int_dark_bg .newsletters p {
    color: #fff;
}
.int_dark_bg .navigation ul li a:hover,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h5 a,
.int_dark_bg .twitter-widget.contuct .twitter-area .text h4 {
    color: #c18847;
}
.int_dark_bg .first-footer .navigation h3::after,
.int_dark_bg .first-footer .widget h3::after,
.int_dark_bg .first-footer .newsletters h3::after {
    background: #c18847;
}
.int_dark_bg .first-footer .contactus .info p {
    color: #efe9e9;
}
.int_dark_bg .first-footer .bloq-email .email input[type="email"],
.int_dark_bg .first-footer .bloq-email .email input[type="submit"] {
    border: 1px solid #c18847;
    background: #282d32;
    color: #fff;
}
.int_dark_bg .first-footer .bloq-email .email input::placeholder {
    color: #fff;
}
.int_dark_bg .second-footer {
    background: #282d32;
}
.int_dark_bg .go-up {
    background: #c18847;
}
.int_dark_bg .portfolio .item {
    padding-bottom: 30px;
}
.int_banner_slider .banner_box_wrapper {
    position: relative;
}
.swiper_imgbox .swipper_img > h4 {
    font-size: 12px;
    letter-spacing: 8px;
    text-transform: uppercase;
    color: #c18847;
    margin: 0;
    position: relative;
}
.swiper_imgbox .swipper_img > h4 > span {
    font-size: 90px;
    text-transform: uppercase;
    font-weight: 400;
    color: #252a2d;
    letter-spacing: 12px;
    position: absolute;
    left: 10px;
    right: 0;
    top: -50px;
    z-index: -1;
}
.swiper_imgbox .swipper_img > h2 {
    color: #fff;
    font-weight: 600;
    
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.swiper_imgbox .swipper_img > h3 {
    font-size: 26px;
    color: #c18847;
    margin-bottom: 20px;
    font-weight: 300;
}
.swiper_imgbox .swipper_img > h3 > .banner_span1 {
    color: #c18847;
}
.swiper_imgbox .swipper_img > p {
    color: #fff;
    margin-bottom: 15px;
}
.swiper_imgbox .homes-list {
    padding-left: 0px;
    padding-bottom: 2.5rem;
    margin: 0;
}
.swiper_imgbox .homes-list li {
    width: 45%;
    float: left;
    font-size: 16px;
    line-height: 40px;
    list-style: none;
    color: #fff;
}
.swiper_imgbox .homes-list li i {
    font-size: 16px;
    margin-right: 8px;
    color: #fff;
}
a.int_btn,
.int_infosidebar .siderbar_contact > a,
.int_infosidebar .siderbar_email > a {
    text-decoration: none;
}
.banner_box_wrapper .swiper_imgbox {
    margin: 140px 0 0;
    padding: 0 15px 0 25px;
}
.banner_box_wrapper .banner_navi {
    position: absolute;
    left: 95px;
    bottom: -8px;
}
.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev,
.banner_box_wrapper .swiper-button-prev,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next {
    background-image: none;
    text-transform: uppercase;
    width: auto;
    height: auto;
}
.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
    color: #fff;
}
.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
    bottom: 50px;
    top: auto;
}
.banner_box_wrapper .swiper-button-prev,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next {
    left: 0px;
}
.banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev {
    right: auto;
    left: 97px;
}
.banner_box_wrapper .swiper-button-prev:after,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:after {
    content: "";
    position: absolute;
    width: 45px;
    height: 1px;
    background: #c18847;
    top: 0px;
    bottom: 0;
    left: -55px;
    margin: auto;
}
.banner_box_wrapper .swiper-button-prev:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -55px;
    margin: auto;
    background: #c18847;
    width: 45px;
    height: 1px;
}
.banner_box_wrapper .swiper-button-next:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -55px;
    margin: auto;
    background: #c18847;
    width: 45px;
    height: 1px;
}
.int_main_wraapper {
    overflow: hidden;
    position: relative;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h4 {
    animation: fadeInLeft 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h2 {
    animation: fadeInRight 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img h3 {
    animation: fadeInLeft 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img p {
    animation: fadeInRight 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox1 .swipper_img .int_btn {
    animation: fadeInLeft 1s ease;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h4 {
    animation: fadeInDown 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h2 {
    animation: fadeInUp 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img h3 {
    animation: fadeInUp 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img p {
    animation: fadeInDown 1s linear;
}
.banner_box_wrapper .swiper-slide.swiper-slide-active .imgbox2 .swipper_img .int_btn {
    animation: fadeInUp 1s ease;
}
.swiper_imgbox .swipper_img > h1 {
    font-size: 90px;
    text-transform: uppercase;
    color: #252a2d;
    font-weight: 600;
    margin-top: 85px;
    margin-left: -9px;
}
.swiper_imgbox .btn_caret {
    right: -29px;
}
@media (max-width: 1600px) {
    .swiper_imgbox .swipper_img > h4 > span {
        font-size: 100px;
    }
}
@media (max-width: 1600px) {
    .swiper_imgbox .swipper_img > h2 {
        font-size: 60px;
    }
}
@media (max-width: 1600px) {
    .swiper_imgbox .swipper_img > h3 {
        font-size: 58px;
    }
}
@media (max-width: 1600px) {
    .swiper_imgbox .swipper_img > h1 {
        font-size: 100px;
        margin-top: 30px;
        margin-left: -5px;
    }
}
@media (max-width: 1600px) {
    .banner_box_wrapper .swiper_imgbox {
        margin: 40px 0 0;
        padding: 0 15px 0 0;
    }
}
@media (max-width: 991px) {
    .swiper_imgbox .swipper_img > h4 {
        font-size: 12px !important;
        line-height: 27px;
    }
    .swiper_imgbox .swipper_img > h2,
    .swiper_imgbox .swipper_img > h3 {
        font-size: 30px !important;
        line-height: 27px;
    }
    .swiper_imgbox .swipper_img > p {
        font-size: 14px !important;
    }
    .swiper_imgbox .swipper_img > h1,
    .swiper_imgbox .swipper_img > h4 > span,
    .int_infosidebar {
        display: none;
    }
    .main_contentblock {
        padding: 20px 0px 100px;
    }
    .int_content_wraapper.int_content_left {
        margin-left: 20px;
    }
    .banner_box_wrapper .banner_navi {
        left: 70px;
        bottom: -8px;
    }
}
@media (max-width: 767px) {
    .main_contentblock {
        text-align: center;
        padding: 12px 0px 92px;
        width: 80%;
        margin: auto;
    }
}
@media (max-width: 480px) {
    .main_contentblock {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .swiper_imgbox .btn_caret {
        display: none;
    }
    .banner_box_wrapper .banner_navi {
        left: 48%;
        transform: translate(-50px, 20px);
        bottom: 12px;
    }
    .main_imgblock {
        display: none;
    }
    .swiper_imgbox .homes-list .fa-bed,
    .swiper_imgbox .homes-list .fa-bath,
    .swiper_imgbox .homes-list,
    .int_white_bg .swiper_imgbox .homes-list .fa-bed,
    .int_white_bg .swiper_imgbox .homes-list .fa-bath,
    .int_white_bg .swiper_imgbox .homes-list {
        margin-left: 1rem;
    }
    .swiper_imgbox .homes-list li,
    .int_white_bg .swiper_imgbox .homes-list li {
        font-size: 15px !important;
    }
}
@media (max-width: 575px) {
    .int_content_wraapper.int_content_left {
        margin-left: 0;
    }
    .int_content_wraapper {
        margin-left: 0px;
    }
}
/* button css */
.int_btn,
.int_btn:focus {
    min-width: 140px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #ffffff;
    background: #c18847;
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    transition-property: color;
    transition-duration: 0.5s;
}
.int_btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333333;
    transform: scaleX(0);
    transform-origin: 0% 10% 0;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
}
.int_btn:hover:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.int_btn.left_zero:before {
    transform-origin: 100% 50%;
}
.int_btn_two:focus {
    background: #ff1d4d;
}
.int_btn_two {
    background: #ff1d4d;
    width: 170px;
    letter-spacing: 1px;
}
.btn_caret {
    height: 50px;
    line-height: 53px;
    background: #c18847;
    display: inline-block;
    padding: 0px 12px;
    position: absolute;
    top: 0;
    right: -29px;
}
.btn_caret_two {
    background: #f41a48;
}
.int_btn:hover {
    color: #fff;
    background: #555555;
}
/* button css */


/*------------------------------------*/
/*- 25. FEATURED PROPERTIES HOME 13 --*/
/*------------------------------------*/
.fs-listings,
.listings-container {
    position: relative;
    z-index: 9;
}
.listing-item {
    background-color: #fff;
    display: block;
    width: 100%;
    border-radius: 3px;
}
.owl-carousel .listing-item {
    margin-bottom: 0;
}
.listing-img-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
}
.listing-img-container img {
    width: 100%;
    border-radius: 3px 3px 0 0;
}
.listing-img-container:after,
.listing-img-container:before {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    content: "";
    z-index: 9;
}
.listing-img-container:before {
    opacity: 0.9;
    background: linear-gradient(to bottom, transparent 65%, #2f2f2f);
    transition: opacity .3s;
}
.compact .listing-img-container:before {
    background: linear-gradient(to bottom, transparent 50%, #2f2f2f);
}
.listing-img-container:after {
    opacity: 0;
    background: linear-gradient(to bottom, transparent 60%, #66676b);
    transition: opacity .4s;
}
.listing-img-container:hover:before {
    opacity: 0;
    transition: opacity 0.4s;
}
.listing-img-container:hover:after {
    opacity: 0.9;
    transition: opacity 0.3s;
}
/* Content on image */
.listing-img-content {
    position: absolute;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 30px;
}
.compact .listing-img-content {
    padding: 0;
}
.compact .listing-img-content .listing-compact-title {
    padding: 23px 26px;
    display: block;
}
.compact .listing-img-content .listing-compact-title i {
    display: block;
    font-style: normal;
    font-size: 17px;
    padding: 0;
    margin: 2px 0 0 0;
}
.listing-hidden-content {
    position: relative;
    width: 100%;
    background-color: #c18847;
    color: #fff;
    height: 0;
    transition: all 0.4s;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    padding: 0 0 0 25px;
    display: block;
    overflow: hidden;
}
.listing-item.compact .listing-hidden-content li {
    opacity: 1;
}
.compact .listing-img-container:before {
    transform: translateY(10px);
}
.listing-item.compact:hover .listing-hidden-content {
    height: 70px;
}
.compact .listing-img-container:after {
    display: none;
}
.compact .listing-img-container:before {
    opacity: 0.7 !important;
}
.listing-item.compact li {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    line-height: 20px;
    padding: 15px 20px 0 0;
    position: relative;
    transition: all 0.5s;
    opacity: 0;
}
.listing-item.compact li span {
    display: block;
    font-size: 14px;
    color: #fff;
    margin: 0;
    padding: 0;
}
.compact .listing-img-container:after {
    opacity: 0;
    background: linear-gradient(to bottom, transparent 35%, #2f2f2f 95%);
    transition: opacity .6s;
}
.listing-img-container:after {
    background: linear-gradient(to bottom, transparent 60%, #274abb);
}
.listing-img-container:after,
.listing-img-container:before {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    content: "";
    z-index: 9;
}
.compact .listing-img-container:after {
    opacity: 0;
    background: linear-gradient(to bottom, transparent 35%, #2f2f2f 95%);
    transition: opacity .6s;
}
.listing-img-content span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
.listing-img-content .listing-price i {
    display: block;
    font-style: normal;
    font-size: 16px;
    padding: 0;
    margin: -4px 0 0 0;
}
/* Badges */
.listing-item.compact .listing-badges {
    position: absolute;
    top: 0;
    z-index: 8;
    right: 0;
    width: 100%;
    display: block;
    font-size: 13px;
    padding: 0px;
    overflow: hidden;
    height: 100px;
}
.listing-item.compact .listing-badges span {
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    float: right;
    background-color: #e54242;
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute;
}
.listing-item.compact .listing-badges span.rent {
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    float: right;
    background-color: #ffb200;
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute;
}
.listing-item.compact .listing-badges .featured {
    background-color: #18ba60;
    float: left;
    left: 15px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 90px;
    font-size: 14.5px;
    margin: 0;
}

/*-----------------------------------*/
/*- 26. BANNER SLIDER HOME 14 --*/
/*-----------------------------------*/
body.int_white_bg {
    background-color: #fff;
}
.bg-white-1 {
    background: url(../images/bg/bg-pattern-dot.png) 0 0 repeat !important;
}
.bg-white-2 {
    background: #f8f8f8 !important;
}
.int_white_bg .section-title h3 {
    color: #333;
}
.int_white_bg .section-title h2 {
    color: #c18847;
}
.int_white_bg .section-title:before {
    background: #c18847;
}
.int_white_bg .slick-dots li.slick-active {
    box-shadow: inset 0 0 0 6px #c18847;
}
.int_white_bg .slick-dots li:after {
    background: #c18847;
}
.int_white_bg .slick-dots li {
    box-shadow: inset 0 0 0 2px #c18847;
}
.int_white_bg .services-home .bg-base {
    background: #c18847;
}
.int_white_bg .services-home .border-1 {
    border: 1px dashed #c18847 !important;
    background: #fff;
}
.int_white_bg a.button.border,
a.button {
    background: #c18847;
}
.int_white_bg .services-home .text-bold-700 {
    color: #333;
}
.int_white_bg .services-home .agent-section p {
    color: #666;
}
.int_white_bg .services-home a.text-base {
    color: #333;
}
.int_white_bg .services-home a.text-base:hover {
    color: #c18847;
}
.int_white_bg .portfolio .project-single {
    border: 0px;
    margin-bottom: 0px;
}
.int_white_bg .btn.btn-outline-light {
    background: #c18847;
}
.int_white_bg .bg-all {
    margin-top: 0px;
}
.int_white_bg .portfolio .project-single {
    box-shadow: none;
}
.int_white_bg .feature-cities-1 .listing-badges .featured {
    background: #c18847;
}
.int_white_bg .team-content,
.int_white_bg .team-info {
    background: #fff;
    border: 0px;
}
.int_white_bg .team-wrap:hover .team-content .team-info {
    background: #fff;
}
.int_white_bg .team-content h3,
.int_white_bg .team-content p,
.int_white_bg .team-content span a {
    color: #333;
}
.int_white_bg .team .team-pro .fa {
    background: #c18847;
    border: 0px;
}
.int_white_bg .plan-price,
.int_white_bg .plan a.btn {
    background: #c18847;
}
.int_white_bg .plan a.btn.pro {
    background: #121B22;
}
.int_white_bg .plan-price:after {
    border-top-color: #c18847;
}
.int_white_bg .testimonials .owl-carousel .test-1 {
    background: #fff;
}
.int_white_bg .testimonials .test-1 h3 {
    color: #333;
    margin-bottom: 1.5rem;
}
.int_white_bg .testimonials .test-1 h6,
.int_white_bg .testimonials .test-1 p::before {
    color: #333;
}
.int_white_bg .testimonials .test-1 p {
    color: $text;
}
.int_white_bg .testimonials .test-1 img {
    margin-bottom: .5rem;
}
.int_white_bg .starts .fa {
    color: #febe42 !important;
    margin-top: .5rem;
    margin-bottom: 1rem;
}
.int_white_bg .owl-dot.active {
    background: #c18847 !important;
}
.int_white_bg .testimonials .test-1 p::after {
    display: none;
}
.int_white_bg .blog-section .news-item {
    background: #fff;
}
.int_white_bg .blog-section .news-item h3 {
    color: #333;
}
.int_white_bg .blog-section .news-item-descr p {
    color: $text;
}
.int_white_bg .blog-section .news-item .date,
.int_white_bg .blog-section .news-link {
    color: #c18847;
}
.int_white_bg .blog-section .action-list .fa {
    color: #c18847;
}
.int_white_bg .blog-section .news-item h3:hover {
    color: #c18847;
}
.int_white_bg .first-footer .top-footer {
    border-top: 0px;
}
.int_white_bg .first-footer .contactus ul li .fa,
.int_white_bg .first-footer h3,
.int_white_bg .navigation ul li a,
.int_white_bg .twitter-widget.contuct .twitter-area .icon-holder i,
.int_white_bg .twitter-widget.contuct .twitter-area .text h5 {
    color: #333;
}
.int_white_bg .newsletters p,
.int_white_bg .first-footer .netabout p {
    color: $text;
}
.int_white_bg .navigation ul li a:hover,
.int_white_bg .twitter-widget.contuct .twitter-area .text h5 a,
.int_white_bg .twitter-widget.contuct .twitter-area .text h4 {
    color: #c18847;
}
.int_white_bg .first-footer .navigation h3::after,
.int_white_bg .first-footer .widget h3::after,
.int_white_bg .first-footer .newsletters h3::after {
    background: #c18847;
}
.int_white_bg .first-footer .contactus .info p {
    color: $text;
}
.int_white_bg .first-footer .bloq-email .email input[type="email"] {
    border: 1px solid #c18847;
    background: #282d32;
    color: #fff;
}
.int_white_bg .first-footer .bloq-email .email input[type="submit"] {
    border: 1px solid #c18847;
    background: #c18847;
    color: #fff;
}
.int_white_bg .first-footer .bloq-email .email input::placeholder {
    color: #fff;
}
.int_white_bg .second-footer p {
    color: $text;
}
.int_white_bg .second-footer p .fa-heart:before {
    color: #c18847;
}
.int_white_bg .go-up {
    background: #c18847;
}
.int_white_bg .portfolio .item {
    padding-bottom: 30px;
}
.int_white_bg .swiper_imgbox .swipper_img > h4 {
    font-size: 12px;
    letter-spacing: 8px;
    text-transform: uppercase;
    color: #c18847;
    margin: 0;
    position: relative;
}
.int_white_bg .swiper_imgbox .swipper_img > h4 > span {
    font-size: 90px;
    text-transform: uppercase;
    font-weight: 400;
    color: #f1f1f1;
    letter-spacing: 12px;
    position: absolute;
    left: 10px;
    right: 0;
    top: -50px;
    z-index: -1;
}
.int_white_bg .swiper_imgbox .swipper_img > h2 {
    color: #333;
    font-weight: 600;
    
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.int_white_bg .swiper_imgbox .swipper_img > h3 {
    font-size: 26px;
    color: #c18847;
    margin-bottom: 20px;
    font-weight: 300;
}
.int_white_bg .swiper_imgbox .homes-list li,
.int_white_bg .swiper_imgbox .homes-list li i {
    color: #333;
    font-size: 16px;
}
.int_white_bg .swiper_imgbox .swipper_img > h3 > .banner_span1 {
    color: #c18847;
}
.int_white_bg .swiper_imgbox .swipper_img > p {
    color: #333;
    margin-bottom: 15px;
}
.int_white_bg .banner_box_wrapper .swiper-button-next,
.banner_box_wrapper .swiper-button-prev {
    color: #fff;
}
.int_white_bg .banner_box_wrapper .swiper-button-prev:after,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:after {
    content: "";
    position: absolute;
    width: 45px;
    height: 1px;
    background: #c18847;
    top: 0px;
    bottom: 0;
    left: -55px;
    margin: auto;
}
.int_white_bg .banner_box_wrapper .swiper-button-prev:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-next:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -55px;
    margin: auto;
    background: #c18847;
    width: 45px;
    height: 1px;
}
.int_white_bg .banner_box_wrapper .swiper-button-next:before,
.banner_box_wrapper .swiper-container-rtl .swiper-button-prev:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -55px;
    margin: auto;
    background: #c18847;
    width: 45px;
    height: 1px;
}
.int_white_bg .swiper_imgbox .swipper_img > h1 {
    font-size: 90px;
    text-transform: uppercase;
    color: #f1f1f1;
    font-weight: 600;
    margin-top: 85px;
    margin-left: -9px;
}
.int_white_bg .banner_box_wrapper .swiper-button-prev,
.int_white_bg .banner_box_wrapper .swiper-button-next {
    color: #111;
}
.int_white_bg .int_infosidebar {
    background: #fff;
}
.int_white_bg .int_infosidebar .siderbar_social > ul > li > a,
.int_white_bg .int_infosidebar .siderbar_contact > a,
.int_white_bg .int_infosidebar .siderbar_email > a {
    color: #333;
}

/*---------------------------------------*/
/*--- 27. TYPED IMAGE HOME 15 ---*/
/*---------------------------------------*/
.parallax-searchs.home15 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/bg/bg-15.jpg') no-repeat center top;
    background-size: cover;
    background-attachment: fixed !important;
    width: 100%;
    height: 90vh;
}
.parallax-searchs.home15 .welcome-text p {
    color: rgba(255, 255, 255, 0.89);
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    
    line-height: 37px;
    font-weight: 400;
}
.parallax-searchs.home15 .hero-inner {
    text-align: center;
    padding: 320px 0;
}
.parallax-searchs .welcome-text p {
    color: #fff;
    font-size: 18px;
    
    font-weight: 500;
}
.parallax-searchs .welcome-text h1 {
    font-size: 40px;
    color: #fff;
    
}
/* Search Form */
.parallax-searchs .trip-search {
    background: #fff;
    padding: 10px;
    border: 1px solid #A7CCE8;
    border-radius: 50px;
    text-align: left;
    margin-top: 30px;
}
.parallax-searchs .trip-search .form {
    line-height: 0px;
}
.parallax-searchs .form-group {
    width: 190px;
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 0px;
}
.parallax-searchs .form-group:last-child {
    margin-right: 0px;
}
.parallax-searchs .form-group .nice-select {
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    color: #757575;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.parallax-searchs .form-group .nice-select:hover {
    color: #18ba60;
}
.parallax-searchs .form-group .nice-select::after {
    right: 20px;
    color: #757575;
}
.parallax-searchs .form-group .nice-select i {
    color: #18ba60;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-right: 10px;
}
.parallax-searchs .form-group .list li {
    color: #757575;
}
.parallax-searchs .form-group .list li.selected {
    color: #757575;
    font-weight: 400;
}
.parallax-searchs .form-group .list li:hover {
    color: #fff;
    background: #18ba60;
}
.parallax-searchs .form-group.location {
    width: 150px;
}
.parallax-searchs .form-group.button {
    width: 145px;
    position: relative;
    height: 50px;
}
.parallax-searchs .form-group .btn {
    display: block;
    width: 100%;
    background: #18ba60;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 0;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 14px;
    border: none;
    border-radius: 50px;
}
.parallax-searchs .form-group .btn:hover {
    background: $secondcolor;
    color: #fff;
}
@media only screen and (max-width: 1024px) {
    .parallax-searchs .trip-search .form-group {
        margin-top: 15px;
        margin-right: 20px;
        width: 100%;
    }
}
@media only screen and (max-width: 991px) {
    .parallax-searchs .trip-search .form-group {
        margin-top: 15px;
        margin-right: 20px;
    }
    .parallax-searchs .trip-search {
        border-radius: 1px;
    }
    .parallax-searchs .hero-inner {
        margin: 97px 0 !important;
    }
    .parallax-searchs.home15 .hero-inner {
        padding: 150px 0;
    }
    .parallax-searchs .welcome-text h1 {
        font-size: 26px;
    }
    .parallax-searchs.home15 .welcome-text p {
        font-size: 15px;
    }
    .featured-boxes-area {
        top: 50px;
    }
    .int_dark_bg .portfolio .item.landscapes.no-pb {
        margin-bottom: 0px;
    }
    .int_dark_bg .portfolio .item.landscapes.no-pb {
        padding-bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .parallax-searchs .welcome-text h1 {
        font-size: 30px;
    }
    .parallax-searchs .hero-inner {
        margin: 69px 0;
    }
    .parallax-searchs .trip-search .form-group {
        margin-top: 10px;
        width: 100%;
    }
    .header-top.head-tr {
        display: none;
    }
}
@media only screen and (max-width: 450px) {
    .parallax-searchs .welcome-text h1 {
        font-size: 26px !important;
        line-height: 34px;
    }
    .parallax-searchs .hero-inner {
        padding-top: 55px !important;
    }
    .parallax-searchs.home15 .welcome-text p {
        line-height: 25px;
    }
    .parallax-searchs.home15 {
        height: 100vh;
    }
    .featured-boxes-area {
        top: 100px;
        padding-bottom: 6rem;
    }
    .parallax-searchs .welcome-text p {
        font-size: 14px;
    }
    .parallax-searchs .hero-inner {
        margin: 70px 0;
    }
    .parallax-searchs .trip-search {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 360px) {
    .parallax-searchs .welcome-text h1,
    .parallax-searchs.home15 .welcome-text p {
        display: none;
    }
    .parallax-searchs .hero-inner {
        padding-top: 45px !important;
    }
    .parallax-searchs .trip-search {
        margin-top: 0px;
    }
}

/*--------------------------------------------*/
/*-- 28. SECTION INFO HOME 15 --*/
/*--------------------------------------------*/
.featured-boxes-area {
    position: relative;
    z-index: 1;
    margin-top: -100px
}
.featured-boxes-inner {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, .1)
}
.featured-boxes-inner .col-lg-3 {
    border-right: 1px solid #eee
}
.featured-boxes-inner .col-lg-3:last-child {
    border-right: none
}
.single-featured-box {
    text-align: center;
    position: relative;
    padding: 115px 25px 40px;
    overflow: hidden
}
.single-featured-box .icon {
    transition: .4s;
    color: #ee0979;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -12px
}
.single-featured-box .icon i::before {
    font-size: 55px
}
.single-featured-box h3 {
    transition: .4s;
    margin: 15px 0;
    font-size: 18px;
}
.single-featured-box p {
    transition: .4s;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 0
}
.single-featured-box .read-more-btn {
    background: linear-gradient(90deg, #000 0, #000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: .6;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
}
.single-featured-box .read-more-btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 0;
    transition: .5s;
    height: 1px;
    background: linear-gradient(90deg, #18ba60 0, #18ba60 100%)
}
.single-featured-box .read-more-btn:hover::before {
    width: 100%
}
.single-featured-box:hover .icon {
    top: 23px;
    animation: .4s ease-in-out iconTop
}
.single-featured-box:hover h3,
.single-featured-box:hover p {
    transform: translateY(-20px)
}
.single-featured-box:hover .read-more-btn {
    opacity: 1;
    visibility: visible;
    bottom: 23px
}
@-webkit-keyframes iconTop {
    0% {
        transform: translate(-50%, 0)
    }
    25% {
        opacity: 0;
        transform: translate(-50%, -70%)
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -40%)
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0)
    }
}
@keyframes iconTop {
    0% {
        transform: translate(-50%, 0)
    }
    25% {
        opacity: 0;
        transform: translate(-50%, -70%)
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -40%)
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0)
    }
}
@-webkit-keyframes MOVE-BG {
    from {
        transform: translateX(0)
    }
    to {
        transform: translateX(88px)
    }
}
@keyframes MOVE-BG {
    from {
        transform: translateX(0)
    }
    to {
        transform: translateX(88px)
    }
}
@-webkit-keyframes rotateMe {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}

/*---------------------------------------------*/
/*-- 29. SECTION FEATURED PROPERTIES HOME 15 --*/
/*---------------------------------------------*/
section.featured.home15 {
    padding: 6rem 0;
    background: #fff;
}
.featured.home15 .sec-title {
    margin-left: 0;
}
.featured.home15 .sec-title h2 span {
    font-weight: 600;
    color: #111;
}
.featured.home15 .sec-title h2 {
    color: $firstcolor;
    margin-bottom: .5rem;
}
.featured.home15 .item {
    padding-bottom: 30px;
}
.featured.home15 .filters-group {
    margin-bottom: 2.5rem;
    padding-left: 0px;
}
.featured.home15 .filters-group ul {
    padding-left: 0px;
}
.featured.home15 .filters-group ul li {
    list-style: none;
    color: #fff;
    display: inline-block;
    padding: 10px 15px;
    
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    transition: all .5s ease;
}
.featured.home15 .filters-group ul li:hover {
    background: $firstcolor;
    color: #fff;
}
.featured.home15 .filters-group {
    text-align: center;
    border-radius: 5px;
}
@media screen and (max-width: 543px) {
    .featured.home15 .filters-group ul {
        padding-left: 0px;
    }
    .featured.home15 .filters-group ul li:hover {
        background: none;
        color: #000;
    }
}
.featured.home15 .filters-group .active {
    background: $firstcolor;
    color: #fff;
}
.project-single {
    margin-bottom: 10px;
}
.project-single.no-mb {
    margin-bottom: 0px;
}
.featured.home15 .item.no-pb {
    padding-bottom: 0px;
}
@media screen and (max-width: 767px) {
    .project-single.no-mb.last:last-child {
        margin-bottom: 0px;
    }
    .featured.home15 .filters-group {
        text-align: left;
        margin-bottom: 2rem;
    }
    .filters-group.col-md-6 {
        margin-left: 1rem;
    }
    .featured.home15 .item.sale.no-pb {
        padding-bottom: 1.6rem;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 991px) {
    .featured.home15 .item.landscapes.no-pb {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .featured.home15 .item.h33 {
        padding-bottom: 30px !important;
    }
    .project-single.mbx-10 {
        margin-bottom: 10px;
    }
    .featured .compare {
        margin-top: .9rem !important;
    }
}
.homes-img {
    display: block;
}
.homes,
.homes-img {
    position: relative;
    overflow: hidden;
}
.featured.home15 .homes-tag.featured {
    background-color: transparent !important;
    float: left;
    left: 0px !important;
    top: 0px !important;
    position: relative;
    text-align: left;
    width: 200px !important;
    font-size: 14px !important;
    margin: 0;
}
.featured.home15 .homes-tag.button.alt.featured.pt-44 {
    padding-top: 25px;
    padding-left: 0px;
}
.featured.home15 .homes .button {
    font-size: 15px;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    color: #fff;
    border: none;
    background-color: $firstcolor;
    display: inline-block;
    z-index: 99;
}
.featured.home15 .homes-tag.featured {
    left: 15px !important;
    margin-top: 15px;
}
.featured.home15 .homes-price,
.featured.home15 .homes-tag {
    position: absolute !important;
    z-index: 99 !important;
}
.featured.home15 .homes-tag.sale {
    background: #e54242 !important;
    width: 84px !important;
    height: 36px;
}
.featured.home15 .homes-tag.sale {
    right: 15px;
    margin-top: 15px;
    top: 0;
}
.featured.home15 .homes-tag.sale.rent {
    background: #34495e !important;
    text-align: center;
    color: #fff;
    width: 96px !important;
    z-index: 99;
}
.featured.home15 .price-properties {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.featured.home15 .compare {
    margin-top: 1.1rem;
    line-height: 0px;
}
.featured.home15 .compare a {
    margin-right: 1.5rem;
}
.featured.home15 .compare a:last-child {
    margin-right: 0px;
}
.featured.home15 .compare i {
    font-size: 1rem;
    transition: all .5s ease;
}
.featured.home15 .fa-exchange-alt {
    color: #666;
}
.featured.home15 .fa-exchange-alt:hover {
    color: #18ba60;
}
.featured.home15 .fa-share-alt {
    color: #666;
}
.featured.home15 .fa-share-alt:hover {
    color: #ffb200;
}
.featured.home15 .fa-heart-o {
    color: #666;
}
.featured.home15 .fa-heart-o:hover {
    color: #e54242;
}
.featured.home15 .homes-price {
    color: #fff;
    font-size: 15px !important;
    font-weight: 600;
    padding: 6px 14px;
    border-radius: 2px;
    background: #18ba60;
    bottom: 20px;
    left: 15px;
    z-index: 99;
}
.featured.home15 .homes-content {
    background: #212529 !important;
    padding: 20px 20px !important;
}
.featured.home15 .homes-content h3.title a {
    font-size: 1.1rem;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    text-decoration: none;
}
.featured.home15 .homes-content h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 20px;
}
.featured.home15 .homes-content h3 a {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px;
    text-decoration: none;
    transition: all .3s ease;
}
.featured.home15 .homes-content h3 a:hover {
    color: $firstcolor;
}
.price-properties h3 {
    margin-top: 1.3 !important;
    margin-bottom: 0px;
}
.featured.home15 .homes-content .homes-address {
    margin-top: 0px;
}
.featured.home15 .homes-content .homes-address .fa-map-marker {
    font-size: 1rem;
    text-decoration: none;
    color: #18ba60;
}
.featured.home15 .homes-content .homes-address a {
    text-decoration: none;
}
.featured.home15 .homes-content .homes-address a span {
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 1px;
    text-decoration: none;
}
.featured.home15 .homes-address span {
    color: $text;
}
.featured.home15 .homes-content .homes-address a i {
    margin-right: 10px;
}
.featured.home15 .homes-content .homes-list {
    padding-left: 0px;
    padding-bottom: 0;
    margin: 0;
}
.featured.home15 .homes-content .homes-list li {
    width: 33%;
    float: left;
    font-size: 13px;
    line-height: 40px;
    list-style: none;
    color: $firstcolor;
}
.featured.home15 .homes-content .homes-list li i {
    font-size: 14px;
    margin-right: 5px;
    color: #cecdcd;
}
.featured.home15 .homes-content .homes-list span {
    color: #fff;
}
.featured.home15 .footer {
    background: none;
    border-top: 1px solid #d8d8d8;
    padding-top: 1rem;
    font-size: 13px;
    color: $text;
}
.featured.home15 .footer a {
    color: $text;
    text-decoration: none;
}
.featured.home15 .footer span {
    float: right;
}
.featured.home15 .footer i {
    margin-right: 5px;
    font-size: 1rem;
    color: $text;
}
/*----- HOVER EFFECT -----*/
.featured.home15 .project-single {
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 0px !important;
}
.featured.home15 .project-inner {
    position: relative;
    overflow: hidden;
}
.featured.home15 .project-head {
    position: relative;
    text-align: center;
}
.featured.home15 .project-head:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    z-index: 33;
    background: $secondcolor;
    color: #fff;
    text-align: center;
    transition: all .5s ease;
}
.featured.home15 .project-single:hover .project-head:before {
    opacity: 0.8;
}
.featured.home15 .project-head img {
    width: 100%;
    z-index: 2;
}
.featured.home15 .project-bottom {
    text-align: center;
    padding: 10px 0 0;
    position: absolute;
    width: 100%;
    text-align: left;
    z-index: 333;
    text-align: center;
    top: 50%;
    margin-top: -23.5px;
}
.featured.home15 .project-bottom h4 {
    
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
}
.featured.home15 .project-bottom h4:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
}
.featured.home15 .project-single:hover .project-bottom h4 {
    opacity: 1;
    visibility: visible;
}
.featured.home15 .project-bottom h4 a {
    color: #fff;
    text-decoration: none;
}
.featured.home15 .project-bottom h4 span {
    display: block;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
}
.featured.home15 .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(0%);
    opacity: 0;
    visibility: visible;
    padding: 8px 15px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.featured.home15 .project-single .button-effect {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}
.featured.home15 .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    line-height: 33px;
    background: rgba(0, 0, 0, .6);
    z-index: 99;
}
.featured.home15 .button-effect .btn:hover {
    color: #fff;
}
.featured.home15 .button-effect .btn:last-child {
    margin: 0;
}
.featured.home15 .load-button {
    margin-top: 50px;
}
.featured.home15 .load-button .btn {
    padding: 14px 30px;
    color: #fff;
    font-weight: 500;
    border: none;
    transition: all 0.4s ease;
    margin: 0;
}
.featured.home15 .load-button .btn:hover {
    background: #353535;
}
.featured.home15 .button-effect .fa-link:before {
    content: "\f0c1";
    font-family: FontAwesome;
    color: #fff;
}
.featured.home15 .button-effect .fa-video:before {
    content: "\f03d";
    font-family: FontAwesome;
    color: #fff;
}
.featured.home15 .button-effect .fa-photo:before {
    content: "\f03e";
    font-family: FontAwesome;
    color: #fff;
}

/*----------------------------------------------*/
/*-------- 30. SECTION INFO HELP HOME 15 -------*/
/*----------------------------------------------*/
.info-help {
    padding: 6rem 0;
    background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(../images/bg/bg-info3.jpg) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 3;
}
.info-help:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.info-help .info-head .info-text {
    padding-left: 0px;
}
.info-help .info-head .info-text h3 {
    font-size: 30px;
    line-height: 45px;
    color: #fff;
    margin-bottom: 0px;
}
.info-help .info-head .info-text p {
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    color: #fff;
    padding: 1.5rem 0;
    margin-bottom: 0px;
}
.info-help .info-head .info-text .btn-pro {
    background: #18ba60;
    color: #fff;
    margin-top: 10px;
    border: 0;
    transition: all .5s ease;
}
.info-help .info-head .info-text .btn-pro:hover {
    background: $secondcolor;
}
@media screen and (max-width: 767px) {
    .info-help .info-head .info-text h3 {
        font-size: 25px;
    }
    .info-help .info-head .info-text p {
        font-size: 16px;
        ;
    }
}
.rev_row_zone_bottom,
.tp-caption.have {
    bottom: 80px;
}
.hermes .tp-bullet,
.uranus.tparrows {
    display: none;
}

/*------------------------------------------*/
/*--- 31. SECTION VISITED CITIES HOME 15 ---*/
/*------------------------------------------*/
section.visited-cities {
    padding: 6rem 0;
}
.visited-cities .img-box {
    height: 280px;
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
    z-index: 90;
    border-radius: 3px;
}
.visited-cities .img-box.no-mb {
    margin-bottom: 0px;
}
.visited-cities .img-box:hover {
    background: $firstcolor;
}
.visited-cities .img-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-color: $secondcolor;
    opacity: 0.2;
    z-index: 99;
    transition: 0.3s ease-out;
}
.visited-cities .listing-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    overflow: hidden;
    height: 100px;
}
.visited-cities .listing-badges .featured {
    background-color: $firstcolor;
    float: left;
    transform: rotate(-45deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
}
.visited-cities .listing-badges span {
    color: #fff;
    display: inline-block;
    padding: 3px;
    float: right;
    background-color: rgba(74, 74, 76, 0.7);
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute;
}
.visited-cities .starts {
    list-style: none;
    margin-top: 0;
    padding: 0px;
    margin-bottom: 0px;
}
.visited-cities .starts li {
    text-align: center;
    display: inline-block;
    margin-bottom: 0px;
}
.visited-cities .starts .fa {
    color: #fe4f4f !important;
}
.visited-cities .starts ul {
    margin-bottom: 0px;
}
.visited-cities .img-box-content {
    position: absolute;
    z-index: 101;
    top: 50%;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    transform: translate(0, -50.5%);
}
.visited-cities .img-box-content h4 {
    font-size: 20px;
    color: #fff;
    margin: 0;
}
.visited-cities .img-box-content span {
    font-size: 16px;
    color: #fff;
}
.visited-cities .img-box img {
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
    transition: all 0.55s;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    transition: transform 0.35s ease-out;
}
@media screen and (max-width: 991px) {
    .visited-cities .pr {
        padding-right: 5px;
    }
    .tp-caption.have {
        bottom: -80px;
    }
}
@media screen and (max-width: 767px) {
    .visited-cities .img-box.no-mb {
        margin-bottom: 1.5rem;
    }
    .visited-cities .img-box.no-mb.x3 {
        margin-bottom: 0px;
    }
    .visited-cities .img-box.san.no-mb.x3 {
        margin-bottom: 0px !important;
    }
    .visited-cities .img-box.mi,
    .visited-cities .img-box {
        margin-bottom: 1.6rem !important;
    }
    .visited-cities .bg-all {
        margin-top: 0px;
    }
    .visited-cities .pr-1,
    .feature-categories .pr-1,
    .feature-categories .mt,
    .visited-cities .pr {
        padding-right: 15px !important;
    }
}

/*-----------------------------------------------*/
/*--- 32. SECTION RECENTLY PROPERTIES HOME 16 ---*/
/*-----------------------------------------------*/
.recent-16 {
    display: block;
    position: relative;
    overflow: hidden;
    height: 400px;
}
.recent-img16 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 1s ease-in-out;
}
.recent-16:hover .recent-img16 {
    transform: scale(1.06, 1.06);
}
.recent-content {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.recent-details {
    position: absolute;
    padding: 20px;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
    transition: all .4s ease-in-out;
}
.recent-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 4px;
}
.recent-price {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
}
.recent-price > small {
    font-size: 60%;
}
.house-details {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.house-details > span {
    opacity: 0.5;
    display: inline-block;
    padding: 0 5px;
}
.recent-16:hover .recent-details {
    transform: translateY(-30%);
}
.view-proper {
    position: absolute;
    color: #fff;
    padding: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    font-size: 14px;
    letter-spacing: 1px;
    opacity: 0;
    font-weight: 400;
    transform: translateY(400%);
    transition: all .4s ease-in-out;
}
.recent-16:hover .view-proper {
    transform: translateY(0);
    opacity: 1;
}
.img-center {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

/*----------------------------*/
/*--- 33. PARALLAX HOME 17 ---*/
/*----------------------------*/
.parallax-searchs.home17 {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg/home-17-2.jpg') no-repeat center top;
    background-size: cover;
    background-attachment: fixed !important;
    width: 100%;
    height: 100vh;
}
.banner-inner-wrap {
    height: 110vh;
    display: flex;
    align-items: center;
}
.banner-inner-area {
    position: relative;
}
.tab-content>.active {
    display: block;
}
.banner-inner .sub-title {
    color: #fff;
    margin-bottom: 22px;
}
.banner-inner h1 {
    color: #fff;
    font-size: 45px;
    line-height: 66px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-bottom: 1.6rem;
    
}
.banner-inner .content {
    margin-top: 20px;
    margin-bottom: 0;
}
.banner-inner .banner-btn-wrap {
    margin-top: 19px;
}
.banner-inner .btn {
    height: 55px;
    line-height: 55px;
}
.banner-inner .video-play-btn {
    height: 55px;
    width: 55px;
    line-height: 52px;
    text-align: center;
    border-radius: 6px;
    display: inline-block;
    border: 2px solid #D4D4D4;
    font-size: 20px;
    color: #fff;
}
.banner-inner .rld-banner-search {
    margin-top: 33px;
}
.thumb-wrap {
    position: absolute;
    bottom: 0;
}
.main-search-area {
    margin-top: -50px;
}
.rld-main-search {
    padding: 50px 30px 35px 30px;
    background: #fff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0 6px 6px 6px;
}
.rld-main-search .rld-single-input,
.rld-main-search .rld-single-select .single-select {
    margin-bottom: 15px;
}
.rld-main-search .btn {
    width: 100%;
}
.btn-yellow {
    background: #687389;
    color: #fff;
    border: 1px solid #687389;
}
.btn.btn-yellow {
    height: 48px;
    line-height: 48px;
    padding: 0 30px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border: 1px solid #687389;
    font-size: 14px;
    transition: all 0.5s ease;
    font-weight: 600;
}
.btn-yellow:hover,
.btn-yellow:focus {
    background: #fff;
    border: 1px solid #687389;
    color: #687389;
}
.rld-single-input {
    position: relative;
}
.rld-single-input input {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #D4D4D4;
    transition: all 0.4s ease;
    border-radius: 4px;
    font-size: 15px;
}
.rld-single-input input:hover,
.rld-single-input input:active,
.rld-single-input input:focus {
    border: 1px solid #687389;
}
.rld-single-input.left-icon:before {
    content: '\f041';
    font-size: 18px;
    font-family: fontawesome;
    color: #687389;
    position: absolute;
    top: 12px;
    background-size: auto;
    height: 17px;
    width: 12px;
    left: 19px;
    background-repeat: no-repeat;
}
.rld-single-input.left-icon input {
    padding-left: 40px;
}
.rld-single-input textarea {
    height: 120px;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #D4D4D4;
    transition: all 0.4s ease;
    border-radius: 4px;
    font-size: 15px;
}
.rld-single-input textarea:hover,
.rld-single-input textarea:active,
.rld-single-input textarea:focus {
    border: 1px solid #687389;
}
.rld-single-select .single-select {
    height: 48px;
    line-height: 47px;
    width: 100%;
    padding: 0 34px 0 20px;
    border: 1px solid #D4D4D4;
    transition: all 0.4s ease;
    position: relative;
    border-radius: 4px;
}
.rld-single-select .single-select:hover,
.rld-single-select .single-select:active,
.rld-single-select .single-select:focus {
    border: 1px solid #687389;
}
.rld-single-select .single-select:after {
    border: 0;
}
.rld-single-select .single-select:before {
    content: '\f0dd';
    font-size: 20px;
    font-family: fontawesome;
    color: #687389;
    position: absolute;
    top: 0px;
    margin: 0px;
    background-size: auto;
    height: 0px;
    width: 11px;
    right: 14px;
    left: auto;
    background-repeat: no-repeat;
}
.rld-single-select .single-select .list {
    margin-top: 0px;
    width: 100%;
}
.rld-price-slider-wrap .rld-price-slider,
.rld-price-slider-wrap .rld-size-slider {
    height: 5px;
    background: #FFF0E5;
    border: 0;
    margin: 20px 0 0 0;
}
.rld-price-slider-wrap .ui-slider-handle {
    height: 24px;
    width: 42px;
    background: #687389;
    border: 0;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    top: -10px;
    font-size: 13px;
    line-height: 25px;
}
/****** banner-search *******/
.rld-banner-search .rld-single-input input {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border: 0;
    padding-right: 140px;
}
.rld-banner-search .rld-single-input button {
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    line-height: 48px;
    border: 0;
    padding: 0 20px;
    font-weight: 600;
    background: var(--main-color-one);
    color: #fff;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
}
/******* banner tab style *****/
.banner-area {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.banner-area .banner-search-wrap {
    margin-top: 24px;
}
.rld-banner-tab {
    border-bottom: 0;
}
.rld-banner-tab .nav-item {
    margin-bottom: 0;
}
.rld-banner-tab .nav-item a {
    border: 0;
    border-radius: 5px 5px 0 0;
    height: 50px;
    line-height: 50px;
    padding: 0 35px;
    margin-right: 8px;
    position: relative;
    display: block;
    letter-spacing: 0.4px;
    background: #fff;
    color: #000;
    font-family: var(--heading-font);
    font-weight: 600;
    transition: all 0.4s ease;
}
.rld-banner-tab .nav-item a:hover,
.rld-banner-tab .nav-item a:focus,
.rld-banner-tab .nav-item a.active {
    background: #687389;
    color: #fff;
}
.rld-banner-tab .nav-item:last-child {
    margin-bottom: 0;
}
.navbar-area-3 {
    background: #fff;
}
.banner-search {
    margin-top: 80px;
}

/*----------------------------------------------*/
/*-------- 34. SECTION INFO HELP HOME 17 -------*/
/*----------------------------------------------*/
.info-help.h18 {
    padding: 9rem 0;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url(../images/bg/info-service4.png) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 0 !important;
}
.info-help.h17 {
    padding: 9rem 0;
    background: url(../images/bg/info-service6.png) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 0 !important;
}
.info-help.h17:before {
    background: none;
}
.info-help.h17 .info-head .info-text {
    background: #fff;
    padding: 30px;
}
.info-help.h17 .info-head .info-text h3 {
    color: #333;
    line-height: 32px;
}
.info-help.h17 .info-head .info-text h5 {
    color: #333;
    font-weight: 500;
}
.info-help.h17 .info-head .info-text p {
    color: #666;
}
.portfolio.ho-17 .item {
    padding-bottom: 30px;
}
.inner-pages .portfolio.ho-17 .item {
    padding-bottom: 30px;
}
@media screen and (max-width: 360px) {
    .banner-inner h1,
    .banner-inner .sub-title {
        display: none;
    }
    .banner-inner-wrap {
        height: 120vh;
    }
}
@media screen and (max-width: 767px) {
    .portfolio .item.sale.h-177.no-pb {
        padding-bottom: 30px;
        margin-bottom: 0px;
    }
    .portfolio .ho17-thelast {
        padding-top: 30px;
        padding-bottom: 0px !important;
    }
}
@media screen and (max-width: 991px) {
    .portfolio .ho17-thelast,
    .portfolio .item.sale.h-177.no-pb {
        padding-bottom: 0px !important;
    }
}

/*-----------------------------------*/
/*------ 35. AGENTS HOME 17 ----*/
/*-----------------------------------*/
section.team {
    padding: 6rem 0;
    background: #f7f7f7;
}
.team-block {
    position: relative;
}
.team-block .inner-box {
    position: relative;
    padding-bottom: 0px;
    box-shadow: 2px 2px 30px #ccc;
    transition-duration: .5s;
}
.team-block .inner-box .image {
    position: relative;
}
.team-block .inner-box .image img {
    position: relative;
    width: 100%;
    display: block;
    height: auto;
}
.team-block .inner-box .lower-box {
    position: relative;
    padding: 22px 0;
    text-align: center;
}
.team-block .inner-box .lower-box h3 {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 0px;
}
.team-block .inner-box .lower-box h3 a {
    position: relative;
    color: #000;
    text-decoration: none;
    transition: all 0.3s ease;
}
.team-block .inner-box .lower-box h3 a:hover {
    color: #687389;
}
.team-block .inner-box .lower-box .designation {
    position: relative;
    color: $text;
    font-size: 16px;
    font-weight: 400;
    padding-top: 2px;
    
}
@media screen and (max-width: 991px) {
    .team-block {
        padding-bottom: 2.5rem;
    }
    .team-block.pb-none {
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 767px) {
    .team-block.pb-none {
        padding-bottom: 2.5rem;
    }
    .team-block.pb-none:last-child {
        padding-bottom: 0px;
    }
}
/*------ HOVER EFFECT ------*/
.team .team-head {
    position: relative;
    overflow: hidden;
}
.team .team-details {
    background: #fff;
    transition: all 0.4s ease;
}
.team .team-head::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    content: "";
    z-index: 2;
    transition: all 0.4s ease;
}
.team .team-head img {
    width: 100%;
    position: relative;
}
.team .team-social {
    background: transparent;
    width: 75px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    z-index: 33;
    opacity: 0;
    visibility: hidden;
    transform-origin: left;
    transform: perspective(600px) rotateY(90deg);
    transition: all 0.4s ease;
}
.team ul.team-social {
    margin: 0px;
}
.team .team-details .team-social {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateY(0deg);
}
.team .team-social li a {
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid #d4cfcf;
}
.team .team-social li a.linkedin {
    border-bottom: 0px;
}
.team .team-social li a.facebook {
    background-color: #687389;
}
.team .team-social li a.twitter {
    background-color: #687389;
}
.team .team-social li a.instagram {
    background-color: #687389;
}
.team .team-social li a.linkedin {
    background-color: #687389;
}
.team .team-social li {
    display: block;
}
.team .team-social li:last-child {
    margin: 0;
}
.team .team-details:hover .team-bottom {
    background-color: #F9A056;
    transition: all 0.4s ease;
}

/*---------------------------------------------*/
/*--------- 36. HEADER IMAGE HOME 18 ----------*/
/*---------------------------------------------*/
.header-image.home-18 {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/bg/home-18.jpg') no-repeat center top;
    background-size: cover;
    background-attachment: fixed !important;
    width: 100%;
    height: 100vh;
}
.header-image .left h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    color: #fff;
    margin: 0;
    margin-bottom: 20px;
}
.header-image .left p {
    font-size: 17px;
    width: 90%;
    font-weight: 400;
    line-height: 27px;
    color: #fff;
    margin: 0;
}
.header-image .left .btn-white {
    background: #f55d2c;
    display: inline-block;
    padding: 12px 35px;
    border-radius: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-top: 30px;
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.15);
}
a.btn-white {
    text-decoration: none;
}
.google-maps.home-18 .filter {
    padding: 20px;
    margin-top: 180px;
    width: 70%;
    position: relative;
    z-index: 100;
    border-radius: 5px;
    background: rgba(255, 255, 255, .3);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    float: right;
    color: #fff;
}
@media (max-width: 991.98px) {
    .header-image {
        text-align: center;
        padding: 600px 30px;
    }
    .google-maps.home-18 .filter {
        margin-top: 0px;
    }
    .google-maps.home-18 .filter {
        width: 100%;
    }
    .google-maps.home-18 {
        top: 60px;
    }
    .header-image .left {
        margin-top: 150px;
        margin-bottom: 0px;
    }
    .header-image .left h1 {
        font-size: 28px;
        line-height: 37px;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 767px) {
    .header-image.home-18 {
        height: 10vh;
    }
    .header-image {
        padding: 350px 30px;
    }
    .header-image .left {
        margin-top: 10px;
    }
    .header-image .left h1 {
        font-size: 26px;
    }
    .header-image .left p {
        font-size: 15px;
    }
    .google-maps.home-18 .filter {
        padding: 0px;
    }
    .google-maps.home-18 .filter form {
        background: #f55d2c;
        border-top: 1px solid #f55d2c;
    }
    .home18 .sec-title h2 {
        font-size: 24px !important;
    }
    .home18 .sec-title h2 span::before {
        display: none;
    }
}

/*--------------------------------------------*/
/*---- 37. SECTION WHY CHOOSE US HOME 18 -----*/
/*--------------------------------------------*/
section.how-it-works {
    background: #fff;
    padding: 80px 0;
}
.how-it-works .serv-flex {
    background: #fff;
    color: #f55d2c;
    text-align: center;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    border: 1px solid #f1f1f1;
    transition: .5s ease;
}
.how-it-works .serv-flex.arrow:after {
    display: none;
}
.how-it-works .art-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.how-it-works article .fa {
    padding-right: 1rem;
    padding-bottom: .5rem;
    font-size: 4rem;
    text-align: left;
}
.how-it-works article img {
    padding-right: 1rem;
    padding-bottom: .5rem;
    width: 100px;
    height: 100px;
    text-align: center;
}
.how-it-works .sec-title {
    margin-left: .5rem;
}
.how-it-works .sec-title h2 span {
    font-weight: 600;
    color: #111;
}
.how-it-works .sec-title img {
    position: relative;
    width: 151px;
    height: 26px;
}
.how-it-works article h3 {
    color: #222;
    margin: 1.7rem 0 .2rem;
}
.how-it-works article p {
    color: $text;
    margin-top: 1rem;
    margin-bottom: 0px;
}
.how-it-works .service-1 .serv:hover,
.how-it-works .service-2 .serv:hover {
    cursor: all-scroll;
}
@media screen and (max-width: 767px) {
    .how-it-works .service-1 .serv {
        margin-bottom: 2rem;
    }
    .how-it-works .service-1 .serv:last-child {
        margin-bottom: 0px;
    }
    .how-it-works .mt-50 {
        margin-top: 0px !important;
    }
    .how-it-works .service-1 .serv.mb-0.pt {
        margin-top: 0px !important;
    }
}
@media screen and (max-width: 991px) {
    .how-it-works .mt-50 {
        margin-top: 2rem;
    }
    .how-it-works .service-1 .serv.mb-0 {
        margin-bottom: 0px;
    }
    .how-it-works .service-1 .serv.mb-0.pt {
        margin-top: 2rem;
    }
    .how-it-works .serv-flex:after {
        display: none;
    }
}

/*-------------------------------------------*/
/*----- 38. SECTION TESTIMONIALS HOME 18 ----*/
/*-------------------------------------------*/
section.h18.testimonials {
    padding: 6rem 0;
    background: linear-gradient(rgba(14, 18, 68, 0.88), rgba(14, 18, 68, 0.88)), url(../images/bg/bg-testimonials.jpeg) no-repeat scroll center center;
    background-size: cover;
}
.h18.testimonials .sec-title h2 {
    color: #fff;
}
.h18.testimonials .sec-title p {
    color: #fff;
}
.h18.testimonials .client-info {
    display: inline-block;
}
.h18.testimonials .client-thumb {
    border-radius: 100%;
    height: 60px;
    margin-right: 20px;
    width: 60px;
    float: left;
}
.h18.testimonials .sngl-client {
    border: 1px solid #fafafa;
    margin-bottom: 30px;
    text-align: center;
    margin: 5px;
}
.h18.testimonials .single-testimonial {
    display: inline-block;
    padding: 0 10px;
    width: 100%;
}
.h18.testimonials .clinet-inner {
    margin-top: 20px;
}
.h18.testimonials .client-info h2 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 5px;
    
    font-weight: 600;
}
.h18.testimonials .client-info span {
    color: #fff;
    font-size: 13px;
    display: inline-block;
    text-transform: uppercase;
}
.h18.testimonials .client-comment span::before {
    height: 1px;
    background: #4cc700;
    width: 40px;
    content: "";
    position: absolute;
    bottom: 0;
}
.h18.testimonials .client-comment h3 {
    color: #4cc700;
    margin-bottom: 20px;
    
    font-weight: normal;
    font-style: italic;
}
.h18.testimonials .client-comment {
    background-color: #f55d2c;
    padding: 20px 20px 20px 20px;
    position: relative;
    font-style: italic;
    border-radius: 10px;
    color: #fff;
}
.h18.testimonials .client-comment p {
    margin-bottom: 0
}
.h18.testimonials .client-comment::after {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 16px solid #f55d2c;
    content: "";
    height: 0;
    left: 12px;
    position: absolute;
    width: 0;
    transform: rotate(0);
    bottom: -16px;
}
.h18.testimonials .client-comment::before {
    color: #fff;
    content: "\f10d";
    font-family: fontawesome;
    font-size: 71px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
    opacity: 0.20;
    font-style: normal
}
.h18.testimonials .client-thumb img {
    width: 90px;
    border-radius: 50%;
    margin: 0 auto;
}
.h18.testimonials .client-reviews i {
    color: #e54242;
    font-size: 13px;
}
.h18.testimonials .client-comment > ul {
    padding-bottom: 15px;
}
.h18.testimonials .owl-dots {
    text-align: center;
    margin-top: 3rem;
    line-height: 0;
}
.h18.testimonials .owl-dot {
    border-radius: 50px;
    height: 10px;
    width: 10px;
    display: inline-block;
    background: #fff;
    margin-left: 5px;
    margin-right: 5px;
}
.h18.testimonials .owl-dot.active {
    background: #f55d2c;
}
.h18.testimonials .sec-title {
    text-align: center;
    padding-bottom: 2.5rem;
}
.h18.testimonials .sec-title h2 {
    font-size: 28px;
    color: #f55d2c;
}
.h18.testimonials .sec-title h2 span {
    position: relative;
    color: #fff;
}
.h18.testimonials .sec-title h2 span::before {
    position: absolute;
    width: 50px;
    height: 1px;
    background: #f55d2c;
    top: 18px;
    content: "";
    left: -60px;
}
.h18.testimonials .sec-title p {
    margin-top: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    max-width: 550px;
}
.home18 .sec-title {
    text-align: center;
    padding-bottom: 2.5rem;
}
.home18 .sec-title h2 {
    font-size: 28px;
    color: #f55d2c;
}
.home18 .sec-title h2 span {
    position: relative;
    color: #111;
}
.home18 .sec-title h2 span::before {
    position: absolute;
    width: 50px;
    height: 1px;
    background: #f55d2c;
    top: 18px;
    content: "";
    left: -60px;
}
.home18 .sec-title p {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #333;
    max-width: 550px;
}

/*----------------------------------------------*/
/*--------- 39. HEADER SLIDER HOME 19 ----------*/
/*----------------------------------------------*/
.slide-left-social {
    position: absolute;
    bottom: 30px;
    left: 40px;
    z-index: 20;
}
.slide-left-social ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.slide-left-social ul li {
    float: left;
    margin-right: 60px;
    position: relative;
}
.slide-left-social ul li:last-child {
    margin-right: 0px;
}
.slide-left-social ul li:after {
    content: '';
    position: absolute;
    width: 36px;
    height: 1px;
    right: -46px;
    top: 50%;
    margin-top: 1px;
    background-color: #000;
}
.slide-left-social ul li:last-child:after {
    display: none;
}
.slide-left-social ul li a {
    color: #000;
    font-size: 10px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
}
@media (max-width: 778px) {
    .slide-left-social {
        display: none;
    }
}

/*----------------------------------------------*/
/*--------- 40. HEADER SEARCH HOME 19 ----------*/
/*----------------------------------------------*/
.int_white_bg.h19 .search-2 .form-group .list li.selected:hover,
.int_white_bg.h19 .search-2 .form-group .list li:hover,
.int_white_bg.h19 .search-2 .form-group .btn,
.int_white_bg.h19 .section-title:before,
.int_white_bg.h19 .btn.btn-outline-light,
.int_white_bg.h19 .services-home .bg-base,
.int_white_bg.h19 .listing-hidden-content,
.int_white_bg.h19 .plan-price,
.int_white_bg.h19 .plan a.btn,
.int_white_bg.h19 .go-up {
    background: #293046;
}
.int_white_bg.h19 a.button.border,
a.button {
    background: #293046;
}
.int_white_bg.h19 .dark-overlay h1,
.int_white_bg.h19 .search-2 .form-group .nice-select i,
.int_white_bg.h19 .section-title h2 {
    color: #293046;
}
.int_white_bg.h19 .dark-overlay h1 span {
    color: #fff;
}
.int_white_bg.h19 .search-2 .form-group .nice-select:hover {
    color: #293046;
}
.int_white_bg.h19 .services-home .border-1,
.int_white_bg.h19 .plan,
.int_white_bg.h19 .plan ul li {
    border: 1px dashed #293046 !important;
}
.int_white_bg.h19 .services-home a.text-base:hover {
    color: #293046;
}
.int_white_bg.h19 .listing-item.compact li {
    color: #fff;
}
.int_white_bg.h19 .team-wrap:hover .team-content .team-info {
    background: #293046;
}
.int_white_bg.h19 .team .team-pro .fa {
    background: #212529;
}
.int_white_bg.h19 .plan a.btn.pro2 {
    background: #293046;
}
.int_white_bg.h19 .first-footer .navigation h3::after,
.int_white_bg.h19 .first-footer .widget h3::after,
.int_white_bg.h20 .first-footer .newsletters h3::after {
    background: #293046;
}
.int_white_bg.h19 .navigation ul li a:hover,
.int_white_bg.h19 .twitter-widget.contuct .twitter-area .text h5 a,
.int_white_bg.h19 .twitter-widget.contuct .twitter-area .text h4 {
    color: #293046;
}
.int_white_bg.h19 .first-footer .bloq-email .email input[type="email"],
.int_white_bg.h19 .first-footer .bloq-email .email input[type="submit"] {
    border: 1px solid #293046;
}
.int_white_bg.h19 #navigation.style-1.black ul li:hover {
    background-color: #293046;
    color: #293046;
}
.int_white_bg.h19 .plan-price:after {
    border-top-color: #293046;
}
.int_white_bg.h19 .plan ul li:first-child {
    border-top: 0 !important;
}
.int_white_bg.h19 .plan ul li:last-child {
    border-bottom: 0 !important;
}

/*---------------------------------------------*/
/*--------- 41. HEADER VIDEO HOME 20 ----------*/
/*---------------------------------------------*/
.int_dark_bg.h20 a.button.border,
a.button,
.int_dark_bg.h20 .search-2 .form-group .list li.selected:hover,
.int_dark_bg.h20 .search-2 .form-group .list li:hover,
.int_dark_bg.h20 .search-2 .form-group .btn,
.int_dark_bg.h20 .section-title:before,
.int_dark_bg.h20 .btn.btn-outline-light,
.int_dark_bg.h20 .services-home .bg-base,
.int_dark_bg.h20 .listing-hidden-content,
.int_dark_bg.h20 .plan-price,
.int_dark_bg.h20 .plan a.btn,
.int_dark_bg.h20 .go-up {
    background: #f5b200;
}
.int_dark_bg.h20 .dark-overlay h1,
.int_dark_bg.h20 .search-2 .form-group .nice-select i,
.int_dark_bg.h20 .section-title h2 {
    color: #f5b200;
}
.int_dark_bg.h20 .dark-overlay h1 span,
.int_dark_bg.h20 .blog-section .action-list,
.int_dark_bg.h20 .blog-section .admin p {
    color: #fff;
}
.int_dark_bg.h20 .blog-section .news-link {
    color: #f5b200;
}
.int_dark_bg.h20 .blog-section .news-item .date {
    color: #fff;
}
.int_dark_bg.h20 .search-2 .form-group .nice-select:hover,
.int_dark_bg.h20 .blog-section .news-item h3:hover {
    color: #f5b200;
}
.int_dark_bg.h20 .services-home .border-1,
.int_dark_bg.h20 .plan,
.int_dark_bg.h20 .plan ul li {
    border: 1px dashed #f5b200 !important;
}
.int_dark_bg.h20 .services-home a.text-base:hover {
    color: #f5b200;
}
.int_dark_bg.h20 .listing-item.compact li {
    color: #fff;
}
.int_dark_bg.h20 .team-wrap:hover .team-content .team-info {
    background: #f5b200;
}
.int_dark_bg.h20 .team .team-pro .fa {
    background: #212529;
}
.int_dark_bg.h20 .plan a.btn.pro2 {
    background: #282d32;
}
.int_dark_bg.h20 .first-footer .navigation h3::after,
.int_dark_bg.h20 .first-footer .widget h3::after,
.int_dark_bg.h20 .first-footer .newsletters h3::after {
    background: #f5b200;
}
.int_dark_bg.h20 .navigation ul li a:hover,
.int_dark_bg.h20 .twitter-widget.contuct .twitter-area .text h5 a,
.int_dark_bg.h20 .twitter-widget.contuct .twitter-area .text h4 {
    color: #f5b200;
}
.int_dark_bg.h20 .first-footer .bloq-email .email input[type="email"],
.int_dark_bg.h20 .first-footer .bloq-email .email input[type="submit"] {
    border: 1px solid #f5b200;
}
.int_dark_bg.h20 #navigation.style-1.black ul li:hover {
    background-color: #f5b200;
    color: #f5b200;
}
.int_dark_bg.h20 .plan-price:after {
    border-top-color: #f5b200;
}
.int_dark_bg.h20 .plan ul li:first-child {
    border-top: 0 !important;
}
.int_dark_bg.h20 .plan ul li:last-child {
    border-bottom: 0 !important;
}
@media screen and (max-width: 991px) {
    .int_dark_bg.h20 .fullwidth-home-map.dark-overlay video {
        height: 115vh !important;
    }
}

/*-----------------------------------*/
/*- 42. INNER PAGE PROPERTIES LIST --*/
/*-----------------------------------*/
.inner-pages .blog .block-heading {
    background: #f9f9f9;
    border-radius: 3px;
    height: 52px;
    margin-bottom: 30px;
}
.inner-pages .properties-list.full .project-single {
    border-bottom: 0px;
}
.inner-pages .properties-list.full .my-44 {
    margin: 2.5rem 0;
}
.agents.list .blog .homes-content .homes-list {
    padding-bottom: 2rem;
}
@media screen and (max-width: 991px) {
    .inner-pages .properties-list.full .my-44.ft {
        margin: 0px;
    }
    .inner-pages .properties-list.full .pr-0 {
        padding-left: 0px;
    }
    .inner-pages .properties-list.full .col-lg-8 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .inner-pages .properties-list.full .mb-44 {
        margin-bottom: 2.5rem !important;
    }
    .inner-pages .properties-list.full .row.featured {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media screen and (max-width: 575px) {
    .agents.list .pagination.lis-view {
        margin-top: 0px;
    }
}

/*-------------------------------------------------*/
/*- 43. INNER PAGE PROPERTIES LIST RIGHT SIDEBAR --*/
/*-------------------------------------------------*/
.inner-pages .blog .block-heading {
    background: #f9f9f9;
    border-radius: 3px;
    height: 52px;
    margin-bottom: 30px;
}
.inner-pages .properties-right.list .homes-img img {
    height: 272px;
}
.inner-pages .properties-right.list .project-single {
    border-bottom: 0px;
}
.inner-pages .properties-right.list .my-44 {
    margin: 2.5rem 0;
}
@media screen and (max-width: 991px) {
    .inner-pages .properties-right.list .my-44.ft {
        margin: 0px;
    }
    .inner-pages .properties-right.list .homes-img img {
        height: auto;
    }
    .inner-pages .properties-right.list .pr-0 {
        padding-left: 0px;
    }
    .inner-pages .properties-right.list .col-lg-8 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .inner-pages .properties-right.list .mb-44 {
        margin-bottom: 2.5rem !important;
    }
    .inner-pages .properties-right.list .row.featured {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/*-----------------------------------*/
/*- 44. INNER PAGE PROPERTIES GRID --*/
/*-----------------------------------*/
.inner-pages .blog .block-heading {
    background: #fff;
    border-radius: 3px;
    height: 52px;
    margin-bottom: 30px;
}
.inner-pages section.portfolio {
    background: #f7f7f7;
}
.inner-pages .blog .block-heading h4 {
    line-height: 52px;
    color: $secondcolor;
    display: inline-block;
    padding-right: 20px;
    border-radius: 3px 0 0 3px;
    margin: 0 0 0;
    font-weight: 600;
}
.inner-pages .blog .space-3 {
    margin-bottom: 1.6rem;
}
.inner-pages .blog .block-heading .heading-icon {
    background: $secondcolor;
    width: 50px;
    text-align: center;
    margin-right: 8px;
    display: inline-block;
    border-radius: 3px 0 0 3px;
    position: relative;
    color: #fff;
}
.inner-pages .project-single .homes-content .homes-list li i {
    font-size: 1rem;
}
.inner-pages .project-single .fa-user,
.inner-pages .project-single .fa-calendar {
    color: #666 !important;
}
.inner-pages .portfolio .homes-content .homes-list li {
    // width: 45% !important;
    line-height: 40px !important;
}
.properties-list .item {
    padding-bottom: 1.6rem;
}
@media screen and (max-width: 767px) {
    .inner-pages .blog .item {
        padding-bottom: 1.6rem;
    }
    .inner-pages .portfolio.blog .item.no-pb.x2 {
        padding-bottom: 0px;
    }
}
.inner-pages .blog .block-heading .sorting {
    padding: 8px 12px;
    width: auto;
    background: #FFF;
    border: 1px solid #EEE;
    color: #000;
    margin-right: 5px;
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 300;
    outline: none;
}
@media screen and (max-width: 380px) {
    .inner-pages .blog .block-heading .sorting {
        float: left;
        width: 120px;
    }
}
.inner-pages .blog .block-heading .sorting-options {
    margin: 10px 15px 0 0;
    text-align: right;
}
.inner-pages .blog .block-heading .sorting {
    font-size: 13px;
    font-weight: 300;
    padding: 5px 12px;
    border: solid 1px #000;
}
.inner-pages .blog .block-heading a {
    text-decoration: none;
}
.inner-pages .blog .block-heading .change-view-btn.lde .fa {
    color: $secondcolor !important;
}
.inner-pages .blog .block-heading .change-view-btn.lde:hover {
    background: $firstcolor;
    color: #fff !important;
}
.inner-pages .blog .sorting-options a .active-view-btn .fa {
    color: #fff !important;
}
.inner-pages .blog .change-view-btn {
    color: $secondcolor !important;
    border: 1px solid $secondcolor;
    margin-left: 5px;
    border-radius: 2px;
    background: transparent;
    padding: 5px 9px;
}
.inner-pages .change-view-btn:hover {
    color: $firstcolor !important;
}
.inner-pages .active-view-btn {
    background: $secondcolor !important;
    color: #fff !important;
    margin-left: 5px;
    padding: 5px 9px;
}
.inner-pages .blog .active-view-btn:hover {
    background: $firstcolor !important;
    color: #fff;
}
.inner-pages .blog .block-heading .fa {
    color: #fff;
    margin-right: 0px;
}
.inner-pages .blog .homes .fa {
    color: $firstcolor;
}
.inner-pages .properties-list .compare,
.inner-pages .properties-right .compare {
    margin-top: 1.1rem !important;
}
.inner-pages .properties-right .fa-heart-o {
    color: #666 !important;
}
.inner-pages .blog .single-block {
    padding: 3rem 0;
}
.inner-pages .blog .section-heading .media .media-left i {
    background: $firstcolor;
    color: #fff;
    width: 47px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    font-size: 25px;
    float: left;
}
.inner-pages .blog .section-heading .media .media-left {
    padding-right: 15px;
}
.inner-pages .blog .section-heading .media .media-body h5::after {
    margin-bottom: 16px;
}
.inner-pages .blog .section-heading .media .media-body p {
    margin: 0 0 0;
    color: $text;
    line-height: 0;
}
.inner-pages .blog .main-search-field h5,
.inner-pages .blog .main-search-field-2 h5,
.inner-pages .blog .section-heading .media .media-body h5 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    
}
.inner-pages .blog .main-search-field {
    background: transparent;
    padding-bottom: 0px;
}
.inner-pages .blog .main-search-field select {
    background: #fff;
    border: 2px solid rgb(226, 223, 223);
    color: #333;
}
.inner-pages .blog .main-search-field .at-col-default-mar {
    margin-bottom: 0px;
}
.inner-pages .blog .at-input {
    width: 100%;
    height: 45px;
    padding: 5px 10px;
    color: #000;
    border: 2px solid rgb(226, 223, 223);
    border-radius: 0;
    background: #fff;
}
@media screen and (max-width: 991px) {
    .inner-pages .blog .at-input {
        margin-bottom: 0px;
    }
}
.inner-pages .blog .col-lg-12.no-pds {
    padding-left: 0px;
    padding-right: 0px;
}
.inner-pages .blog .slider_amount {
    color: #000;
    font-weight: 400;
}
.inner-pages .blog .ui-widget.ui-widget-content {
    background: $firstcolor;
}
.inner-pages .blog .hvr-bounce-to-right {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-top: 2rem;
    font-size: 14px;
    cursor: pointer;
    background: $firstcolor;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    padding: 12px 22px;
    border-radius: 0;
    transition: all .5s ease;
}
.inner-pages .blog .hvr-bounce-to-right:hover {
    background: $secondcolor;
}
.inner-pages.homepage-4 .first-footer .navigation h3::after,
.inner-pages.homepage-4 .first-footer .widget h3::after,
.inner-pages.homepage-4 .first-footer .newsletters h3::after,
.inner-pages.homepage-4 .first-footer .bloq-email .email input[type="submit"], 
.inner-pages.homepage-4 .go-up {
    background: $firstcolor;
}
.inner-pages.homepage-4 #navigation.style-1 ul li:hover {
    background: $firstcolor;
}
@media screen and (min-width: 992px) {
.portfolio .item.sale.h-177.no-pb, .ho17-thelast.no-pb, .rent.h-17.no-pb {
    padding-bottom: 0px !important;
    }
}
/*---------------------------------------*/
/*- 45. INNER PAGE PROPERTIES GRID MAP --*/
/*---------------------------------------*/
.google-maps-right {
    position: fixed;
    top: 1;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0 !important;
    margin-bottom: 0px;
}
.google-maps-right #map-leaflet {
    height: 867px;
}
section.google-map-right {
    padding: 10px 0 30px 0 !important;
}
.half-map-view .google-maps-right #map-leaflet {
    height: 100vh;
    top: 0;
    bottom: 0;
    padding: 0px;
}
.half-map-view .blog .block-heading {
    padding: 140px 0 30px 0 !important;
}
.half-map-view .headings-2 {
    padding: 130px 0 30px 0;
}
.half-map-view .fx-google-map-right.pl-55 {
    padding-left: 35px;
}
.half-map-view .fx-google-map-right.pl-555 {
    padding-left: 35px;
    padding-right: 35px !important;
}
.half-map-view .mt-5.pagi {
    padding-left: 22px;
}
.half-map-view .portfolio .homes .button,
.half-map-view .portfolio .homes-price {
    z-index: 8;
}
.header.google-map-right {
    z-index: 99999;
}
.header.google-map-right .header-bottom {
    position: fixed;
    width: 100%;
    z-index: 99999;
}
@media screen and (max-width: 991px) {
    .google-maps-right {
        position: relative;
        margin-top: 45px;
    }
    .mt-5.pagi {
        display: none;
    }
    .mt-55.pagi {
        display: none;
    }
    .half-map-view .fx-google-map-right.pl-55 {
        padding-left: 15px;
        padding-right: 30px !important;
    }
    .half-map-view .fx-google-map-right.pl-555 {
        padding-left: 15px !important;
        padding-right: 0px !important;
    }
}
@media screen and (max-width: 767px) {
    .google-maps-right {
        margin-top: 45px;
    }
}
@media screen and (max-width: 575px) {
    .half-map-view .fx-google-map-right.pl-55 {
        padding-right: 15px !important;
    }
}

/*---------------------------------------*/
/*- 46. INNER PAGE PROPERTIES TOP MAP --*/
/*---------------------------------------*/
.header-map.google-maps.properties #map-leaflet,
.header-map.google-maps.properties {
    height: 450px;
}

/*---------------------------------------*/
/*--- 47. INNER PAGE PROPERTY DETAILS ---*/
/*---------------------------------------*/
section.single-proper {
    padding: 0 0 6rem 0;
    background: #f7f7f7;
}
.pro-wrapper {
    display: flex;
}
section.headings-2 {
    padding: 60px 0 55px 0;
    background: #f7f7f7;
}
.bg-white-inner {
    background: #f9f9f9 !important;
}

.inner-pages .headings-2 .listing-title-bar h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    color: #222;
    
}
.inner-pages .headings-2 .listing-title-bar h4 {
    font-size: 24px;
    font-weight: 600;
    color: $firstcolor;
    
}
.inner-pages .headings-2 .listing-title-bar a,
.inner-pages .headings-2 .listing-title-bar a p {
    font-size: 17px;
    text-decoration: none;
    color: #333;
}
.inner-pages .headings-2 span.category-tag {
    font-size: 14px;
    background: $firstcolor;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 15px;
    border-radius: 50px;
    top: -3px;
    margin-left: .5rem;
    position: relative;
}
.schedule.widget-boxed.mt-33 {
    margin-top: 60px;
}
.inner-pages .detail-list-rating {
    float: left;
    margin-right: 15px;
}
.inner-pages .detail-list-rating i {
    color: #ffc107;
}
h4.widget_author__name {
    color: #222;
}
.inner-pages .listing-title-bar a {
    text-decoration: none;
    color: #333;
}
.inner-pages .listing-title-bar a:last-child {
    text-decoration: none;
    color: $text;
}
/*-------------Sliders---------------------------------------*/
.listing-details-sliders .list-inline {
    white-space: nowrap;
    width: 100% !important;
}
.listing-details-sliders .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-left: initial;
    margin-top: 15px;
}
.listing-details-sliders .carousel-indicators a {
    cursor: pointer;
}
.listing-details-sliders .carousel-indicators > li {
    height: initial;
    text-indent: initial;
    max-width: 127px;
    margin-right: 10px;
    margin-left: 0;
}
.listing-details-sliders .carousel-indicators > li:last-child {
    margin-right: 0px;
}
.dd.listing-details-sliders .carousel-indicators > li {
    width: initial;
    height: initial;
    text-indent: initial;
}
.smail-listing .list-inline-item {
    width: 20%;
}
.smail-listing .list-inline-item a img {
    width: 600px;
    border: none;
    border-radius: 0;
}
.elements-page .tab-box p {
    margin-bottom: 0;
}
.listing-details-sliders .carousel-indicators > li.active img {
    opacity: 0.7;
}
.listing-details-sliders .right {
    position: absolute;
    right: 15px;
    top: 45%;
}
.listing-details-sliders .left {
    position: absolute;
    top: 50%;
    left: 35px;
    background: $firstcolor;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    z-index: 120;
    cursor: pointer;
    margin-top: -18px;
    box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
    transition: all .3s ease;
}
.listing-details-sliders .left:hover,
.listing-details-sliders .right:hover {
    background: $secondcolor;
}
.listing-details-sliders .left i {
    font-size: 23px;
    margin-left: 12px;
    margin-top: 5px;
    color: #fff;
}
.listing-details-sliders .right {
    position: absolute;
    top: 50%;
    right: 35px;
    background: $firstcolor;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    z-index: 120;
    cursor: pointer;
    margin-top: -18px;
    box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
    transition: all .3s ease;
}
.listing-details-sliders .img-fluid {
    max-width: 100%;
    height: auto;
}
.single-proper .mb-30,
.property-location.map {
    margin-bottom: 30px !important;
}
.listing-details-sliders .right i {
    font-size: 23px;
    margin-left: 14px;
    margin-top: 5px;
    color: #fff;
}
.list-inline-item:not(:last-child) {
    margin-right: 1px;
}
div.datedropper.my-style {
    border-radius: 8px;
    width: 180px;
}
div.datedropper.my-style .picker {
    border-radius: 8px;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, .1);
}
div.datedropper.my-style .pick-l {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
div.datedropper.my-style .pick-lg-b .pick-sl:before,
div.datedropper.my-style .pick-lg-h,
div.datedropper.my-style .pick-m,
div.datedropper.my-style .pick-submit,
div.datedropper.my-style:before {
    background-color: $firstcolor;
}
div.datedropper.my-style .pick-l,
div.datedropper.my-style .picker,
div.datedropper.my-style.picker-tiny .pick-m,
div.datedropper.my-style.picker-tiny:before {
    background-color: #FFF;
}
div.datedropper.my-style .pick li span,
div.datedropper.my-style .pick-btn,
div.datedropper.my-style .pick-lg-b .pick-wke,
div.datedropper.my-style .pick-y.pick-jump {
    color: $firstcolor;
}
div.datedropper.my-style .pick-arw,
div.datedropper.my-style .pick-l,
div.datedropper.my-style .picker {
    color: #3a465e;
}
div.datedropper.my-style .pick-lg-b .pick-sl,
div.datedropper.my-style .pick-lg-h,
div.datedropper.my-style .pick-m,
div.datedropper.my-style .pick-m .pick-arw,
div.datedropper.my-style .pick-submit {
    color: #FFF;
}
div.datedropper.my-style.picker-tiny .pick-m,
div.datedropper.my-style.picker-tiny .pick-m .pick-arw {
    color: #3a465e;
}
.form-control[readonly] {
    background: #fff;
}
div.datedropper.my-style.picker-lkd .pick-submit {
    background-color: #FFF;
    color: #3a465e;
}
.schedule a.btn {
    padding: 10px 30px;
    
    transition: all ease .4s;
}
.schedule .theme-btn,
.theme-btn:focus,
.theme-btn:hover,
.theme-btn:active {
    color: #fff;
    background: $firstcolor;
}
.schedule .full-width {
    width: 100%;
    margin-top: 15px;
}
.schedule .input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:first-child) > .btn {
    background: #fff;
    transition: all ease .4s;
}
.schedule .input-group-btn:not(:last-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn:hover {
    background: $firstcolor;
    color: #fff;
}
.smail-listing {
    width: 100%;
}
.sidebar-widget .author-box {
    margin-bottom: 15px;
}
.sidebar-widget .author-box img {
    float: left;
    width: 85px;
    height: 85px;
    border: 4px solid #fff;
    margin-right: 15px;
}
.sidebar-widget .author__title {
    font-size: 18px;
    color: #222;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 2px;
}
.sidebar-widget .author-box .author__title {
    margin-top: 18px;
    font-size: 18px;
}
.sidebar .sidebar-widget .author__meta {
    font-size: 15px;
    color: $text;
    font-weight: 400;
}
.sidebar-widget .author__contact li {
    color: $text;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 15px;
}
.sidebar-widget .author__contact li span i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff;
    color: $firstcolor;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 10px;
    transition: all .3s;
}
.sidebar .sidebar-widget .author__contact li a {
    color: $text;
    text-decoration: none;
    transition: all .3s;
}
.sidebar .sidebar-widget .author__contact li a:hover {
    color: $firstcolor;
}
.sidebar-widget ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.sidebar-widget .author__img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.sidebar-widget .author__link {
    text-align: center;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #e4e4e4;
}
.sidebar-widget .author__link li {
    display: inline-block;
}
.sidebar-widget .author__link li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background-color: rgba(128, 137, 150, 0.2);
    color: $firstcolor;
    border-radius: 50%;
    font-size: 16px;
    transition: all .3s;
}
.widget-boxed {
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, .08);
}
.widget-boxed {
    border-radius: 6px;
    padding: 1.5rem;
    transform: translate3d(0, 0, 0);
    z-index: 90;
    margin-bottom: 0px;
    position: relative;
    border: 1px solid #eaeff5;
}
.widget-boxed .input-group .fa {
    font-size: 18px !important;
    color: $firstcolor;
}
.widget-boxed .input-group .fa:hover {
    color: #fff;
}
.widget-boxed-header {
    padding-bottom: 1.5rem;
    padding-top: 0px;
    border-bottom: 1px solid #eaeff5;
}
.widget-boxed-header h4 {
    color: #222 !important;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.widget-boxed-header h4 .fa {
    color: $firstcolor;
    position: relative;
    top: 0px;
}
.row.mrg-top-15 label.mb-3 {
    color: #333;
}
.widget-boxed-body {
    padding: 1.5rem 0 0;
}
.widget-boxed {
    background: #fff;
}
.agent-contact-form-sidebar {
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #eeeeee;
}
.agent-contact-form-sidebar h4 {
    font-size: 16px;
    margin-bottom: 20px;
}
.agent-contact-form-sidebar input {
    width: 100%;
    height: 45px;
    border: 1px solid #dae2e9;
    background-color: #fff;
    padding: 15px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #7e8c99;
    margin-bottom: 1rem;
}
.agent-contact-form-sidebar textarea {
    border: 1px solid #dae2e9 !important;
    background-color: #fff;
    padding-left: 15px;
    width: 100%;
    height: 100px;
    color: #7e8c99;
}
.agent-contact-form-sidebar .multiple-send-message {
    background-color: $firstcolor;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    font-size: 14px;
    
    padding-top: 12px;
    margin-top: 10px;
    margin-bottom: 0px;
    transition: all .3s ease;
}
.agent-contact-form-sidebar .multiple-send-message:hover {
    background-color: $secondcolor;
}
/* Arrows */
.widget-boxed .slick-prev,
.widget-boxed .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    color: #111;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    display: block;
    top: calc(0% - 35px);
    transform: translateY(-50%);
    transition: 0.3s;
    z-index: 0;
    box-shadow: none;
}
.widget-boxed .slick-prev:hover,
.widget-boxed .slick-next:hover {
    color: transparent;
    outline: none;
    background: transparent;
}
.widget-boxed .slick-prev:hover:before,
.widget-boxed .slick-next:hover:before {
    outline: none;
}
.widget-boxed .slick-prev.slick-disabled,
.widget-boxed .slick-next.slick-disabled {
    pointer-events: none;
    color: #111;
    background-color: transparent;
    box-shadow: none;
}
.widget-boxed .slick-prev.slick-disabled:before,
.widget-boxed .slick-next.slick-disabled:before {
    color: #111;
}
.widget-boxed .slick-prev:before,
.widget-boxed .slick-next:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    font-size: 26px;
    line-height: 1;
    transition: 0.3s;
    position: relative;
    color: #111;
}
.widget-boxed .slick-prev {
    left: 0px;
    margin-left: 197px;
}
.widget-boxed .slick-prev:before {
    content: '\f053';
    left: 0px;
    color: #666;
}
.widget-boxed .slick-next {
    right: 0px;
    margin-right: 0px;
}
.widget-boxed .slick-next:before {
    content: '\f054';
    right: 0px;
}
.homes-content .font-weight-bold.mr-1 {
    font-weight: 600;
    color: #555 !important;
}
.homes-content span.det {
    color: $text !important;
}
.widget-boxed.mt-5,
.widget-boxed.my-5,
.main-search-field.mt-5 {
    margin-top: 30px !important;
}
.inner-pages .blog .block-heading.details h4 {
    color: #000;
}
.inner-pages .blog .blog-info.details,
.listing-details-sliders.mb-30,
.homes-content.details.mb-30,
.property-location,
.reviews.comments,
.reviews.leve-comments,
.wprt-image-video.w50.pro,
.ag-de .similar-property,
.ag-de .portfolio.py-0.age,
.det .similar-property {
    padding: 1.5rem !important;
    background: #fff;
    border: 1px solid #eaeff5;
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
}
.wprt-image-video.w50.pro {
    margin-bottom: 30px !important;
}
.inner-pages .blog .blog-info.details h4,
.inner-pages .blog .blog-info.details h5,
.inner-pages .carousel-inner h5,
.wprt-image-video.w50.pro h5 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    
}
.inner-pages .blog .blog-info.details {
    padding: 0;
    border-bottom: 0px;
}
.inner-pages .blog .homes-content .homes-list {
    padding: 0px;
    margin: 0px;
}
.inner-pages .blog .homes-content .homes-list li {
    width: 50%;
    float: left;
    font-size: 14px;
    line-height: 36px;
    list-style: none;
    color: #0098ef;
}
.inner-pages .blog .homes-content.details-2 {
    background: #303441;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}
.inner-pages .blog .homes-content.details-2.mb-4 {
    margin-bottom: 30px !important;
}
@media screen and (max-width: 767px) {
    .inner-pages .blog .homes-content.details-2 {
        padding-left: 4rem;
    }
    .inner-pages .carousel.slide .carousel-inner img {
        width: 100%;
        height: 280px;
    }
    .inner-pages .headings-2 .listing-title-bar h3 {
        font-size: 24px;
    }
}
@media screen and (max-width: 500px) {
    .inner-pages .carousel.slide .carousel-inner img {
        width: 100%;
        height: 280px;
    }
}
@media screen and (max-width: 450px) {
    .inner-pages .blog .homes-content.details-2 {
        padding-left: 2.5rem;
    }
    .inner-pages .blog .block-heading.map {
        margin-top: 30px;
    }
    .pro-wrapper {
        flex-direction: column;
    }
    .single.detail-wrapper {
        margin-top: 30px;
        margin-left: 0px;
    }
    .listing-details-sliders .list-inline {
        width: 88% !important;
        margin-right: 0px;
        margin-top: 0px;
        margin-left: 10px !important;
    }
    .inner-pages .blog .homes-content.details span {
        font-size: 15px !important;
    }
    .inner-pages .single.homes-content .homes-list li {
        width: 100%;
        line-height: 27px;
        margin-bottom: 15px;
    }
    .inner-pages .blog .homes-content .homes-list {
        display: flex;
        flex-direction: column;
    }
    .inner-pages .blog .project-single .homes-content .homes-list {
        flex-direction: row;
    }
    .property .popup-youtube,
    .property.wprt-image-video .iq-waves {
        top: 55.9% !important;
    }
    .property.vid-si2 .popup-youtube,
    .property.wprt-image-video.vid-si2 .iq-waves {
        top: 52.3% !important;
    }
    .property.vid-si3 .popup-youtube,
    .property.wprt-image-video.vid-si3 .iq-waves {
        top: 55.5% !important;
    }
    .widget-boxed .slick-prev {
        margin-left: 180px !important;
    }
    .inner-pages .headings-2 .listing-title-bar h3,
    .inner-pages .headings-2 .listing-title-bar h4 {
        font-size: 22px !important;
    }
    .inner-pages .headings-2 .listing-title-bar a {
        font-size: 15px;
    }
    .inner-pages .homes-content.details-2 ul {
        display: block !important;
    }
    .swiper-button-prev.swiper-button-white,
    .swiper-button-next.swiper-button-white {
        display: none;
    }
}
@media screen and (max-width: 360px) {
    .inner-pages .blog .homes-content.details-2 {
        padding-left: 1.3rem;
    }
}
.inner-pages .blog .homes-content.details-2 span {
    color: #fff;
    font-size: 1rem;
}
.inner-pages .blog .homes-content.details-2 .fa {
    color: #fff;
}
.inner-pages .blog .blog-info p.mb-3 {
    color: $text;
    font-weight: 400;
}
.inner-pages .blog .sorting-options span {
    color: $secondcolor;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.inner-pages .blog .sorting-options h5 {
    color: $firstcolor;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-top: 0px;
}
.inner-pages .blog .sorting-options h6 {
    color: #18ba60;
    font-weight: bold;
    margin-right: 3.2rem;
}
.inner-pages .blog .wprt-image-video.w50 {
    margin-bottom: 5rem;
}
.inner-pages .blog .homes-content h5,
.inner-pages .blog .property-location h5,
.inner-pages .blog .team.assigned h5,
.similar-property h5,
.ag-de .blog-section h5,
.inner-pages .carousel h5 {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    
}
.inner-pages .blog .homes-content h5::after,
.inner-pages .blog .blog-info.details h5::after,
.inner-pages .blog .property-location h5::after,
.inner-pages .blog .team.assigned h5::after,
.inner-pages .carousel h5:after,
.wprt-image-video.w50.pro h5:after,
.similar-property h5:after,
.ag-de .blog-section h5:after {
    color: $firstcolor;
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}
.property .popup-youtube {
    background: #fff none repeat scroll 0 0;
    border-radius: 100%;
    color: $firstcolor;
    font-size: 30px;
    height: 70px;
    left: 50%;
    line-height: 70px;
    position: absolute;
    text-align: center;
    top: 58%;
    transform: translate(-50%, -50%);
    width: 70px;
    z-index: 9;
}
.property .popup-youtube i {
    margin-left: 5px
}
.property.wprt-image-video .iq-waves {
    height: 352px;
    height: 22rem;
    left: 50%;
    position: absolute;
    top: 58%;
    transform: translate(-50%, -50%);
    width: 352px;
    width: 22rem;
    z-index: 2;
}
.property.vid-si2 .popup-youtube,
.property.wprt-image-video.vid-si2 .iq-waves {
    top: 52%;
}
.property.wprt-image-video .iq-waves .waves {
    animation: 3s ease-in-out 0s normal none infinite running waves;
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0 padding-box;
    border-radius: 320px;
    height: 352px;
    height: 22rem;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    width: 352px;
    width: 22rem;
}
.property.wprt-image-video .iq-waves .wave-1 {
    animation-delay: 0s;
}
.property.wprt-image-video .iq-waves .wave-2 {
    animation-delay: 1s;
}
.property.wprt-image-video .iq-waves .wave-3 {
    animation-delay: 2s;
}
.carousel-inner.carus img {
    width: 1000%;
}
@-webkit-keyframes waves {
    0% {
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        transform: scale(0.6, 0.6);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
@keyframes waves {
    0% {
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        transform: scale(0.6, 0.6);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
@media screen and (max-width: 991px) {
    .property.wprt-image-video {
        margin-top: 2.5rem;
    }
    .inner-pages .single.widget {
        padding-top: 0px !important;
    }
    .widget-boxed.popular {
        margin-bottom: 30px;
    }
    .widget-boxed .slick-prev {
        margin-left: 540px;
    }
    .widget-boxed .book {
        margin-bottom: 1.5rem;
    }
    .listing-details-sliders .list-inline {
        width: 94% !important;
        margin-right: 0px;
        margin-left: 20px;
    }
    .single.reviews.leve-comments {
        margin-bottom: 30px !important;
    }
    .inner-pages .headings-2 .listing-title-bar h3 {
        font-size: 24px;
    }
    .schedule.widget-boxed.mt-33 {
        margin-top: 0px;
    }
    .ag-de .single.reviews.leve-comments,
    .ag-de .widget-boxed.popular {
        margin-bottom: 0px !important;
    }
}
.single.reviews.leve-comments {
    margin-top: 0px;
    margin-bottom: 60px;
}
.inner-pages .leave-rating {
    height: 24px;
    float: left;
}
.inner-pages .leave-rating:hover input[type=radio]:checked~label {
    color: #dadada;
}
.inner-pages .leave-rating input[type=radio] {
    display: none;
}
.inner-pages .leave-rating input[type=radio]:checked~label {
    color: #ffc600;
}
.inner-pages .leave-rating label {
    font-size: 24px;
    float: right;
    letter-spacing: 4px;
    color: #dadada;
    cursor: pointer;
    transition: .3s;
}
.inner-pages .leave-rating label:hover,
.inner-pages .leave-rating label:hover~label {
    color: #ffc600!important;
}
.inner-pages .leave-rating-title {
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 7px;
}
.inner-pages .add-review-photos {
    font-weight: 600;
    padding: 6px 7px;
    color: #fff;
    border: 0px;
    background-color: $firstcolor;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: auto;
    transition: .3s;
    cursor: pointer;
    line-height: 20px;
    float: right;
    top: -25px;
}
.inner-pages .photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 12px;
    cursor: pointer;
}
.inner-pages .photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
.inner-pages .add-review-photos:hover {
    color: #fff;
    background-color: $secondcolor;
}
.inner-pages .add-review-photos i {
    color: #66676b;
    margin-right: 3px;
    transition: .3s;
    font-size: 16px;
    position: relative;
    top: 2px;
}
.inner-pages .add-review-photos:hover i {
    color: #fff;
}
.inner-pages a.rate-review {
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    font-size: 13px;
    color: #666;
    font-weight: 500;
    padding: 5px 20px;
    margin-top: 20px;
    display: inline-block;
    transition: .3s;
}
.inner-pages a.rate-review span {
    border-left: 1px solid #e0e0e0;
    line-height: 20px;
    padding-left: 10px;
    margin-left: 5px;
    transition: .3s;
    color: #666;
}
.inner-pages a.rate-review i {
    padding-right: 3px;
}
.inner-pages a.rate-review:hover span {
    border-color: #d0d0d0;
}
.inner-pages a.rate-review:hover {
    border-color: #d0d0d0;
    background-color: #fafafa;
}
.inner-pages .blog .homes-content.details .fa {
    color: $firstcolor;
    font-size: 1.5rem;
    font-weight: bold;
}
.inner-pages .blog .homes-content.details span {
    font-size: 1rem;
    color: #000;
}
#map-contact {
    width: 100%;
    height: 300px;
}
.contact-map {
    width: 100%;
    height: 300px;
}
.inner-pages .info-box-img {
    width: 180px !important;
    margin-right: 20px;
    float: left;
}
.inner-pages section.team.assigned {
    padding: 0px;
}
.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white {
    background-color: #274abb;
    width: 55px;
    height: 55px;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #274abb;
    padding: 8px;
}
.swiper-pagination-bullet {
    padding: 8px;
    background: #fff;
    font-weight: 700;
}
.inner-pages .commented .rest img {
    width: 165px;
    height: 110px;
    margin-top: 12px;
    border-radius: 5px;
    margin-right: 15px;
}
.inner-pages .resti {
    display: flex;
}
.list-inline {
    line-height: 15px;
}
@media screen and (max-width: 575px) {
    .inner-pages .resti {
        flex-direction: column;
    }
}
.single-property-4 {
    position: relative;
}
.p0 {
    padding: 0 !important;
}
.m0 {
    margin: 0 auto;
}
.pr1 {
    padding-right: 1px;
}
.pr1 {
    padding-right: 1px;
}
.w100 {
    width: 100% !important;
}
.single-property-5 .video-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    overflow: hidden
}
.single-property-5.fullwidth-home-map.dark-overlay {
    height: 70vh !important;
    margin-bottom: 0;
}
.single-property-5.fullwidth-home-map.dark-overlay video {
    top: 50%;
    transform: translateY(-52%);
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -10;
    background-size: cover;
    transition: 1s opacity;
    position: absolute;
}
.single-property-5.dark-overlay:before {
    background: none !important;
}
.single-property-5.dark-overlay:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: none;
}
section.breadcrumb-outer {
    padding: 12rem 0;
    background: linear-gradient(rgba(14, 18, 68, 0.3), rgba(14, 18, 68, 0.3)), url(../images/bg/home-171.png) no-repeat scroll center center;
    background-size: cover;
}
.detail-title .detail-title-inner .listing-rating i {
    color: #ffc107;
}
.detail-title .detail-title-inner .list-single-contacts li {
    font-size: 15px;
    padding-right: 17px
}
.detail-title .detail-title-inner .list-single-details .list-single-rating {
    position: relative;
    z-index: 1;
    display: inline-block
}
.detail-title .detail-title-inner .list-single-details .list-single-rating .rating-score span {
    background: #fff;
    color: #274abb;
    float: left;
    font-weight: 700;
    border-radius: 0;
    padding: 16px 18px;
    font-size: 24px
}
.white {
    color: #fff !important;
}
h2.white span {
    color: #fff !important;
    margin-bottom: 20px;
}
h2.white {
    margin-top: 20px;
    margin-bottom: 20px;
}
.list-single-contacts ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.list-single-contacts ul li {
    margin-bottom: 0;
    line-height: 1.5;
    color: #666;
    position: relative;
    display: inline-block;
    padding: 0;
}
.list-single-contacts ul li a,
.breadcrumb-outer a {
    text-decoration: none;
}
.breadcrumb-outer a:hover {
    color: #fff;
}
.nir-btn,
.nir-btn1,
.nir-btn-black {
    position: relative;
    z-index: 1;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    
    transition: all ease-in-out .5s;
    background: #274abb;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    border-radius: 0;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, .1);
}
.breadcrumb-outer .mt-1,
.my-1 {
    margin-top: 1rem !important;
}
.nir-btn1:before {
    border-color: #fff !important;
}
.nir-btn:before,
.nir-btn1:before,
.nir-btn-black:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all ease-in-out .5s;
    transform: scale(0, 1);
    z-index: -1;
    border: 2px solid #ff5a5f;
    color: #ff5a5f;
    background: 0 0;
    border-radius: 0;
}
.property.vid-si6 .popup-youtube,
.property.wprt-image-video.vid-si6 .iq-waves {
    top: 56.7%;
}

/*---------------------------------------*/
/*-- 48. INNER PAGE AGENTS LISTING GRID -*/
/*---------------------------------------*/
.inner-pages section.team {
    padding: 6rem 0;
    background: #f6f6f6;
}
.inner-pages .team .team-all {
    padding: 0;
    margin: 0;
}
.inner-pages .team-wrap {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.inner-pages .team-img {
    position: relative;
    z-index: 9;
}
.inner-pages .team-img img {
    width: 100%;
}
.inner-pages .team-content {
    height: 80px;
    width: 100%;
    bottom: 0;
    position: absolute;
    text-align: center;
    overflow: hidden;
    transition: all 0.6s;
    z-index: 9;
    background: $firstcolor;
}
.inner-pages .team-wrap:hover .team-content {
    height: 150px;
}
.inner-pages .team-content .team-info {
    background: #fff !important;
}
.inner-pages .team-info {
    background: $firstcolor;
    padding: 20px;
    transition: all .3s;
}
.inner-pages .team-wrap:hover .team-content .team-info {
    background: $firstcolor;
}
.inner-pages .team-content h3 {
    
    margin-bottom: 0px;
    color: #444;
    font-size: 16px;
}
.inner-pages .team-content p {
    color: #000;
    font-style: italic;
    margin-bottom: 10px;
    font-size: .97rem;
    font-weight: 400;
    letter-spacing: 1px;
}
.inner-pages .team-content ul {
    opacity: 0;
    display: flex;
    justify-content: center;
    transform: translateY(100%);
    backface-visibility: hidden;
    transition: transform 0.6s, opacity 0.4s;
}
.inner-pages .team-wrap:hover .team-content ul {
    opacity: 1;
    transform: translateY(0px);
    transition: transform 0.6s, opacity 0.4s;
    margin-bottom: 10px;
}
.inner-pages .team-content ul li {
    opacity: 0;
    padding: .3rem 0;
    transform: translateY(40px);
    transition: transform 0.6s, opacity 0.1s;
    display: inline-block;
}
.inner-pages .team-wrap:hover .team-content ul li {
    opacity: 1;
    transform: translateY(0px);
}
.inner-pages .team-wrap:hover .team-content ul li:nth-child(1) {
    transition-delay: 100ms;
}
.inner-pages .team-wrap:hover .team-content ul li:nth-child(2) {
    transition-delay: 200ms;
}
.inner-pages .team-wrap:hover .team-content ul li:nth-child(3) {
    transition-delay: 300ms;
}
.inner-pages .team-wrap:hover .team-content ul li:nth-child(4) {
    transition-delay: 400ms;
}
.inner-pages .team-wrap:hover .team-content ul li:nth-child(5) {
    transition-delay: 500ms;
}
.inner-pages .team-socials ul li a {
    display: flex;
}
.inner-pages .team-content span a {
    color: #000;
    transition: all 1s;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
}
.inner-pages .team-wrap:hover .team-content span a {
    bottom: 2px;
}
.inner-pages .team-content span a:hover {
    color: $firstcolor;
}
.inner-pages .team .team-pro ul {
    padding: 0;
    margin: 0;
}
.inner-pages .team .team-pro ul li {
    list-style: none;
    display: flex;
    justify-content: center;
}
.inner-pages .team .team-pro .fa {
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    margin: 0 .5rem;
    width: 2.1rem;
    height: 2.1rem;
    line-height: 2.1rem;
}
.inner-pages .team .team-all.padding {
    padding-bottom: 2.5rem;
}
.property-nearby b.title {
    font-size: 15.5px;
}
.property-nearby h6 {
    font-size: 13.2px;
    font-weight: 600;
}
.property-nearby span {
    line-height: 16.5px;
    font-size: 13.2px;
    color: $text;
}
@media screen and (max-width: 991px) {
    .inner-pages .team .team-pro {
        padding-bottom: 2.5rem;
    }
    .inner-pages .team .team-pro:last-child {
        padding-bottom: 0px !important;
    }
    .inner-pages .team .pb-none {
        padding-bottom: 0px;
    }
    .inner-pages .team .team-all.padding {
        padding-bottom: 0px;
    }
    .detail-title-inner,
    .list-single-details {
        text-align: center!important;
    }
    .list-single-details .rating-score {
        justify-content: center;
        float: none!important;
        margin-bottom: .5rem;
    }
    .list-single-details {
        margin-top: 3rem;
    }
}
@media screen and (max-width: 767px) {
    .inner-pages .team .team-pro.pb-on {
        padding-bottom: 2.5rem;
    }
    .inner-pages .team-pro.pb-on.np,
    .inner-pages .team-pro.pb-none.np {
        padding-top: 0px;
    }
    .inner-pages .team-pro.pb-on.np-2 {
        padding-bottom: 2.5rem !important;
    }
}
@media screen and (max-width: 575px) {
    .list-single-contacts ul li {
        margin-bottom: .7rem;
        text-align: left;
    }
    h2.white span {
        line-height: 37px;
    }
    section.breadcrumb-outer {
        padding: 7rem 0;
    }
}
@media screen and (max-width: 360px) {
    .white.the-last {
        margin-top: 0px;
    }
    .list-single-contacts ul li {
        margin-bottom: .7rem;
        text-align: left;
    }
}
@media screen and (max-width: 450px) {
    .property.vid-si6 .popup-youtube,
    .property.wprt-image-video.vid-si6 .iq-waves {
        top: 54.3% !important;
    }
}
.inner-pages .team .team-pro .fa.fa-facebook:hover {
    background: #3b5998;
}
.inner-pages .team .team-pro .fa.fa-twitter:hover {
    background: #1da1f2;
}
.inner-pages .team .team-pro .fa.fa-instagram:hover {
    background: #e1306c;
}

/*-----------------------------------------*/
/*--- 49. INNER PAGE AGENTS LISTING ROW ---*/
/*-----------------------------------------*/
.inner-pages .agent-row .agent-img {
    width: 30%;
    display: inline-block;
    overflow: hidden;
    min-height: 285px;
    max-height: 285px;
    margin-bottom: 40px;
    background: #d4e0e7;
    float: left;
    z-index: 100;
}
.agents .portfolio .project-head img {
    background-color: #1d293e;
    opacity: 10;
}
ul.the-agents-details {
    list-style: none;
    padding-left: 0px;
    margin-top: 15px;
}
ul.the-agents-details li {
    margin-bottom: 6px;
}
ul.the-agents-details li a {
    text-decoration: none;
    color: #666;
    font-size: 14px;
}
.view-my-listing a {
    color: $firstcolor !important;
    transition: all .3s ease;
}
.view-my-listing a:hover {
    color: $secondcolor !important;
}
.agents .listing-hidden-content {
    background: $firstcolor;
}
.agents .schedule.widget-boxed.mt-33 {
    margin-top: 80px;
}
.agents #header,
.sin-1 #header {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}
.agents .blog-section .news-link {
    font-size: 14px !important;
    font-weight: 400 !important;
}
.agents .blog-section .news-item-sm:last-child {
    border-radius: 0 0 8px 8px;
}
.agents section.blog-section,
.agents .blog .block-heading,
.agents section.team {
    background: #f7f7f7;
}
@media screen and (min-width: 991px) {
    .agents .no-pb2.icho {
        padding-bottom: 45px;
    }
    .agents .pt-55 {
        padding-top: 45px;
    }
    .agents .pt-33 {
        padding-top: 15px;
    }
}
.agents .blog-section .news-item-sm .news-item-text {
    padding: 20px !important;
}
@media screen and (max-width: 991px) {
    .inner-pages .agent-row .agent-img,
    .inner-pages .agent-row .agent-content {
        width: 100% !important;
    }
    .inner-pages .agent-row .agent-content {
        min-height: 300px !important;
    }
    .agents .pagination.disabled {
        display: none;
    }
    .agents .schedule.widget-boxed.mt-33 {
        margin-top: 60px !important;
    }
    .inner-pages .agent-row .agent-img {
        float: none;
        display: block;
        width: 100%;
        min-height: auto;
        max-height: 100%;
        margin-bottom: 0px !important;
    }
    .inner-pages .agent {
        margin-bottom: 0px !important;
    }
    .inner-pages .team .agent-mb {
        margin-bottom: 2.5rem;
    }
}
@media screen and (max-width: 767px) {
    .inner-pages .agent-row .agent-footer {
        bottom: 22px !important;
    }
}
@media screen and (max-width: 381px) {
    .inner-pages .agent-row .agent-details .fa-envelope {
        padding: 1rem 0;
    }
}
.inner-pages .agent-row .agent-content {
    background: #fff;
    padding: 25px;
    width: 70%;
    float: left;
    min-height: 285px;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.inner-pages .agent-row .agent-details h4 {
    margin-bottom: 1rem;
}
.inner-pages .agent-row .agent-text p {
    margin-top: 18px;
    line-height: 1.6;
    color: $text;
    overflow: hidden;
}
.inner-pages .agent-row .agent-details {
    padding-left: 0px;
    padding-right: 0px;
}
.inner-pages .agent-row .agent-details p {
    display: inline-block;
    margin-right: 15px;
}
.inner-pages .agent-row .agent-footer {
    text-align: left;
    left: 25px;
    right: 25px;
    position: absolute;
    bottom: 25px;
}
.inner-pages .agent-row .agent-footer .social-icons {
    margin-top: 5px;
}
.inner-pages .agent-row-sidebar .agent-img {
    min-height: 254px;
    max-height: 254px;
}
.inner-pages .agent-row-sidebar .agent-text {
    font-size: 13px;
}
.inner-pages .agent-row-sidebar .agent-content {
    min-height: 254px;
}
.inner-pages .agent-row-sidebar .agent-footer {
    bottom: 20px;
}
.inner-pages .agent {
    background: white;
    position: relative;
    margin-bottom: 30px;
}
.inner-pages .agent-tag {
    position: absolute;
    padding: 8px 8px;
    font-size: 15px;
    z-index: 20;
    top: 20px;
    left: 10px;
}
.inner-pages .agent-img {
    position: relative;
    display: block;
}
.inner-pages .agent-img img {
    width: 100%;
    height: auto;
}
.inner-pages .agent-content {
    padding: 5%;
}
.inner-pages .agent-content p {
    color: $text;
    margin: 0;
}
.inner-pages .button.alt {
    background-color: $firstcolor;
    color: #fff;
}
.inner-pages .agent-details {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding: 0px 10px 15px 10px;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.9;
}
.inner-pages .agent-details:last-child {
    border-bottom: 0px;
}
.inner-pages .agent-details a {
    color: #777;
    font-size: 20px;
    margin-bottom: 1rem;
    text-decoration: none;
}
.inner-pages .homes-content .homes-address a {
    font-size: 1.2rem !important;
    margin: 0 0 15px;
    text-decoration: none;
    color: $firstcolor;
}
.inner-pages .homes-address span {
    color: $text;
}
.inner-pages .homes-content .homes-list li i {
    font-size: 1.2rem;
    margin-right: 5px;
    color: $firstcolor;
}
.inner-pages .agent-details a:hover {
    color: #48a0dc;
}
.inner-pages .agent-details .fa {
    color: $firstcolor;
    font-size: 15px;
    margin-right: 8px;
}
.inner-pages .agent-content .social-icons.circle li {
    padding-left: 4px;
    padding-right: 4px;
}
.inner-pages .agent-content .social-icons.circle li a {
    color: #9bb0bb;
    border: 1px solid #9bb0bb;
}
.inner-pages .agent-content .social-icons.circle li a:hover {
    color: white;
    border-color: #48a0dc;
}
.inner-pages .agent-content .netsocials {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
}
.inner-pages .agent-content .netsocials .fa {
    font-size: 1rem;
    text-align: center;
    background: transparent;
    border: 1px solid #fff;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 1.5rem;
    transition: all .5s ease;
}
.inner-pages .agent-content .netsocials a .fa-facebook {
    background: #3b5998;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-facebook:hover {
    background: #3b5998;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-twitter {
    background: #1da1f2;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-twitter:hover {
    background: #1da1f2;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-google-plus {
    background: #dd4b39;
    color: #fff;
}
.agent-content .netsocials a .fa-google-plus:hover {
    background: #dd4b39;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-youtube {
    background: #cd201f;
    color: #fff;
}
.inner-pages .agent-content .netsocials a .fa-youtube:hover {
    background: #cd201f;
    color: #fff;
}
.inner-pages .button.button-icon.right {
    float: right;
}
@media screen and (max-width: 531px) {
    .inner-pages .agent-row .agent-text p {
        margin-bottom: 3rem;
    }
}

/*---------------------------------------*/
/*---- 50. INNER PAGE AGENTS DETAILS ----*/
/*---------------------------------------*/
.inner-pages section.portfolio.agent-details {
    padding: 1rem 0;
    background: #f7f7f7;
}
.inner-pages .agent-details .section-title {
    text-align: left !important;
}
.inner-pages .agent-details .section-title .title-style h2,
.inner-pages .agent-details.no-mb a {
    margin-bottom: 0px;
}
.inner-pages .featured .compare {
    margin-top: 1.1rem;
}
.portfolio .project-head .fa,
.portfolio .project-head .fa-video {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-right: 0px;
    line-height: 0px;
}
.agents .blog .blog-info.details {
    margin-top: 30px;
}
.agents .single.reviews.leve-comments {
    margin-top: 30px;
    margin-bottom: 0px;
}
section.single-proper.details {
    padding: 6rem 0 !important;
}
.agents.det .portfolio {
    margin-bottom: 30px;
}
.agents.det .blog-section .news-item {
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
    border: 1px solid #eaeff5;
}
.agents.det section.headings-2 {
    background: #f7f7f7;
}
.agents.det section.headings-2.hee {
    background: #f7f7f7;
}
.agents.det .text-heading.text-left a {
    font-size: 16px;
    transition: all .3s ease;
}
.agents .pro-wrapper {
    display: flex;
    justify-content: space-between;
}
.agents .fa.fa-th-large,
.agents .active-view-btn .fa.fa-th-list {
    color: #fff;
}
.agents .lde .fa.fa-th-large {
    color: rgb(18, 27, 34);
}
.agents.det .text-heading.text-left a:hover {
    color: 000;
}
.agents.det .text-heading.text-left span {
    font-size: 16px;
    color: $firstcolor;
}
.inner-pages .agent-details .button-effect {
    position: absolute;
    text-align: left;
    bottom: 0;
    right: 0;
    z-index: 33;
    background: transparent;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
    padding: 8px 15px;
    border-radius: 20px 0 0 0;
    transition: all 0.4s ease;
    margin-bottom: .7rem;
}
.inner-pages .agent-details .button-effect .btn {
    display: inline-block;
    color: #fff;
    box-shadow: none;
    padding: 0;
    margin-right: 15px;
    border-radius: 100%;
    width: 31px;
    height: 31px;
    line-height: 31px;
    background: #fff;
    z-index: 99;
}
.inner-pages .button.alt,
.inner-pages .homes-tag.sale.rent {
    line-height: 1.5;
}
.inner-pages .agent-details .footer a {
    font-size: 13px;
    color: #666;
}
.inner-pages .agent-details .footer i {
    color: #666;
}
.inner-pages .agent-details .compare .fa-heart-o {
    color: #666;
}
.agents a.button.border, 
a.button {
    background-color: #274abb;
    color: #fff !important;
}
@media screen and (max-width: 991px) {
    .inner-pages section.portfolio.agent-details {
        padding-top: 2.5rem;
    }
    
}
@media screen and (max-width: 767px) {
    .agents .blog-section .no-pb2.icho {
        padding-bottom: 0px !important;
    }
}

/*------------------------------------*/
/*------ 51. INNER PAGE DASHBOARD ----*/
/*------------------------------------*/
.user-page.section-padding {
    padding: 1rem 0;
    margin-top: 90px;
}
.user-profile-box {
    background: #1d293e;
    box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
    margin: 0 auto 50px;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 295px;
    height: 100vh;
    z-index: 99999;
}
.user-profile-box .header {
    padding: 20px 20px 120px;
    text-align: center;
    position: relative;
    background-repeat: no-repeat;
    border: none;
    margin: 0;
    background: #1d293e;
    background-size: cover;
    color: #ffffff;
    z-index: 1;
}
.user-profile-box .header h2 {
    margin: 0 0 8px;
    color: #aeb7c2;
    
    font-weight: 600;
    font-size: 18px;
}
.user-profile-box .header h4 {
    font-size: 14px;
    color: #aeb7c2;
    margin-top: 15px;
    font-weight: 400;
}
.user-profile-box .profile-img {
    border-radius: 50%;
    background-clip: padding-box;
    border: 5px solid #18ba60;
    bottom: 5px;
    float: left;
    height: 100px;
    width: 100px;
    left: 59.5%;
    margin-left: -75px;
    position: absolute;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.user-profile-box .detail {
    padding-top: 30px;
}
.user-profile-box .detail ul li a {
    color: #aeb7c2;
    padding: 12px 50px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}
.my-address.pro label {
    color: #222;
    font-weight: 500;
}
.my-address.pro .form-group {
    margin-bottom: 1.5rem;
}
.user-profile-box .detail ul {
    list-style: none;
    padding-left: 0px;
}
.user-profile-box .detail ul li a i {
    margin-right: 10px;
}
.user-profile-box .detail ul li a {
    transition: all .3s ease;
}
.user-profile-box .detail ul li .active {
    background: #172133;
    color: #fff;
    font-weight: 500;
}
.user-profile-box .detail ul li a:hover {
    background: #172133;
    color: #fff;
}
.dashborad-box {
    background: #fff;
    padding: 30px;
    margin-bottom: 2.5rem;
    box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
}
.dashborad-box.stat {
    background: #f7f7f7;
    box-shadow: none;;
}
.db-top-header {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
}
.db-top-header #header.cloned.sticky {
    visibility: hidden;
}
.dashboard-bd.maxw1600 {
    max-width: 100%;
}
.dashboard-bd .left-side {
    margin-left: 80px;
}
.dashboard-bd #logo {
    margin-right: 0px;
}
.dashboard-bd .sidebar-header {
    background-color: #24324a;
    padding: 28px 0 28px 0;
}
.dashboard-bd .left-side {
    width: 55%;
}
.dashboard-bd .right-side {
    width: auto;
    margin-right: 55px;
}
.dashboard-bd .my-address  {
    background: #fff;
}
.dashboard-bd a.button.border, a.button {
    background: #1ec38b;
    padding: 9px 20px;
    border-radius: 50px;
    border: 1px solid transparent;
    color: #fff !important;
    transition: 0.3s;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    transition: all .3s ease;
}
.dashboard-bd .active-user h2 {
    
    font-weight: 400;
    padding-top: 15px;
    font-size: 16px;
    margin-bottom: 30px;
    color: #aeb7c2;
    text-align: center;
}
body.dashboard-bd {
    background: #f7f7f7;
}
.dashboard-bd .sidebar-header img {
    width: 340px;
    height: 47px;
}
@media (min-width: 992px) {
    .col-lg-3.user-dash {
        flex: 0 0 17%;
        max-width: 17%;
    }
    .dashboard-bd #navigation {
        margin-left: 175px;
    }
}
@media (min-width: 992px) {
    .col-lg-9.user-dash2 {
        flex: 0 0 82.5%;
        max-width: 82.5%;
        padding-top: 30px;
    }
}
@media (max-width: 991px) {
    .dashboard-bd #logo {
        margin: 0 auto;
    }
    .dashborad-box .col-lg-3.dar.pro, 
    .dashborad-box .col-lg-3.dar.rev, 
    .dashborad-box .col-lg-3.dar.com, 
    .dashborad-box .col-lg-3.dar.booked {
        flex: 0 0 50%;
        max-width: 48.9% !important;        
    }
    .dashborad-box .col-lg-3.dar.pro, 
    .dashborad-box .col-lg-3.dar.rev {
        margin-bottom: 30px;
    }
    .dashborad-box .col-lg-3.dar.rev {
        margin-right: 0px !important;
    }
    .header-user-name {
        display: none;
    }
}
@media (max-width: 575px) {
    .dashboard-bd .left-side {
        margin-left: 0px;
    }    
    .dashborad-box .col-lg-3.dar.pro, 
    .dashborad-box .col-lg-3.dar.rev, 
    .dashborad-box .col-lg-3.dar.com, 
    .dashborad-box .col-lg-3.dar.booked {
        flex: 0 0 1000%;
        max-width: 100% !important;
        margin-right: 0px !important;
    }
    .dashborad-box .col-lg-3.dar.com {
        margin-bottom: 30px;
    }
}
.header-user-menu {
    float: right;
    position: relative;
    top: 10px;
    margin-left: 0px;
    margin-right: 50px;
}
.header-user-name {
    position: relative;
    float: left;
    cursor: pointer;
    color: #666;
    transition: 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 10px;
    font-weight: 600;
}
.header-user-name:before {
    font-family: "FontAwesome";
    content: "\f0d7";
    position: absolute;
    color: #666;
    bottom: 1px;
    font-size: 13px;
    right: -16px;
    transition: all .2s ease-in-out;
}
.header-user-name span {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    overflow: hidden;
    top: 0;
    left: -50px;
}
.header-user-name span img {
    width: 100%;
    height: 100%;
}
.header-user-menu ul.hu-menu-vis {
    animation: 330ms ease 0s normal none 1 running zoomIn;
    opacity: 9;
    visibility: visible;
    top: 145px;
}
.header-user-menu ul {
    margin: 10px 0 0 0;
    opacity: 0;
    list-style: none;
    visibility: hidden;
    position: absolute;
    min-width: 150px;
    top: 60px;
    left: -50px;
    z-index: 1;
    padding: 10px 0;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    transition: all .2s ease-in-out;
}
.header-user-menu ul li {
    float: left;
    width: 100%;
    padding: 4px 0;
}
.header-user-menu ul li a {
    color: #50596E;
    float: left;
    width: 100%;
    text-decoration: none;
    font-weight: 500;
    text-align: left;
    padding: 6px 15px;
}
.dashborad-box .title {
    position: relative;
    padding-bottom: 15px;
    color: #222;
    margin-bottom: 15px;
}
.dashborad-box .item,
.table.table-striped {
    margin-bottom: 0px;
}
.dashborad-box .item .icon {
    float: left;
    font-size: 34px;
    width: 50px;
    position: relative;
}
.dashborad-box .item .icon .fa-list {
    color: #fff;
}
.dashborad-box .item .icon .fa-star {
    color: #fff;
}
.dashborad-box .item .icon .fa-comments {
    color: #fff;
}
.dashborad-box .item .icon .fa-heart {
    color: #fff;
}
.dashborad-box .col-lg-3.dar.pro,
.dashborad-box .col-lg-3.dar.rev,
.dashborad-box .col-lg-3.dar.com,
.dashborad-box .col-lg-3.dar.booked {
    max-width: 24%;
}
.dar.pro {
    border-radius: 8px;
    padding: 30px;
    background: #1ec38b;
}
.dar.rev {
    border-radius: 8px;
    padding: 30px;
    background: #f91;
}
.dar.com {
    border-radius: 8px;
    padding: 30px;
    background: #6ae;
}
.dar.booked {
    border-radius: 8px;
    padding: 30px;
    background: #f91942;
}
.dashborad-box .item .icon:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: #fff;
}
.dashborad-box a {
    color: $firstcolor;
    text-decoration: none;
}
.dashborad-box .item .icon span {
    font-size: 32px;
    color: $firstcolor;
}
.dashborad-box .item .info {
    padding-left: 20px;
    float: left;
}
.dashborad-box .item .info .number {
    font-size: 34px;
    font-weight: 600;
    margin: 0;
    color: #fff;
}
.dashborad-box .item .info .type {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
}
.dashborad-box .item:after {
    display: table;
    content: "";
    clear: both;
}
.dashboard-bd .second-footer {
    background: #fff;
    border: 1px solid #e6e6e6;
    color: #333;
    margin-top: 55px;
    bottom: 0;
    padding-top: 30px;
}
.dashboard-bd .second-footer.ad {
    margin-top: 80px;
}
.dashboard-bd .second-footer.ad2 {
    margin-top: 330px;
}
.dashboard-bd .messages .message .thumb img,
.dashboard-bd .reviews .review .thumb img {
    border-radius: 50px;
}
.dashboard-bd .second-footer p .fa-heart:before {
    color: $firstcolor;
}
.listing-table .table thead tr .rating span {
    background: #f3f3f3;
    padding: 0 3px;
    border-radius: 4px;
}
.listing-table .table thead tr .status .active {
    background: #f3f3f3;
    color: #ffffff;
    padding: 0 3px;
    border-radius: 4px;
}
.listing-table .table thead tr .status .non-active {
    background: #d7d7d7;
    padding: 0 3px;
    border-radius: 4px;
}
.listing-table .table thead tr .edit a span {
    color: $firstcolor !important;
}
.listing-table .table thead tr th {
    margin-bottom: 0;
    border: none;
    color: #333;
    font-weight: 600;
}
.messages .message {
    margin-top: 50px;
}
.messages .message:after {
    display: table;
    content: '';
    clear: both;
}
.messages .message .thumb {
    height: 80px;
    width: 80px;
    float: left;
    border-radius: 50px;
    overflow: hidden;
}
.messages .message .body {
    position: relative;
    float: left;
    width: calc(100% - 120px);
    padding-left: 20px;
}
.messages .message .body h6,
.reviews .review .body h6,
.reviews .review .body h4 {
    font-size: 18px;
    margin-bottom: .2rem;
    color: #111;
}
.reviews .review .body h4 {
    margin-bottom: 8px;
}
.messages .message .body h6 .badge {
    background: $firstcolor;
    color: #ffffff;
}
.messages .message .body .post-time {
    margin-bottom: 0;
    color: #666;
}
.messages .message .body .controller {
    position: absolute;
    top: 0;
    right: 0;
}
.messages .message .body .controller ul li {
    display: inline-block;
    margin-left: 6px;
}
.messages .message .body .controller ul li a {
    color: #888;
}
.messages .message .body .controller ul li a:hover {
    color: $firstcolor;
}
.review {
    margin-top: 30px;
    display: inline-block;
}
.review .thumb {
    height: 80px;
    width: 80px;
    float: left;
    border-radius: 50px;
    overflow: hidden;
}
.review .thumb:after {
    display: table;
    content: '';
    clear: both;
}
.review .body {
    position: relative;
    float: left;
    width: calc(100% - 120px);
    padding-left: 20px;
}
.review .body h6 .stars {
    margin-left: 10px;
    font-size: 1rem;
}
.review .body h6 .stars i {
    color: #fcc200;
}
.inner .starts li {
    margin-bottom: 0px;
}
.review .body .post-time {
    margin-bottom: 0;
    color: #666;
}
.review .body .controller {
    position: absolute;
    top: 0;
    right: 0;
}
.review .body .controller ul li {
    display: inline-block;
    margin-left: 6px;
}
.review .body .controller ul li a {
    color: #888;
}
.review .body .controller ul li a:hover {
    color: $firstcolor;
}
.password-section {
    margin-top: 20px;
}
.password-section h6 {
    margin-bottom: 10px;
    color: #18ba60;
}
.my-properties {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(38, 38, 38, 0.2);
    padding: 30px;
}
.my-properties table {
    width: 100%;
    display: table;
}
.my-properties table thead tr th {
    font-size: 15px;
    color: #222;
    background-color: #f5f6ff;
    border: none;
    font-weight: 500;
    padding: 10px 10px 10px 0;
}
.my-properties table thead tr th:first-child {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    padding-left: 30px;
}
.my-properties table tbody tr td {
    border-top: none;
    border-bottom: 1px solid #f3f3f3;
    padding: 30px 20px 30px 0;
    vertical-align: middle;
    font-size: 14px;
}
.my-properties table h2 {
    border: none;
    font-size: 16px;
    color: #444;
    text-decoration: none;
    
    font-weight: 500;
    margin: 0 0 9px 0;
    padding: 0;
}
.my-properties a {
    text-decoration: none;
}
.my-address label {
    color: #222;
}
.my-address .form-group {
    margin-bottom: 1.5rem;
}
.my-properties table tbody tr .image {
    width: 150px;
}
.my-properties table tbody tr .image a img {
    width: 100%;
    border-radius: 5px;
}
.my-properties table tbody tr td .actions .edit {
    float: left;
}
.my-properties table tbody tr td .inner figure {
    margin-bottom: 16px;
    color: #333;
}
.my-properties table tbody tr td .inner figure i {
    font-size: 13px;
}
.my-properties table tbody tr td .price {
    font-size: 12px;
    font-weight: 500;
}
.my-properties table tbody tr td.actions {
    text-align: right;
}
.my-properties table tbody tr td.actions .edit {
    float: left;
    color: #008000;
}
.my-properties table tbody tr td.actions .edit i {
    transition: 0.4s;
    margin-right: 5px;
}
.my-properties table tbody tr td.actions a {
    padding: 0;
    color: #f50057;
}
.my-properties table tbody tr td:last-child {
    padding-right: 5px;
}
.user-page .heading {
    margin-bottom: 1.5rem;
    
    background: #fff;
    color: #222;
    font-weight: 500;
}
.section-inforamation label {
    color: #222;
    font-weight: 500;
}
.section-inforamation .form-group {
    margin-bottom: 1.5rem;
}
.dashborad-box .starts {
    margin-top: .8rem;
}
.notification-box {
    padding: 30px 35px;
    color: #727272;
    border-radius: 4px;
    background-color: #f3f3f3;
}
.notification-box h3 {
    font-size: 22px;
    margin-bottom: 10px;
}
.notification-box p {
    margin-bottom: 0;
}
.submit-property .drop-file {
    position: relative;
    border: 2px dashed #e5e5e5;
    border-radius: 4px;
    background: #fcfcfc;
    transition: all 0.3s linear;
    display: inline-block;
    width: 100%;
}
.user-page.section-padding select {
    border: 0;
    font-size: 14px;
    width: 100%;
    line-height: 30px;
    padding: 0 15px;
    color: #727272;
    background: #ffffff;
    z-index: 1;
    width: 100%;
    position: relative;
    appearance: none;
    position: relative;
    min-height: 45px;
    box-shadow: none;
    border: 1px solid #f3f3f3;
    background: #fff;
    border-radius: 4px;
    width: 100% !important;
    cursor: pointer;
}
select:focus {
    outline: none;
    box-shadow: none;
}
.form-check-input {
    margin-top: 7px;
}
.drop-file {
    position: relative;
    border: 2px dashed #d0d0d0;
    border-radius: 4px;
    background: #fcfcfc;
    transition: all 0.3s linear;
    display: inline-block;
    width: 100%;
}
.drop-file:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
    background-color: #66676b;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s linear;
    border-radius: 4px;
}
.drop-file .drop-message {
    text-align: center;
    margin: 50px 0;
    font-size: 17px;
    color: #4a4747;
    transition: all 0.3s linear;
    position: relative;
    z-index: 11;
}
.drop-file .drop-message:hover {
    color: #66676b;
}
.drop-file .drop-message span {
    display: inline;
    line-height: 117px;
}
.inner-pages .section-inforamation .form-control,
.inner-pages .my-address .form-control {
    border: 1px solid $text;
}
.inner-pages .section-inforamation .btn-primary,
.inner-pages .my-address .btn-primary {
    color: #fff;
    background-color: $firstcolor;
    border: none;
    cursor: pointer;
    transition: all .5s ease;
}
.inner-pages .section-inforamation .btn-primary:hover,
.inner-pages .my-address .btn-primary:hover {
    background: $secondcolor;
    color: #fff;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.pagination-container .pagination {
    padding-top: 30px;
}
.pagination-container a {
    text-decoration: none;
}
.pagination-container a:hover {
    background: $secondcolor;
    color: #fff;
}
.btn-common {
    background-color: $firstcolor;
    position: relative;
    z-index: 1;
    color: #fff;
}
.btn.btn-common {
    padding: 15px 20px;
}
.pagination-container .pagination .page-item .page-link {
    margin: 0 10px;
    padding: 15px 20px;
    color: $firstcolor;
    border-color: #f3f3f3;
    border-radius: 4px;
    background: #fff;
}
.pagination-container .pagination .page-item .page-link:hover {
    background: $firstcolor;
    color: #fff;
}
@media screen and (max-width: 991px) {
    .my-address.pro {
        margin-top: 2.5rem;
    }
    .user-profile-box {
        display: none;
    }
    .user-profile-box,
    .user-profile-box .detail.pass {
        margin-bottom: 2.5rem !important;
    }
    .dashborad-box .item {
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 575px) {
    .my-properties table tr {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .dashborad-box .item .info .number {
        font-size: 20px;
    }
    .user-profile-box .detail ul li a,
    .reviews .review .body h4 {
        font-size: 15px;
    }
    .my-properties table tbody tr .image {
        width: 100%;
    }
    .my-properties table thead tr th:first-child,
    .my-properties table thead tr th {
        padding-left: 1rem;
        font-size: 15px;
    }
    .my-properties table tbody tr td {
        padding-top: 0px;
    }
    .my-properties table tbody tr td:first-child,
    .inner-pages .pagination {
        margin-top: 30px;
    }
    .my-properties table tbody tr td {
        padding-bottom: 15px;
    }
    .btn.btn-common,
    .pagination-container .pagination .page-item .page-link {
        padding: 8px;
    }
}
@media screen and (max-width: 450px) {
    .messages .message,
    .reviews .review {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .review .body,
    .messages .message .body {
        position: relative;
        float: left;
        width: calc(100% - 20px);
        padding-left: 0px;
    }
    .messages .message .thumb,
    .review .thumb {
        margin-bottom: 1rem;
        margin-right: 8rem;
    }
    .messages .message .body {
        padding-left: 0px;
    }
    .listing-table .table thead tr th,
    .listing-table .table tbody {
        font-size: 14px;
    }
}
@media screen and (max-width: 411px) {
    .messages .message .thumb {
        margin-right: 5rem;
    }
    .review .thumb {
        margin-right: 3rem;
    }
}
@media screen and (max-width: 360px) {
    .messages .message .thumb {
        margin-right: 10rem !important;
    }
    .review .thumb {
        margin-right: 10rem !important;
    }
}
@media screen and (max-width: 360px) {
    .messages .message .body,
    .review .body {
        width: 100%;
    }
    .review .body {
        padding-left: 0px;
    }
    .messages .message .thumb,
    .review .thumb {
        margin-right: 8rem;
    }
}
.user-page .royal-add-property-area {
    background: #f6f6f6 none repeat scroll 0 0;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/*---------------------------------------*/
/*---- 52. INNER PAGE PAYMENT-METHOD ----*/
/*---------------------------------------*/
.inner-pages section.payment-method {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background: #f6f6f6;
}
.tr-single-box {
    background: #ffffff;
    display: block;
    border-radius: 2px;
    border: 1px solid #f0f3f7;
    margin-bottom: 30px;
}
.tr-single-header {
    width: 100%;
    border-bottom: 1px solid #f3f4f7;
    margin-bottom: 2rem;
}
.tr-single-header i {
    color: $firstcolor;
}
.tr-single-header h4 {
    margin: 0;
    font-size: 20px;
    color: #222;
    font-weight: 600;
}
.tr-single-header h4>i {
    margin-right: 7px;
}
.tr-single-body {
    width: 100%;
    padding: 25px;
}
.payment-card {
    border-radius: 4px;
    padding: 18px 15px 15px;
    border: 1px solid #eaeff5;
    margin-bottom: 20px;
}
header.payment-card-header {
    display: inline-block;
    width: 100%;
}
.payment-card-title.flexbox {
    float: left;
}
header.payment-card-header .pull-right img {
    max-width: 100px;
}
.payment-card .collapse {
    padding: 20px 15px 10px;
}
.payment-card-title.flexbox h4 {
    margin: 0;
    font-size: 18px;
}
.include-features {
    margin-top: 15px;
}
.features-tag {
    background-color: #eaedf3;
    border-radius: 0 2px 2px 0;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.2727272727;
    margin: 2px 15px 5px 0;
    padding: 3px 7px;
    position: relative;
}
.features-tag:before {
    border-top: 10px solid transparent;
    border-left: 9px solid #eaedf3;
    border-bottom: 10px solid transparent;
    height: 0;
    position: absolute;
    top: 0;
    right: -8px;
    width: 0;
}
.features-tag:after {
    background-color: #fff;
    border-radius: 50%;
    height: 4px;
    position: absolute;
    top: 8px;
    right: -2px;
    width: 4px;
}
.features-tag:after,
.features-tag:before {
    content: "";
}
.payment-card .custom-checkbox input[type="checkbox"]:checked + label:after {
    top: 5px;
}
.mrg-bot-20 {
    margin-bottom: 20px;
}
.inner-pages .payment-method .form-control {
    border: 1px solid $text;
    margin-bottom: 1.3rem;
}
.booking-price-detail h5 {
    font-size: 16px;
    font-weight: 600;
}
.custom-checkbox {
    position: relative;
}
.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    margin: 5px 0 0 3px;
    z-index: 9;
}
.custom-checkbox label:before {
    width: 18px;
    height: 18px;
}
.custom-checkbox label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    background: #ffffff;
    border: 1px solid $text;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 2;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    transform: inherit;
    z-index: 3;
    transform: rotateZ(45deg);
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
    border-color: $firstcolor;
    background: $firstcolor;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    border-color: #fff;
}
.custom-checkbox input[type="checkbox"]:disabled + label:before {
    color: #b8b8b8;
    cursor: auto;
    box-shadow: none;
    background: #ddd;
}
.side-list ul {
    margin: 0;
    padding: 0;
}
.side-list ul li {
    list-style: none;
    padding: 10px 5px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px dashed #eaeff5;
}
.side-list ul li:last-child,
.side-list.no-border ul li {
    border-bottom: none;
}
@media screen and (max-width: 575px) {
    .inner-pages .payment-method .form-control.address {
        margin-bottom: 1.3rem !important;
    }
    .payment-card-title.flexbox h4 {
        font-size: 16px;
    }
}

/*-----------------------------------*/
/*------ 53. INNER PAGE ABOUT US ----*/
/*-----------------------------------*/
.inner-pages section.about-us {
    background: #fff;
    padding: 6rem 0;
}
.inner-pages .headings {
    background: linear-gradient(rgba(18, 27, 34, 0.6), rgba(18, 27, 34, 0.6)), url(../images/bg/bg-details.jpg) no-repeat center center;
    width: 100%;
    height: 30vh;
}
.inner-pages .headings h1 {
    margin-top: 7.5rem;
    font-size: 26px;
    color: #fff;
}
.inner-pages .headings h2 a,
.inner-pages .headings h2 {
    text-decoration: none;
    
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    transition: all .5s ease;
}
.inner-pages .headings h2 a:hover {
    color: $firstcolor;
}
.inner-pages .about-us h2::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $secondcolor;
    content: " ";
    width: 97px;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 500px) {
    .inner-pages .headings h1 {
        margin-top: 5rem;
        font-size: 1.5rem;
    }
}
.inner-pages .about-us .who-1 {
    background: #fff;
    background-size: cover;
    color: $text;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80px;
    left: 0;
    top: 0;
}
.inner-pages .about-us .who-1 h2 span {
    color: $firstcolor;
}
.inner-pages .about-us .who-1 p {
    color: $text;
    background: #fff;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}
.inner-pages .services .top-headings-section.se h2 {
    color: #fff;
}
.inner-pages .about-us .box.bg-2 a {
    text-decoration: none;
}
.inner-pages .about-us .box.bg-2 {
    color: #000 !important;
    background: #fff;
}
.inner-pages .about-us .box.bg-2 .button:hover {
    color: #fff;
    border-color: #fff;
}
.inner-pages .about-us .wprt-image-video .icon-wrap {
    border-radius: 50%;
    background-color: $firstcolor;
    outline: none;
    width: 60px;
    height: 60px;
    margin: -25px 0 0 -25px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transition: all ease .238s;
}
.inner-pages .about-us .wprt-image-video .icon-wrap:hover {
    background: $secondcolor;
}
.inner-pages .about-us .wprt-image-video .icon-wrap:after {
    color: #fff;
    content: "\f04b";
    font-family: "FontAwesome";
    font-size: 22px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 60px;
    text-align: center;
    transition: all ease .238s;
}
.inner-pages .about-us .wprt-image-video .icon-wrap:hover {
    color: $secondcolor;
    content: "\f04b";
    font-family: "FontAwesome";
}
.inner-pages .services-2 .section-title .title-style h2,
.inner-pages .team .section-title .title-style h2 {
    color: $firstcolor;
}
.inner-pages .services-2 .title-icon:before,
.inner-pages .team .title-icon:before {
    background: $secondcolor;
}
.inner-pages .services-2 .title-icon:after,
.inner-pages .team .title-icon:after {
    background: $secondcolor;
}
.inner-pages .services-2 .img-1 img {
    margin-bottom: 1rem;
}
.inner-pages .services-2 .art-1 {
    color: $secondcolor;
}
main.services-2 {
    background: #f8f8f8;
    padding: 6rem 0;
}
.services-2 .art-1 {
    background: #fff;
    color: $firstcolor;
    text-align: center;
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid #f9f9f9;
    transition: .5s ease;
}
.services-2 .art-1:hover {
    cursor: all-scroll;
}
.services-2 article .fa {
    font-size: 4rem;
    text-align: center;
}
.services-2 article h3 {
    color: #000;
    margin: 1.3rem 0 1rem;
}
.services-2 article p {
    color: #555;
}
.inner-pages .team-content .team-info {
    background: $secondcolor;
}
@media screen and (max-width: 991px) {
    .box.bg-2 img {
        width: 30%;
    }
    .inner-pages .about-us .wprt-image-video {
        margin-top: 2.5rem;
    }
}
.about-us .who img {
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 767px) {
    .about-us .who img {
        width: 100%;
    }
    .inner-pages .team .jann {
        padding-top: 2.5rem;
    }
    .inner-pages .team .kat {
        padding-top: 2.5rem;
    }
    .services-2 .serv {
        margin-bottom: 2rem;
    }
    .services-2 .serv:last-child {
        margin-bottom: 0px;
    }
}

/*-----------------------------------*/
/*---- 54. INNER PAGE FAQ ---*/
/*-----------------------------------*/
.inner-pages section.faq {
    padding: 6rem 0;
}
.inner-pages .panel .panel-heading {
    font-size: 16px;
    font-weight: 400;
}
.inner-pages .panel .panel-heading.no-mb {
    margin-bottom: 0px;
}
.inner-pages .panel .panel-heading a {
    background: $firstcolor;
    color: #fff;
    display: block;
    padding: 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all .5s ease;
}
.inner-pages .panel .panel-heading a:hover {
    background: $secondcolor;
    color: #fff;
}
.inner-pages .opening-hours.faq {
    margin-top: 0px;
}
.inner-pages .opening-list.faq ul {
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .inner-pages .widget-service-details.faq {
        padding-top: 4rem;
    }
    .inner-pages .opening-list ul li:last-child {
        margin-bottom: 0px;
    }
}

/*-----------------------------------*/
/*----- 55. INNER PAGE PRICING ------*/
/*-----------------------------------*/
.inner-pages section.pricing-table {
    padding: 6rem 0;
    background: #f6f6f6;
}
section.pricing-table {
    padding: 6rem 0;
    background: #fff;
}
.plan {
    background: #fff;
    border: 1px solid #dedede;
}
.plan-name {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    display: block;
    font-size: 24px;
    line-height: 47px;
    font-weight: 800;
    padding: 20px 0;
    color: #000;
}
.plan-price {
    font-size: 48px;
    padding: 30px 0;
    margin-bottom: 30px;
    position: relative;
    background: $firstcolor;
    color: #fff;
}
.plan-price:after {
    bottom: -30px;
    border: solid transparent;
    content: " ";
    position: absolute;
    border-width: 15px;
    border-top-color: $firstcolor;
    left: 50%;
    margin-left: -16px;
}
.plan.featured .plan-price {
    background: $secondcolor;
}
.plan.featured .plan-price:after {
    border-top-color: $secondcolor;
}
.plan.featured .plan-name {
    color: #323232;
}
.plan.featured .plan-price {
    color: #fff;
}
.plan.featured .plan-price:after {}
.plan-price .currency {
    top: -0.9em;
    font-size: 50%;
    left: -0.01em;
    font-weight: 700;
}
.plan-name small {
    font-family: 'Roboto', sans-serif;
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}
.plan-price strong {}
.plan-price sub {
    font-size: 18px;
    font-weight: 700;
}
.plan ul {
    background: #fff;
    padding: 0;
    margin-bottom: 0;
}
.plan ul li {
    border-top: 1px dashed #dedede;
    padding: 12px 29px;
    font-weight: 500;
}
.plan ul li:first-child {
    border-top: 0 !important;
}
.plan a.btn {
    color: #fff;
    background: $firstcolor;
    border: 0px;
    margin: 25px 0;
    padding: 8px 30px;
}
.plan a.btn:hover {
    background: $secondcolor;
    color: #fff;
}
@media screen and (max-width: 991px) {
    .plan {
        margin-bottom: 40px;
    }
    .plan.no-mgb {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 767px) {
    .plan.yes-mgb {
        margin-bottom: 40px;
    }
}

/*-----------------------------------*/
/*------ 56. INNER PAGE 404 ------*/
/*-----------------------------------*/
.inner-pages .notfound {
    padding: 6rem 0;
}
.inner-pages .top-headings h2 {
    font-size: 10rem;
    color: $firstcolor;
    margin-bottom: 2rem;
    line-height: 0.73;
}
.inner-pages .top-headings h3 {
    font-size: 3rem;
    color: #000;
    margin-bottom: 2rem;
}
.inner-pages .top-headings p:last-child {
    font-size: 1.2rem;
}
.inner-pages .port-info {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.inner-pages .port-info .btn {
    background: $firstcolor;
    color: #fff;
    border: 1px solid $firstcolor;
    transition: all .5s ease;
}
.inner-pages .port-info .btn:hover {
    background: $secondcolor;
    color: #fff;
    border: 1px solid $secondcolor;
}
@media screen and (max-width: 500px) {
    .inner-pages .top-headings h2 {
        font-size: 7rem;
    }
    .inner-pages .top-headings h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .inner-pages .top-headings p:last-child {
        font-size: 1rem;
    }
}

/*---------------------------------------*/
/*- 57. INNER PAGE LOGIN --*/
/*---------------------------------------*/
#login .login,
#register .login {
    width: 430px;
    padding: 45px 60px 60px;
    overflow-y: auto;
    background-color: #fff;
}
#login {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
a.social_bt {
    border-radius: 3px;
    color: #fff;
    min-width: 200px;
    display: block;
    padding: 12px;
    line-height: 1;
    position: relative;
    transition: all .3s ease-in-out;
}
a.social_bt.facebook {
    background-color: #3B5998;
}
a.social_bt.google {
    background-color: #DC4E41;
}
a.social_bt.linkedin {
    background-color: #0077B5;
}
.add_bottom_15,
a.social_bt {
    margin-bottom: 15px;
}
a.social_bt {
    text-align: center;
    text-decoration: none;
}
a.social_bt.facebook:before {
    content: "\f09a";
}
a.social_bt.google:before {
    content: "\f0d5";
    top: 12px;
}
a.social_bt.linkedin:before {
    content: "\f0e1";
}
a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
    font-family: FontAwesome;
    position: absolute;
    left: 12px;
    top: 10px;
    font-size: 1.3rem;
    color: #fff;
}
#login .divider {
    height: 1px;
    margin: 30px 0 20px;
    text-align: center;
    background-color: #e1e8ed;
}
#login .divider span {
    background-color: #fff;
    display: inline-block;
    position: relative;
    top: -20px;
    padding: 10px;
    font-style: italic;
}
.add_bottom_30 {
    margin-bottom: 30px;
}
#login .float-left {
    float: left!important;
}
#login .container_check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 10px;
}
#login .container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#login .container_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #d2d8dd;
    border-radius: 3px;
    transition: all .2s ease;
}
#login .float-right {
    float: right !important;
    margin-top: 0px !important;
}
.checkboxes label,
a#forgot {
    color: #666;
    text-decoration: none;
}
#login .btn_1.rounded,
a.btn_1.rounded {
    border-radius: 25px !important;
}
#login .btn_1.full-width,
a.btn_1.full-width {
    display: block;
    width: 100%;
    text-decoration: none;
    text-align: center;
    margin-bottom: 5px;
}
#login .btn_1,
a.btn_1 {
    border: none;
    background: $firstcolor;
    cursor: pointer;
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    text-align: center;
    transition: all .3s ease-in-out;
    border-radius: 3px;
}
#login a.btn_1:hover {
    background: $secondcolor;
}
#login .add_top_10 {
    margin-top: 16px;
}
#login .text-center {
    text-align: center !important;
}
#login strong a {
    text-decoration: none;
}
.inner-pages .filter-tags input {
    position: relative;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 0;
    color: #234dd4;
    width: 20px;
    height: 20px;
    color: #fff;
    outline: none;
    margin-right: .5rem !important;
    margin-bottom: 0px;
    background: #fff !important;
    -webkit-appearance: none;
}
.filter-tags input:checked:after,
.custom-form .filter-tags input:checked:after {
    font-family: FontAwesome;
    color: #234dd4;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f00c";
    font-size: 14px;
    position: absolute;
    left: 2px;
    z-index: 20;
}
@media screen and (max-width: 767px) {
    #login .login,
    #register .login {
        width: 100%;
    }
    #login .checkboxes label,
    a#forgot {
        font-size: 13px;
    }
}

/*-----------------------------------*/
/*--- 58. INNER PAGE COMING SOON ----*/
/*-----------------------------------*/
.headings-7 {
    background: linear-gradient(rgba(58, 44, 95, 0.7), rgba(32, 51, 100, 0.7)), url(../images/bg/coming-soon.jpg) no-repeat center top;
    color: #fff;
    background-size: cover;
    background-attachment: fixed !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.headings-7 .text-heading p.sorry {
    text-align: center;
    font-size: 1.3rem;
    margin-top: .8rem;
}
.headings-7 .bloq-email {
    justify-content: center;
    margin-top: 2.5rem;
}
.headings-7 .bloq-email .email input[type="email"] {
    border: 0;
    padding: .8rem;
    flex: 1 1 auto;
}
.headings-7 .bloq-email .email input[type="submit"] {
    border: 0;
    padding: .8rem;
    background: $firstcolor;
    border: 1px solid #fff;
    color: #fff;
    
    cursor: pointer;
    flex: 1 1 auto;
    transition: all .5s ease;
}
.headings-7 .email input[type="submit"]:hover {
    background: $secondcolor;
    border: 1px solid #fff;
}
.font-16 {
    font-size: 16px;
    margin-right: 2rem;
}
.font-36 {
    font-size: 36px;
}

/*------------------------------------------*/
/*--- 59. INNER PAGE UNDER CONSTRUCTION ----*/
/*------------------------------------------*/
.headings-8 {
    background: url(../images/bg/bg-under.jpg) no-repeat center top;
    color: #fff;
    background-size: cover;
    background-attachment: fixed !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.headings-8 .text-heading p.sorry {
    text-align: center;
    font-size: 1.1rem;
    margin-top: .8rem;
}
.headings-8 .bloq-email {
    justify-content: center;
    margin-top: 2.5rem;
}
.headings-8 .bloq-email .email input[type="email"] {
    border: 0;
    padding: .8rem;
    flex: 1 1 auto;
}
.headings-8 .bloq-email .email input[type="submit"] {
    border: 0;
    padding: .8rem;
    background: $firstcolor;
    border: 1px solid #fff;
    color: #fff;
    
    cursor: pointer;
    flex: 1 1 auto;
    transition: all .5s ease;
}
.headings-8 .email input[type="submit"]:hover {
    background: $secondcolor;
    border: 1px solid #fff;
}
.font-16 {
    font-size: 16px;
}
.font-36 {
    font-size: 36px;
}

/*------------------------------------*/
/*----- 60. INNER PAGE BLOG GRID -----*/
/*------------------------------------*/
.inner-pages section.blog-section {
    padding: 6rem 0;
    background: #f6f6f6;
}
.inner-pages .blog-section .news-item {
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.inner-pages .blog-section .news-item.-news-page {
    display: flex;
    flex-direction: column;
}
.inner-pages .blog-section .news-item-descr.big-news {
    height: 120px !important;
}
.inner-pages .blog-section .space,
.inner-pages .blog-section .space2.port {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.inner-pages .blog-section .news-item h3 {
    color: #000;
    transition: all .25s;
}
.inner-pages .blog-section .news-item.-news-slider-item {
    height: 100%;
}
.inner-pages .blog-section .news-item.-news-slider {
    height: auto;
}
.inner-pages .blog-section .news-item a:hover {
    text-decoration: none;
}
.inner-pages .blog-section .news-item a:hover h3 {
    color: $firstcolor;
    transition: all .25s;
}
.inner-pages .blog-section .news-item .news-img-link.-news-page {
    display: block;
    position: relative;
    padding-bottom: 69%;
    width: 100%;
}
.inner-pages .blog-section .news-item .news-img-link.-news-page .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .resp-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.inner-pages .blog-section .news-item-img .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .news-item-img {
    position: relative;
    width: 100%;
}
.inner-pages .blog-section .news-item-img .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .news-item-text {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.inner-pages .blog-section .news-item-text-header {
    display: block;
}
.inner-pages .blog-section .news-item .date {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    color: $firstcolor;
}
.inner-pages .blog-section .news-item-descr {
    margin-bottom: 20px;
    height: 110px !important;
}
.inner-pages .blog-section .news-item-descr p {
    color: $text;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
.inner-pages .blog-section .news-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.inner-pages .blog-section .action-list {
    display: flex;
    font-size: .8rem;
    color: #444;
}
.inner-pages .blog-section .action-list .fa-comment {
    color: $firstcolor;
}
.inner-pages .blog-section .action-list .fa-share-alt {
    color: $firstcolor;
}
.inner-pages .blog-section .action-list .fa-heart {
    color: $firstcolor;
}
.inner-pages .blog-section ul.action-list {
    margin-bottom: 0px;
}
.inner-pages .blog-section .action-item {
    display: inline-block;
    padding-left: 15px;
}
.inner-pages .blog-section .news-slider-wrap .slick-track {
    display: flex;
}
.inner-pages .blog-section .news-item-sm {
    display: flex;
    margin-bottom: 2.5rem;
}
.inner-pages .blog-section .news-item-sm:last-child {
    margin-bottom: 0px;
}
.inner-pages .blog-section .news-item-sm .news-img-link {
    flex-basis: 43.5%;
    position: relative;
}
.agents .blog-section .news-item-sm .news-img-link {
    flex-basis: 48.5%;
    position: relative;
}
.inner-pages .blog-section .news-item-sm .news-img-link .news-item-img {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.inner-pages .blog-section .news-item-sm .news-item-text {
    flex-basis: 66.6%;
    padding: 25px 30px;
}
.inner-pages .blog-section .news-item-sm .news-item-descr {
    height: 52px;
    overflow: hidden;
}
.inner-pages .blog-section .news-link {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: $firstcolor;
    transition: all 0.25s;
}
.inner-pages .blog-section .news-link:hover {
    color: $secondcolor;
    text-decoration: none;
    transition: all 0.25s;
}
@media screen and (max-width: 991px) {
    .inner-pages .blog-section .news-item {
        margin-bottom: 2.5rem;
    }
    .inner-pages .blog-section .news-item.nomb {
        margin-bottom: 0px;
    }
    .inner-pages .blog-section .news-item.no-mb:last-child {
        margin-bottom: 0px;
    }
    .inner-pages .blog-section .space.port,
    .inner-pages .blog-section .space2.port {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .inner-pages .blog-section .no-mb .news-item {
        margin-bottom: 0px;
    }
    .inner-pages .blog-section .space.no-pb2 {
        padding-bottom: 0px;
    }
    .inner-pages .pagination.wpt {
        padding-top: 3rem;
    }
    .inner-pages .blog-section .recent-post.sher {
        margin-top: 3rem;
    }
}
@media screen and (max-width: 767px) {
    .inner-pages .blog-section .no-mb.wpt-2 .news-item {
        margin-bottom: 2.5rem;
    }
    .inner-pages .blog-section .space.no-pb2 {
        padding-bottom: 2.5rem;
    }
    .inner-pages .blog-section .news-item.nomb {
        margin-bottom: 2.5rem;
    }
    .inner-pages .blog-section .recent-post.sher {
        margin-top: 0px;
    }
}
@media screen and (max-width: 577px) {
    .inner-pages .blog-section .news-item-sm {
        display: block !important;
    }
    .inner-pages .blog-section .news-item-sm .news-img-link .news-item-img {
        position: relative;
        padding-bottom: 69%;
    }
}
@media screen and (max-width: 414px) {
    .inner-pages .blog-section .news-item-descr p,
    .inner-pages .blog-section .news-item-descr {
        overflow: visible;
    }
    .inner-pages .blog-section .news-item-descr.visib {
        overflow: hidden;
        margin-bottom: 2rem;
        ;
    }
    .inner-pages .blog-section .news-item.no-mb2 {
        margin-bottom: 0px;
    }
    .inner-pages .blog-section ul.action-list {
        padding-left: 0px;
    }
    .inner-pages .blog-section .news-link {
        font-size: .9rem;
    }
}
.inner-pages .pagination {
    margin-bottom: 0px;
}
.page-item.active .page-link {
    background: $firstcolor;
    border-color: $firstcolor;
}
.page-link {
    color: $secondcolor;
    transition: all .5s ease;
}
.page-link:hover {
    background: $firstcolor;
    color: #fff;
}

/*--------------------------------------*/
/*-- 61. INNER PAGE BLOG GRID SIDEBAR --*/
/*--------------------------------------*/
.inner-pages section.blog-section {
    padding: 6rem 0;
    background: #f6f6f6;
}
.inner-pages .blog-section .news-item {
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.inner-pages .blog-section .news-item.-news-page {
    display: flex;
    flex-direction: column;
}
.inner-pages .blog-section .news-item-descr.big-news {
    height: 120px !important;
}
.inner-pages .blog-section .space,
.inner-pages .blog-section .space2.port {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.inner-pages .blog-section .news-item h3 {
    color: #000;
    transition: all .25s;
}
.inner-pages .blog-section .news-item.-news-slider-item {
    height: 100%;
}
.inner-pages .blog-section .news-item.-news-slider {
    height: auto;
}
.inner-pages .blog-section .news-item a:hover {
    text-decoration: none;
}
.inner-pages .blog-section .news-item a:hover h3 {
    color: $firstcolor;
    transition: all .25s;
}
.inner-pages .blog-section .news-item .news-img-link.-news-page {
    display: block;
    position: relative;
    padding-bottom: 69%;
    width: 100%;
}
.inner-pages .blog-section .news-item .news-img-link.-news-page .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .resp-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.inner-pages .blog-section .news-item-img .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .news-item-img {
    position: relative;
    width: 100%;
}
.inner-pages .blog-section .news-item-img .resp-img {
    background-size: cover;
}
.inner-pages .blog-section .news-item-text {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.inner-pages .blog-section .news-item-text-header {
    display: block;
}
.inner-pages .blog-section .news-item .date {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
}
.inner-pages .blog-section .news-item-descr {
    margin-bottom: 20px;
    height: 110px !important;
}
.inner-pages .blog-section .news-item-descr p {
    color: $text;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
.inner-pages .blog-section .news-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.inner-pages .blog-section .action-list {
    display: flex;
    font-size: .8rem;
    color: #444;
}
.inner-pages .blog-section .action-list .fa-comment {
    color: $firstcolor;
}
.inner-pages .blog-section .action-list .fa-share-alt {
    color: $firstcolor;
}
.inner-pages .blog-section .action-list .fa-heart {
    color: $firstcolor;
}
.inner-pages .blog-section ul.action-list {
    margin-bottom: 0px;
}
.inner-pages .blog-section .action-item {
    display: inline-block;
    padding-left: 15px;
}
.inner-pages .blog-section .news-slider-wrap .slick-track {
    display: flex;
}
@media screen and (max-width: 767px) {
    .inner-pages .space3 {
        padding-bottom: 2.5rem;
    }
    .inner-pages .widget {
        padding-top: 0 !important;
    }
}
.inner-pages .form-control {
    padding: .7rem;
    border: 1px solid $firstcolor;
}
@media screen and (max-width: 767px) {
    .inner-pages .input-group {
        width: 90%;
    }
    .inner-pages .blog-section .no-pb2 {
        padding-bottom: 2.5rem !important;
    }
}
@media screen and (max-width: 991px) {
    .inner-pages .widget {
        padding-top: 2.5rem;
    }
    .inner-pages .blog-section .no-pb2 {
        padding-bottom: 0px;
    }
}
.inner-pages .input-group-btn .fa {
    font-size: 1.8rem;
    cursor: pointer;
}
.inner-pages .input-group-btn .btn-primary {
    background: $firstcolor;
    border: 1px solid $firstcolor;
    cursor: pointer;
    transition: all .5s ease;
}
.inner-pages .input-group-btn .btn-primary:hover {
    background: $secondcolor;
    color: #fff;
}
.inner-pages .recent-main {
    display: flex;
    flex: 0 1 100%;
}
@media screen and (max-width: 992px) {
    .inner-pages .recent-main {
        flex-wrap: wrap;
    }
}
.inner-pages .recent-img img {
    width: 90px;
    height: 70px;
    margin-right: 1rem;
}
.inner-pages .info-img h6 {
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
}
.inner-pages .info-img a {
    text-decoration: none;
    color: #000;
    transition: all .5s ease;
}
.inner-pages .info-img a:hover {
    color: $firstcolor;
}
.inner-pages .recent-post .tags {
    display: flex;
}
.inner-pages .recent-post .tags a {
    background: $firstcolor;
    color: #fff;
    border: 1px solid $firstcolor;
    margin-bottom: .7rem;
    margin-left: .2rem;
    transition: all .5s ease;
}
.inner-pages .recent-post .tags a:hover {
    background: #fff;
    color: $firstcolor;
}
.inner-pages .recent-post .tags.no-mb a {
    margin-bottom: 0px;
}
.inner-pages .recent-post ul {
    margin: 0;
    padding: 0;
}
.inner-pages .recent-post ul li {
    list-style: none;
    padding: .5rem 0;
}
.inner-pages .recent-post ul li.npt {
    padding-top: 0px;
}
.inner-pages .recent-post ul li:last-child {
    padding-top: .5rem;
    padding-bottom: 0px;
}
.inner-pages .recent-post ul li a {
    text-decoration: none;
    color: $text;
    transition: all .5s ease;
}
.inner-pages .recent-post ul li a:hover {
    color: $firstcolor;
}
.inner-pages .recent-post ul li .fa {
    margin-right: 1rem;
}
.inner-pages .recent-post button {
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.inner-pages .widget h5::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.inner-pages .recent-post .netsocials {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.inner-pages .recent-post .netsocials a .fa {
    font-size: 1.3rem;
    text-align: center;
    background: transparent;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 1rem;
    transition: all .5s ease;
}
.inner-pages .recent-post .netsocials a .fa-facebook {
    background: #3b5998;
    color: #fff;
}
.inner-pages .recent-post .netsocials a .fa-twitter {
    background: #1da1f2;
    color: #fff;
}
.inner-pages .recent-post .netsocials a .fa-google-plus {
    background: #dd4b39;
    color: #fff;
}
.inner-pages .recent-post .netsocials a .fa-youtube {
    background: #cd201f;
    color: #fff;
}
.inner-pages .recent-post ul li.npt:last-child {
    padding-top: 0px;
}

/*--------------------------------------*/
/*-- 62. INNER PAGE BLOG LIST SIDEBAR --*/
/*--------------------------------------*/
.inner-pages .space2 {
    padding-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
    .inner-pages .space3 {
        padding-bottom: 2.5rem;
    }
    .inner-pages .widget {
        padding-top: 3.5rem !important;
    }
}
.inner-pages .form-control {
    padding: .7rem;
    border: 1px solid #dddddd;
}
@media screen and (max-width: 767px) {
    .inner-pages .input-group {
        width: 90%;
    }
}
@media screen and (max-width: 991px) {
    .inner-pages .widget {
        padding-top: 3.5rem;
    }
    .inner-pages .blog .recent-main.no-mb p {
        margin-bottom: 0px;
    }
}
.inner-pages .input-group-btn .fa {
    font-size: 1.8rem;
    cursor: pointer;
}
.inner-pages .input-group-btn .btn-primary {
    background: $firstcolor;
    border: 1px solid $firstcolor;
    cursor: pointer;
    transition: all .5s ease;
}
.inner-pages .input-group-btn .btn-primary:hover {
    background: #fff;
    color: $firstcolor;
}
.inner-pages .recent-main {
    display: flex;
    flex: 0 1 100%;
}
@media screen and (max-width: 992px) {
    .inner-pages .recent-main {
        flex-wrap: wrap;
    }
    .inner-pages .info-img {
        margin-top: 1rem;
    }
}
.inner-pages .recent-img img {
    width: 90px;
    height: 70px;
    margin-right: 1rem;
}
.inner-pages .info-img h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #222;
    font-weight: 400;
}
.inner-pages .info-img a {
    text-decoration: none;
    color: #000;
    transition: all .5s ease;
}
.inner-pages .info-img a:hover {
    color: $firstcolor;
}
.inner-pages .recent-post .tags a {
    background: #fff;
    color: #000;
    border: 1px solid #ccc;
    margin-bottom: .7rem;
    margin-left: .2rem;
    transition: all .5s ease;
}
.inner-pages .recent-post .tags.no-mb a {
    margin-bottom: 0px;
}
.inner-pages .recent-post .tags a:hover {
    background: #fff;
    color: $firstcolor;
    border: 1px solid $firstcolor;
}
.inner-pages .recent-post ul {
    margin: 0;
    padding: 0;
}
.inner-pages .recent-post ul li {
    list-style: none;
    padding: .5rem 0;
    border-bottom: 1px solid #ccc;
}
.inner-pages .recent-post ul li:last-child {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.inner-pages .recent-post ul li a {
    text-decoration: none;
    color: $text;
    transition: all .5s ease;
}
.inner-pages .recent-post ul li a:hover {
    color: $firstcolor;
}
.inner-pages .recent-post ul li .fa {
    margin-right: 1rem;
}
.inner-pages .recent-post button {
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.inner-pages .widget h5::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}

/*-----------------------------------*/
/*--- 63. INNER PAGE BLOG DETAILS ---*/
/*-----------------------------------*/
.inner-pages section.blog-section {
    padding: 6rem 0;
    background: #f6f6f6;
}
.inner-pages .blog-section .news-item-descr.big-news.details {
    height: 240px !important;
    box-shadow: 0px !important;
}
.inner-pages .blog-section .news-item.details {
    background: transparent;
    box-shadow: 0 0 0 0 #edeff1;
}
.inner-pages .blog-section .news-item-text.details {
    padding: 20px 0;
}
.inner-pages .comments-info .conra {
    display: flex;
    justify-content: space-between;
}
.inner-pages .comments-info h5 {
    color: $firstcolor;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0px;
}
.inner-pages .comments-info p:last-child {
    font-weight: 400;
}
.inner-pages .social-share {
    display: flex;
    align-items: center;
    list-style: none;
    background: transparent;
    margin: 0;
    padding: 0;
}
.inner-pages .social-share .fa-facebook {
    color: #3b5998;
}
.inner-pages .social-share .fa-twitter {
    color: #1da1f2;
}
.inner-pages .social-share .fa-google-plus {
    color: #dd4b39;
}
.inner-pages .social-share .fa-youtube {
    color: #cd201f;
}
.inner-pages .social-share a {
    color: $firstcolor;
    font-size: 1.5rem;
    margin: 0 .5rem;
}
.col-md-10.comments-info {
    padding-left: 0px;
}
.col-md-10.comments-info p.mb-2 {
    color: #333;
}
.col-md-10.comments-info p:last-child {
    font-weight: 400;
}
.inner-pages .comments-info.no-mb p:last-child {
    margin-bottom: 0px;
}
.inner-pages .comments h3::after,
.inner-pages .leve-comments h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.inner-pages .commented .comm-inf {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
}
.inner-pages .commented img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 2rem;
}
.inner-pages .data form .col-md-12 {
    padding: 0;
    padding-right: 1rem;
}
section.leve-comments {
    margin-top: 4rem;
}
.inner-pages .leve-comments .btn {
    background: $firstcolor;
    cursor: pointer;
    font-size: 16px;
    border: none;
    transition: all .5s ease;
}
.inner-pages .leve-comments .btn:hover {
    background: $secondcolor;
    color: #fff;
}
.inner-pages .recent-post ul li.npt {
    padding-bottom: 0px;
}
.inner-pages .blog-section .news-item-descr.big-news.details {
    height: 240px !important;
    margin-bottom: 2.5rem !important;
    box-shadow: 0px !important;
}
@media screen and (max-width: 767px) {
    .inner-pages .leve-comments.wpb {
        padding-bottom: 2.5rem;
    }
    .col-md-10.comments-info {
        padding-left: 15px;
    }
}
@media screen and (max-width: 575px) {
    section.leve-comments {
        margin-top: 2rem;
    }
}

/*-----------------------------------*/
/*---- 64. INNER PAGE CONTACT US ----*/
/*-----------------------------------*/
.inner-pages .contact-us {
    padding: 6rem 0;
}
.inner-pages .contact-us ul li {
    list-style: none;
    font-weight: 300;
}
.inner-pages .contact-us ul {
    margin: 0;
    padding: 0;
}
.inner-pages .contact-us .btn {
    background: $firstcolor;
    border: 1px solid $firstcolor;
    cursor: pointer;
    transition: all .5s ease;
}
.inner-pages .contact-us .btn:hover {
    background: #fff;
    color: $secondcolor;
    border: 1px solid $firstcolor;
}
.inner-pages .address-info p:last-child {
    margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
    .inner-pages .offices h3:first-child {
        margin-top: 4rem;
    }
}
@media screen and (max-width: 500px) {
    .inner-pages .contact-us h3 {
        font-size: 1.1rem !important;
    }
}
label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #000;
}
form label.error {
    display: block;
    color: #ff0000;
    font-size: 14px !important;
    opacity: 1 !important;
}
.successform,
.errorform {
    display: none;
    height: 70px;
}
.successform span,
.errorform span {
    display: block;
}
.successform span p,
.errorform span p {
    margin-top: 15px;
}
.successform span p {
    color: #01c15a;
    font-size: 2rem;
}
.errorform span p {
    color: #c0392b;
}
.inner-pages .contact-us h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
}
.inner-pages .offices h3 {
    
}
.inner-pages .offices p {
    font-weight: 300;
}
/*---- CONTACT INFO ----*/
.inner-pages .bgc {
    background: linear-gradient(rgba(32, 51, 100, 0.8), rgba(32, 51, 100, 0.8)), url(../images/bg/bg-testimonials.jpg) no-repeat center center;
    padding: 2rem;
    background-size: cover;
}
.inner-pages .request h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: $firstcolor;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
.inner-pages .call-info h3 {
    color: #fff;
    margin-bottom: 2rem;
}
.inner-pages .call-info p {
    color: #fff;
}
.inner-pages .call-info h3::after {
    display: block;
    height: 3px;
    font-weight: bold;
    background-color: #fff;
    content: " ";
    width: 50px;
    margin-top: .5rem;
    margin-bottom: 1.5rem
}
@media screen and (max-width: 991px) {
    .inner-pages .bgc {
        margin-top: 3rem;
    }
}
.inner-pages .call-info .info {
    display: flex;
    margin-bottom: 2rem;
}
.inner-pages .call-info .ti {
    margin-bottom: 0px;
}
.inner-pages .cll:last-child {
    margin-bottom: 0px;
}
.inner-pages .call-info .info p {
    color: #fff;
    margin: 0px;
    margin-left: 1.5rem;
    font-weight: 300;
}
.inner-pages .call-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.inner-pages .call-info ul li .fa {
    font-size: 1.6rem;
    color: #fff;
}

/*------------------------------------*/
/*-- 65. INNER PAGE SUBMIT PROPERTY --*/
/*------------------------------------*/
.inner-pages .royal-add-property-area {
    padding: 0 0 6rem 0;
    background: #f6f6f6 none repeat scroll 0 0;
}
.inner-pages .royal-complete-order-head {
    margin-bottom: 50px;
    text-align: center;
}
.inner-pages .property-form-group {
    padding: 0 50px;
}
.inner-pages .royal-complete-order-head h2 {
    color: #333;
    margin-bottom: 10px;
}
.inner-pages .single-add-property > h3 {
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    padding-bottom: 25px;
    color: $firstcolor;
}
.inner-pages .property-form-group label {
    color: #333;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
}
.inner-pages .property-form-group p input {
    border: 1px solid #ddd;
    height: 50px;
    padding: 10px;
    width: 100%;
}
.inner-pages .property-form-group p.no-mb,
.inner-pages .property-form-group p.no-mb input {
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .inner-pages .property-form-group p.no-mb,
    .inner-pages .property-form-group p.no-mb input {
        margin-bottom: 20px;
    }
    .inner-pages .property-form-group p.no-mb.first input {
        margin-bottom: 20px !important;
    }
    .inner-pages .property-form-group p.no-mb.last:last-child,
    .inner-pages .property-form-group p.no-mb input:last-child {
        margin-bottom: 0px;
    }
}
.inner-pages .property-form-group p textarea {
    border: 1px solid #ddd;
    height: 180px;
    padding: 10px;
    width: 100%;
}
.inner-pages .single-add-property {
    background: #fff none repeat scroll 0 0;
    margin-bottom: 30px;
    padding: 30px;
}
.inner-pages .property-form-group p,
.property-form-group .dropdown.faq-drop {
    margin-bottom: 20px;
}
.property-form-group .dropdown.faq-drop.no-mb {
    margin-bottom: 0px;
}
@media screen and (max-width: 991px) {
    .property-form-group .dropdown.faq-drop.no-mb {
        margin-bottom: 40px;
    }
    .property-form-group .dropdown.faq-drop.no-mb.last {
        margin-bottom: 0px;
    }
}
.inner-pages .property-form-group .dropdown-toggle {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ddd;
    border-radius: 0;
    color: #999;
    padding: 10px;
    text-align: left;
    width: 100%;
    text-transform: capitalize
}
.inner-pages .property-form-group .dropdown-toggle::after {
    display: none;
}
.inner-pages .property-form-group .dropdown-menu.faq-drop-open {
    width: 100%;
    padding: 0
}
.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 400;
    padding: 10px 20px;
    
    transition: all 0.4s ease 0s
}
.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li:last-child {
    border-bottom: 0px solid #ddd
}
.inner-pages .property-form-group .dropdown-menu.faq-drop-open > li:hover {
    background: #f9f9f9
}
.inner-pages .property-form-group .dropdown-toggle i {
    float: right;
    font-size: 20px;
    line-height: 25px;
}
.inner-pages .property-form-group .dz-message {
    background: #fff none repeat scroll 0 0;
    border: 2px dashed #1ABC9C;
    padding: 50px 20px;
    text-align: center;
}
.inner-pages .dz-default i {
    color: #1ABC9C;
    display: block;
    font-size: 60px;
    margin-bottom: 30px;
}
.inner-pages .dz-default.dz-message > span {
    color: #1ABC9C;
    font-size: 20px;
}
.inner-pages .dropdown.faq-drop span {
    color: #999;
    margin-left: 5px;
}
.inner-pages .pro-feature-add label {
    color: $text;
    font-weight: 400;
}
.inner-pages .pro-feature-add input,
.custom-form .filter-tags input {
    position: relative;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 0;
    color: #fa2964;
    width: 20px;
    height: 20px;
    color: #fff;
    outline: none;
    margin-right: .5rem;
    margin-bottom: 0px;
    background: #fff !important;
    -webkit-appearance: none;
}
.inner-pages .pro-feature-add > li {
    display: inline-block;
    margin: 5px 10px;
}
.inner-pages .pro-feature-add li.no-mb input,
.inner-pages .pro-feature-add .no-mb label,
.inner-pages ul.pro-feature-add,
.inner-pages .pro-feature-add > li.no-mb {
    margin-bottom: 0px;
}
@media screen and (max-width: 404px) {
    .inner-pages .pro-feature-add .no-mb label {
        margin-bottom: 20px;
    }
    .inner-pages .pro-feature-add .no-mb.last label:last-child {
        margin-bottom: 0px;
    }
}
.inner-pages .prperty-submit-button > button {
    background: $firstcolor none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    padding: 10px 20px;
    transition: all 0.4s ease 0s;
    text-transform: capitalize
}
.inner-pages .prperty-submit-button > button:hover {
    background: $secondcolor none repeat scroll 0 0;
}
.inner-pages .royal-complete-order-head > p {
    font-size: 17px;
}
@media screen and (max-width: 575px) {
    .inner-pages .property-form-group {
        padding-left: 0px;
        padding-right: 0px;
    }
}

/*-----------------------------------*/
/*------ 66. PRELOADER ------*/
/*-----------------------------------*/
#preloader {
    background: #FFF;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}
#status,
.status-mes {
    background-image: url(../images/preloader.gif);
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    left: 50%;
    margin: -100px 0 0 -100px;
    position: absolute;
    top: 50%;
    width: 200px;
}
.status-mes {
    background: none;
    left: 0;
    margin: 0;
    text-align: center;
    top: 65%;
}

/*-----------------------------------*/
/*----- 67. HEADER GOOGLE MAPS ------*/
/*-----------------------------------*/
.header-map.google-maps {
    background: #e1e5f1;
    padding: 0;
    height: 755px;
    position: static;
}
.header-map.google-maps .container {
    position: static;
}
#map-canvas {
    width: 100%;
    height: 755px;
    position: absolute;
    z-index: 0;
}
.info-box-img {
    width: 180px;
    margin-right: 20px;
    float: left;
}
.info-box p {
    color: black;
}
.header-map.google-maps.horizontal,
.header-map.google-maps.horizontal #map-canvas {
    height: 600px;
    position: static;
}
.map-leaflet-wrapper,
.map-google-wrapper {
    position: absolute;
}
#map-leaflet,
#map-google {
    width: 100%;
    height: 755px;
    position: absolute;
    z-index: 0;
}
.map-pull-top #map-leaflet,
.map-pull-top #map-google {
    margin-top: -180px;
}
#map-leaflet.full,
#map-google.full {
    display: table;
    height: 100%;
    width: 100%;
}
.map-marker {
    height: 50px;
    width: 50px;
}
.leaflet-div-icon,
.map-marker {
    background-color: $firstcolor;
    border: 5px solid $firstcolor;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: all .15s linear;
    z-index: 10;
}
.leaflet-div-icon:before,
.map-marker:before {
    border: 4px solid transparent;
    border-width: 10px 8px;
    border-top-color: $firstcolor;
    bottom: -24px;
    height: 0;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 11;
}
.leaflet-div-icon:after,
.map-marker:after {
    background-color: #fff;
    border-radius: 50%;
    bottom: 0;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 12;
}
.leaflet-div-icon .fa,
.map-marker .fa {
    color: #000;
    font-size: 23px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 13;
}
.leaflet-div-icon:hover,
.map-marker:hover {
    top: -2px;
}
.map-popup-content-wrapper {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    display: none;
    left: -100px;
    top: -210px !important;
    position: absolute;
    z-index: 9999;
}
.map-popup-content-wrapper:after {
    border: 7px solid transparent;
    border-top-color: #fff;
    bottom: -14px;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
}
.map-popup-content-wrapper,
.leaflet-popup-content-wrapper {
    border-radius: 0;
    margin: 0;
    padding: 0;
    width: 240px;
}
.map-popup-content,
.leaflet-popup-content {
    background-color: #fff;
    height: 243px;
    margin: 0 0 8px 0;
    padding: 0;
}
.leaflet-popup-tip {
    width: 17px;
    height: 1px;
    padding: 1px;
    margin: -1px auto 0;
    transform: rotate(45deg);
}
.map-popup-content a:hover,
.leaflet-popup-content a:hover {
    text-decoration: none;
}
.map-popup-content-wrapper .close {
    color: #fff;
    display: block;
    font-size: 11px;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
    text-shadow: none;
    z-index: 99999;
}
.listing-window-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 180px;
    position: relative;
}
.listing-window-image:after {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .15s linear;
    visibility: hidden;
    z-index: 100;
}
.listing-window-image:before {
    -webkit-font-smoothing: antialiased;
    background-color: $firstcolor;
    border-radius: 50%;
    color: #fff;
    content: '\f002';
    display: block;
    font-family: 'FontAwesome';
    height: 32px;
    left: 50%;
    line-height: 30px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity .15s linear;
    visibility: hidden;
    width: 32px;
    z-index: 101;
}
.map-popup-content-wrapper:hover .listing-window-image:before,
.map-popup-content-wrapper:hover .listing-window-image:after,
.leaflet-popup-content-wrapper:hover .listing-window-image:before,
.leaflet-popup-content-wrapper:hover .listing-window-image:after {
    opacity: 1;
    visibility: visible;
}
.listing-window-content h2,
.listing-window-content h3 {
    display: block;
    color: #323232;
}
.listing-window-content h2 {
    display: block;
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1.5;
    margin: 8px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.listing-window-content p {
    display: block;
    font-size: 1.1em;
    font-weight: 500;
    color: #000;
    line-height: 1.5;
    margin: 4px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.listing-window-content h3 {
    background-color: $firstcolor;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    left: 15px;
    margin: 0;
    padding: 4px 8px;
    position: absolute;
    top: 15px;
}
.marker-cluster-small {
    background-color: rgba(39, 74, 187, 0.6);
    color: #fff;
    font-weight: 700;
}
.marker-cluster-small div {
    background-color: rgba(39, 74, 187, 0.6);
    color: #fff;
    font-weight: 700;
}
.marker-cluster-medium {
    background-color: rgba(39, 74, 187, 0.6);
    color: #fff;
    font-weight: 700;
}
.marker-cluster-medium div {
    background-color: rgba(39, 74, 187, 0.6);
    color: #fff;
    font-weight: 700;
}
.marker-cluster-large {
    background-color: rgba(39, 74, 187, 0.6);
    color: #fff;
    font-weight: 700;
}
.marker-cluster-large div {
    background-color: rgba(39, 74, 187, 0.6);
}

/*-----------------------------------*/
/*----- 68. HEADER FILTER ------*/
/*-----------------------------------*/
.filter {
    min-height: 300px;
    background: #cbd8e3;
    padding-bottom: 40px;
}
.filter-header {
    background: #48a0dc;
}
.filter .ui-tabs .ui-tabs-panel {
    padding: 50px 0px 0px 0px;
    background: transparent;
}
.filter .ui-tabs .ui-tabs-nav {
    box-shadow: none;
}
.filter .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    text-align: center;
    font-weight: 700;
    color: white;
    padding: 16px;
    min-width: 100px;
}
.filter .ui-tabs .ui-tabs-nav li.ui-state-active a {
    background: #408dc1;
}
.filter .ui-tabs .ui-tabs-nav li.ui-state-active a:after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -9px;
    border-width: 9px 9px 0;
    border-style: solid;
    border-color: #408dc1 transparent;
    display: block;
    width: 0;
    z-index: 1;
}
.filter .ui-tabs .ui-tabs-nav li {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0px;
    background: none;
}
.filter label {
    display: block;
}
.filter label.label-submit {
    visibility: hidden;
    display: inline-block;
}
.filter-item {
    float: left;
    margin-right: 2.6%;
    width: 23%;
}
.filter-item-7 {
    width: 31.6%;
}
.filter-item-7:nth-child(3) {
    margin-right: 0;
}
.filter-item-7:nth-last-child(4) {
    width: 23%;
}
.filter-item-7:nth-last-child(3) {
    width: 23%;
}
.filter-item-7:nth-last-child(2) {
    width: 23%;
}
.filter-item-7:last-child {
    width: 23%;
    margin-right: 0;
}
.noUi-horizontal {
    height: 8px;
    border: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.09);
    margin-bottom: 53px;
    margin-top: 7px;
}
.filter .noUi-horizontal {
    background: white;
}
.noUi-connect {
    background: #48a0dc;
    box-shadow: none;
}
.noUi-horizontal .noUi-handle {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}
.noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
    content: '';
    display: none;
}
.noUi-horizontal .noUi-tooltip {
    bottom: auto;
    top: 23px;
    font-size: 11px;
    background: rgba(0, 0, 0, 0.05);
    border: none;
    padding: 3px 4px;
}
.filter-item .area-filter {
    width: 48.5%;
    float: left;
}
.filter-item .area-filter.filter-1 {
    margin-right: 3% !important;
}
.area-filter:nth-last-child(2) {
    margin-right: 0;
}
.filter-item .button {
    width: 100% !important;
    cursor: pointer;
    color: #fff;
}
.filter-with-slider {
    margin-top: -51px;
    color: white;
    min-height: auto;
    position: relative;
    z-index: 100;
    background: transparent;
}
.filter-with-slider .tabs.ui-widget {
    background: #408dc1;
}
.filter-with-slider .ui-tabs .ui-tabs-panel {
    padding: 20px 20px 0px 20px;
}
.filter-with-slider .filter-item:nth-last-child(2) {
    margin-right: 0;
}
.filter-with-slider label {
    color: white;
}
.filter-with-slider .noUi-horizontal {
    background: rgba(0, 0, 0, 0.13);
}
.filter-with-slider .noUi-connect {
    background: #323746;
}
.filter-with-slider .noUi-horizontal .noUi-tooltip {
    color: white;
}
.filter-with-slider .button {
    background: #323746;
}
/** FILTER GOOGLE MAPS **/
.google-maps .filter {
    padding: 20px;
    margin-top: 40px;
    width: 30%;
    position: relative;
    z-index: 100;
    border-radius: 5px;
    background: $firstcolor;
    float: right;
    color: #fff;
}
.google-maps .fa-search {
    margin-right: .5rem;
}
@media screen and(max-width: 767px) {
    .google-maps .filter .filter-toggle {
        display: block;
    }
    .google-maps .filter h6 {
        margin-bottom: 0px;
    }
    .google-maps .filter {
        min-height: auto;
        width: 50%;
        text-align: center;
        padding: 0;
        margin-top: 16px;
        position: relative;
        z-index: 20;
    }
    .google-maps .filter form {
        text-align: left;
        display: none;
        padding: 15px;
        position: absolute;
        width: 100%;
        background: $firstcolor;
    }
    .google-maps .filter .filter-item {
        width: 100% !important;
    }
    .google-maps .slider_amount {
        font-size: 14px !important;
    }
}
@media screen and(max-width: 575px) {
    .google-maps .filter {
        width: 100%;
    }
}
@media screen and(max-width: 991px) {
    .google-maps .slider_amount {
        font-size: 12px;
    }
    .info-box {
        display: flex;
        flex-direction: column;
        width: 200px;
    }
    .info-box-img {
        width: 100%;
        margin-bottom: 1rem;
    }
    .info-box h4 {
        font-size: 1.2rem;
        text-align: center;
    }
    .info-box p {
        font-size: 13px;
        text-align: center;
    }
    .small {
        font-size: 15px !important;
        text-align: center;
    }
}
.google-maps .filter .filter-toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 20px;
    color: white;
    font-weight: 700;
}
.google-maps .filter-item {
    width: 100%;
    float: none;
}
.google-maps .filter-item:nth-last-child(2) input {
    margin-bottom: 0px;
}
.google-maps .filter-item label {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    color: white;
}
.google-maps .filter-half {
    width: 48.5%;
    margin-right: 3%;
    float: left;
}
.google-maps .filter-half-last {
    margin-right: 0;
}
.google-maps .noUi-connect {
    background: #323746;
}
.google-maps .noUi-horizontal .noUi-tooltip {
    color: white;
}
.google-maps .filter .button {
    background: $secondcolor;
    margin-bottom: 5px;
    transition: all .5s ease;
}
.google-maps .filter .button:hover {
    background: #fff;
    color: #000;
}
/** FORM ELEMENTS **/
.google-maps input,
.google-maps textarea,
.google-maps select {
    width: 100%;
    padding: 9px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: none;
    font-weight: 400;
    color: #000;
    font-family: 'Lato', sans-serif;
    background: white;
}
input[type="file"] {
    background: white;
    border: none;
    padding: 5px 0px 5px 0px;
    width: auto;
    display: inline-block;
}
.chosen-container {
    margin-bottom: 20px;
    width: 100% !important;
}
.chosen-container.chosen-with-drop .chosen-single {
    border: none;
    background: white;
}
.chosen-container .chosen-single {
    border: none;
    box-shadow: none;
    border-radius: 3px;
    background: white;
    height: 39px;
    line-height: 39px;
    font-size: 15px;
}
.chosen-container .chosen-single div {
    top: 8px;
    right: 6px;
}
.chosen-container .chosen-drop {
    border: none;
}
.chosen-container .chosen-search {
    padding: 10px;
}
.chosen-container .chosen-results {
    padding: 0px 10px 10px 10px;
}
.chosen-container .chosen-results li.highlighted {
    background: #48a0dc;
    border-radius: 2px;
}
input[type="checkbox"],
input[type="radio"] {
    width: auto;
    margin-right: 5px;
}
textarea {
    height: 130px;
}
input.border,
select.border,
textarea.border,
.form-block.border .chosen-container .chosen-single,
.form-block.border .chosen-container .chosen-drop {
    border: 1px solid rgba(0, 0, 0, 0.15);
}
.form-block.border .chosen-container .chosen-results {
    padding-top: 10px;
}
.form-block label {
    display: block;
}
.form-block label .error {
    color: red;
    font-size: 12px;
    margin-left: 10px;
}
.search-form {
    position: relative;
}
.search-form button[type="submit"] {
    border: none;
    background: none;
    position: absolute;
    right: 13px;
    top: 10px;
    color: #97aab7;
}
.button.small {
    padding: 6px 23px 6px 23px;
    font-size: 12px;
    color: #fff;
    background: $firstcolor;
    display: inline-block;
    width: auto;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: all .5s ease;
}
.button.small:hover {
    background: $secondcolor;
}

/*-----------------------------------*/
/*------ 00. STYLE SWITCHER ------*/
/*-----------------------------------*/
.color-switcher {
    width: 266px;
    position: fixed;
    right: -266px;
    top: 300px;
    z-index: 999;
    padding: 0px;
    transition: all 500ms ease;
    border-bottom-right-radius: 4px;
}
.color-switcher h5 {
    font-size: 15px;
    margin-top: 0;
    padding: 0 20px 0;
}
.color-switcher p {
    font-size: 16px;
    color: #000;
    line-height: 50px;
    margin: 0px;
}
.color-switcher ul {
    list-style: none;
    padding: 0;
}
.color-switcher ul li {
    float: left;
}
.color-switcher ul li a {
    display: block;
    width: 40px;
    height: 40px;
    outline: none;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #333;
}
.color-switcher ul li a.blue {
    background: #0098ef;
}
.color-switcher ul li a.pink {
    background: #fa2964;
}
.color-switcher ul li a.orange {
    background: #f7a13e;
}
.color-switcher ul li a.purple {
    background: #3a2c5f;
}
.color-switcher ul li a.green {
    background: #18ba60;
}
.color-switcher ul li a.red {
    background: #e54242;
}
.color-switcher ul li a.cyan {
    background: #1ABC9C;
}
.color-switcher ul li a.sky-blue {
    background: #00cdcd;
}
.color-switcher .gray {
    background: #656565;
}
.color-switcher ul li a.brown {
    background: #987654;
}
.deflt {
    width: 100%;
}
#default {
    width: 100%;
}
.color-switcher .black {
    background: #000;
    display: none;
}
.color-switcher .white {
    background: #fff;
    display: none;
}
.color-switcher .light {
    background: #fff;
    width: 60px;
    color: #000;
    text-align: center;
    float: left;
    border: 1px solid #666;
    margin-top: 15px;
    margin-right: 22px;
}
.color-switcher .dark {
    background: #272727;
    width: 60px;
    float: right;
    color: #fff;
    border: 1px solid #666;
    margin-top: 15px;
}
.picker_close {
    width: 60px;
    height: 60px;
    position: absolute;
    left: -60px;
    top: 0px;
    z-index: -1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.picker_close i {
    color: $text;
    font-size: 30px;
    line-height: 60px;
}
.position {
    right: 0;
    transition: all 500ms ease;
}
.theme-colours,
.layouts,
.backgrounds,
.choose-header,
.choose-footer {
    display: table;
    text-align: left;
    padding-top: 0px;
    width: 100%;
    padding: 18px;
    background: #fff;
    padding-top: 0px;
}
.layouts a {
    display: inline-block;
    text-align: center;
    width: 100%;
    float: left;
    font-size: 10px;
    color: #000;
    margin-bottom: 20px;
    border: 1px solid #e7e7e7;
    padding: 10px 15px;
}
.layouts a:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}
.styleswitcher {
    position: fixed;
    width: 120px;
    background: #ffffff;
    color: #595959;
    top: 150px;
    right: -123px;
    z-index: 999;
}
.styleswitcher p {
    color: #000 !important;
}
.styleswitcher.ackapa {
    left: 0;
}
.styleswitcher .switch {
    padding-left: 15px;
    padding-bottom: 10px;
}
.styleswitcher .switch h5 {
    margin-top: 20px;
}
.styleswitcher .switch p {
    margin-top: 10px;
}
.stylebutton {
    cursor: pointer;
    position: absolute;
    text-align: center;
    line-height: 40px;
    background: #fff;
    font-size: 20px;
    width: 40px;
    height: 40px;
    top: 0px;
    right: -40px;
}
.styleswitcher a {
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 7px;
    margin-bottom: 10px;
    position: relative;
    z-index: 99999999;
}
.color-switcher ul li {
    margin: 3px;
}
.color-switcher.position .theme-colours {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/*-----------------------------------*/
/*------ 69. UI ELEMENT ------*/
/*-----------------------------------*/
/** Accordions **/
section.ui-element {
    padding: 6rem 0;
}
.ui-elements h5.uppercase {
    letter-spacing: 2px;
    margin-right: -2px;
}
.ui-elements .mb40 {
    margin-bottom: 40px;
}
.ui-elements .mb70 {
    margin-bottom: 70px;
}
.ui-elements .uppercase {
    font-weight: 400;
    text-transform: uppercase;
}
.ui-elements .accordion .title {
    cursor: pointer;
    user-select: none;
}
.ui-elements .accordion .content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
}
.ui-elements .accordion li.active .content {
    max-height: 400px;
}
.ui-elements .accordion p:last-child {
    margin-bottom: 0;
}
.ui-elements .accordion-1 li {
    margin-bottom: 10px;
}
.ui-elements .accordion-1 li:last-child {
    margin-bottom: 0;
}
.ui-elements .accordion-1 .title {
    background: #f5f5f5;
    padding: 13px;
    transition: all 0.3s ease;
}
.ui-elements .bg-secondary .accordion-1 .title {
    background: #ebebeb;
}
.ui-elements .bg-secondary .accordion-1 .title:hover {
    background: #e4e4e4;
}
.ui-elements .accordion-1 .title span {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
}
.ui-elements .accordion-1 .active .title {
    background: $firstcolor;
    color: #fff;
}
.ui-elements .accordion-1 .content {
    border: 1px solid #eee;
    border-top: none;
    transition: all 0.3s ease;
    opacity: 0;
}
.ui-elements .accordion-1 .content p {
    padding: 13px;
}
.ui-elements .accordion-1 .active .content {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
}
.ui-elements .accordion-1 .title:hover {
    background: #eee;
}
.ui-elements .accordion-1 .active .title:hover {
    background: $firstcolor;
}
.ui-elements ul {
    margin-bottom: 24px;
    margin-top: 0;
    padding: 0;
    list-style: none;
}
/** Accordions **/

/** Tabs **/
.ui-elements .tabs {
    overflow: hidden;
}
.ui-elements .tabs li {
    display: inline-block;
    cursor: pointer;
}
.ui-elements .button-tabs .tabs {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    background: #FF385C;
    border-radius: 9px;
}
.ui-elements .button-tabs .tabs > li {
    margin-bottom: 0px;
    float: left;
}
.ui-elements .button-tabs .tabs > li:last-child {
    margin-right: 0;
}
.ui-elements .button-tabs .tab-title {
    padding: 13px;
    display: inline-block;
    text-align: center;
    border-radius: 9px;
    width: auto;
    background: #FF385C;
    user-select: none;
    transition: all 0.3s ease;
}
.ui-elements .bg-secondary .button-tabs .tab-title {
    background: #eee;
}
.ui-elements .button-tabs .tab-title span {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 400;
    
    letter-spacing: 2px;
}
.ui-elements .button-tabs .active .tab-title {
    background: #ff797d;
    color: #fff;
    border-radius: 9px;
}
.ui-elements .button-tabs .active .tab-title:hover {
    background: #ff797d;
}
.ui-elements .button-tabs.vertical {
    overflow: hidden;
}
.ui-elements .button-tabs.vertical > .tabs {
    max-width: 30%;
    float: left;
}
.ui-elements .button-tabs.vertical > .content {
    padding-left: 3%;
    max-width: 70%;
    float: right;
}
@media all and (max-width: 767px) {
    .ui-elements .button-tabs .tabs li {
      width: 49%;
      margin-bottom: 15px;
  }
}
.ui-elements .tabbed-content .content > li {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    display: none;
}
.ui-elements .tabbed-content .content .active {
    opacity: 1;
    visibility: visible;
    display: block;
}
.ui-elements .text-tabs .tabs {
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
    overflow: visible;
}
.ui-elements .text-tabs.no-border .tabs {
    border-bottom: none;
}
.ui-elements .text-tabs .tab-title {
    user-select: none;
    transition: all 0.3s ease;
    padding: 0 24px;
    opacity: 0.5;
    position: relative;
}
.ui-elements .text-tabs .tab-title span {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
}
.ui-elements .text-tabs .tab-title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #47b475;
    bottom: 10px;
    opacity: 0;
    transition: all 0.3s ease;
    bottom: -17px;
    left: 0;
}
.ui-elements .text-tabs .active .tab-title {
    opacity: 1;
    color: #47b475;
}
.ui-elements .bg-primary .text-tabs .tab-title:after {
    background: #b3e1c6;
}
.ui-elements .text-tabs .active .tab-title:after {
    opacity: 1;
}
.ui-elements .text-tabs .tab-title:hover {
   opacity: 1;
}
@media all and (max-width: 767px) {
    .ui-elements .text-tabs .tabs {
      padding-bottom: 0;
  }
    .ui-elements .text-tabs .tabs li {
      width: 49%;
      margin-bottom: 15px;
  }
    .ui-elements .text-tabs .tab-title:after {
      display: none;
  }
    .ui-elements .text-tabs:not(.text-center) .tabs li:first-child .tab-title {
      padding-left: 24px;
  }
}
/** Tabs **/

/** Table **/
.ui-elements table.basic-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;
    margin-bottom: 15px
}
.ui-elements table.basic-table th {
    background-color: #66676b;
    text-align: left;
    color: #fff;
    vertical-align: top;
    font-weight: 500
}
.ui-elements table.basic-table th:first-child {
    border-radius: 4px 0 0 4px
}
.ui-elements table.basic-table th:last-child {
    border-radius: 0 4px 4px 0
}
.ui-elements table.basic-table th,
.ui-elements table.basic-table td {
    padding: 15px 28px
}
.ui-elements table.basic-table tr:nth-child(odd) {
    background-color: #f6f6f6
}
.ui-elements table.basic-table {
    margin-bottom: 0
}
@media screen and (max-width:600px) {
    .ui-elements table.basic-table {
        border: 0
    }
    .ui-elements table.basic-table th {
        display: none
    }
    .ui-elements table.basic-table tr {
        display: block;
        margin-bottom: 0
    }
    .ui-elements table.basic-table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 14px;
        text-align: right
    }
    .ui-elements table.basic-table td:before {
        content: attr(data-label);
        float: left;
        font-weight: 600
    }
    .ui-elements table.basic-table td:last-child {
        border-bottom: 0
    }
}
/** Table **/

/** Notifications **/
.ui-elements #result .success,
.ui-elements .notification {
    line-height: 24px;
    margin-bottom: 15px;
    position: relative;
    padding: 20px 26px;
    padding-right: 50px;
    border-radius: 3px;
}
.ui-elements .notification p {
    color: #fff;
    margin: 0;
    font-size: 15px;
}
.ui-elements #result .success,
.ui-elements .notification.success {
    background-color: #31c77f;
}
.ui-elements #result .success,
.ui-elements .notification.success,
.ui-elements .notification.success a,
.ui-elements .notification.success strong {
    color: #31c77f;
}
.ui-elements .notification.error {
    background-color: #e72660;
}
.ui-elements .notification.error,
.ui-elements .notification.error a,
.ui-elements .notification.error strong {
    color: #e72660;
}
.ui-elements .notification.warning {
    background-color: #f19711;
}
.ui-elements .notification.warning,
.ui-elements .notification.warning a,
.ui-elements .notification.warning strong {
    color: #f19711;
}
.ui-elements .notification.notice h4 {
    font-size: 19px;
    margin: 3px 0 15px;
}
.ui-elements .notification.notice h4,
.ui-elements .notification.notice,
.ui-elements .notification.notice a,
.ui-elements .notification.notice strong {
    color: #17a2b8;
}
.ui-elements .notification.notice {
    background-color: #17a2b8;
}
.ui-elements .notification.success .close,
.ui-elements .notification.error .close,
.ui-elements .notification.warning .close,
.ui-elements .notification.notice .close {
    padding: 0 9px;
    position: absolute;
    color: #fff;
    right: 0;
    top: 22px;
    display: block;
    height: 8px;
    width: 8px;
    cursor: pointer;
}
.ui-elements .notification.notice p span i {
    font-weight: 500;
}
.ui-elements .notification a.button {
    float: right;
    color: #fff;
    margin-top: 3px;
}
.ui-elements .notification.notice a.button {
    background-color: #388fc5;
}
.ui-elements .notification.warning a.button {
    background-color: #dfbe51;
}
.ui-elements .notification.error a.button {
    background-color: #e72660;
}
.ui-elements .notification.success a.button {
    background-color: #79ba38;
}
.ui-elements .notification.closeable a.close:before {
    content: "\f00d";
    font-family: fontawesome;
    position: absolute;
    color: #fff;
    right: 25px;
    top: 0;
    cursor: pointer;
}
.ui-elements .close {
    opacity: .7;
}
/** Notifications **/

/** Buttons **/
.ui-elements .group-20 > * {
	margin-top: 0;
	margin-bottom: 1.25rem;
	margin-left: 1.25rem;
}
.ui-elements .ui-buttons .btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    font-weight: 700;
    box-shadow: 10px 15px 18px rgba(23, 23, 36, 0.15);
    transition: all 0.2s ease-in-out;
    padding: 0.6875rem 1.75rem;
    font-size: 1.0625rem;
    line-height: 1.47;
    border-radius: 0.375rem;
}
.ui-elements .ui-buttons .btn-primary {
    color: #fff;
    border-color: #31c77f;
    background-color: #31c77f;
}
.ui-elements .ui-buttons .btn-primary:hover {
	color: #fff;
	border-color: #2ca46d;
	background-color: #2ca46d;
}
.ui-elements .ui-buttons .btn-secondary {
    color: #fff;
    border-color: #6b39bd;
    background-color: #6b39bd;
}
.ui-elements .ui-buttons .btn-secondary:hover {
	color: #fff;
	border-color: #5a329e;
	background-color: #5a329e;
}
.ui-elements .ui-buttons .btn-warning {
    color: #fff;
    border-color: #f19711;
    background-color: #f19711;
}
.ui-elements .ui-buttons .btn-warning:hover {
	color: #fff;
	border-color: #c57d15;
	background-color: #c57d15;
}
.ui-elements .ui-buttons .btn-danger {
    color: #fff;
    border-color: #e72660;
    background-color: #e72660;
}
.ui-elements .ui-buttons .btn-danger:hover {
	color: #fff;
	border-color: #bd2354;
	background-color: #bd2354;
}
.ui-elements .ui-buttons .btn-dark {
    color: #fff;
    border-color: #171724;
    background-color: #171724;
}
.ui-elements .ui-buttons .btn-dark:hover {
	color: #fff;
	border-color: #44444e;
	background-color: #44444e;
}
.ui-elements .ui-buttons .btn-primary.btn-outline {
    color: #31c77f;
    border-color: #31c77f;
    background-color: transparent;
}
.ui-elements .ui-buttons .btn-primary.btn-outline:hover {
	color: #fff;
	background-color: #31c77f;
}
.ui-elements .ui-buttons .link.link-secondary {
    color: #6b39bd;
}
.ui-elements .ui-buttons .link {
    position: relative;
    display: inline-block;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
    background: none;
    transition: .25s;
    color: inherit;
}
.ui-elements .ui-buttons .link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    transform: translateX(-50%);
    opacity: 0;
    border-bottom: 2px solid;
    transition: width .25s;
}
.ui-elements .ui-buttons .link:hover {
	color: #5a329e;
}
.ui-elements .ui-buttons .link:hover::before {
	width: 100%;
	opacity: 1;
}
.ui-elements .ui-buttons h4.effect {
    font-family: Lato, sans-serif;
    font-size: 18px;
    color: #444;
    font-weight: 400;
}
.ui-elements .ui-buttons .btn-anis {
	transform: translateZ(0);
	overflow: hidden;
	transition: .25s;
}
.ui-elements .ui-buttons .btn-anis::before, .btn-anis::after {
	content: '';
	position: absolute;
	top: 50%;
	z-index: -1;
	padding: 10px;
	border-radius: 50%;
	opacity: 1;
	transition: 0s;
	will-change: transform;
}
.ui-elements .ui-buttons .btn-anis::before {
	left: -30%;
	transform: translate(-50%, -50%);
}
.ui-elements .ui-buttons .btn-anis::after {
	right: -30%;
	transform: translate(50%, -50%);
}
.ui-elements .ui-buttons .btn-anis:hover {
	transition: all .25s, border-color .3s .5s, background-color .3s .5s;
}
.ui-elements .ui-buttons .btn-anis:hover::before, .btn-anis:hover::after {
	padding: 50%;
	opacity: 0;
	transition: left .4s, right .4s, padding .3s .5s, opacity .05s .75s;
}
.ui-elements .ui-buttons .btn-anis:hover::before {
	left: 50%;
}
.ui-elements .ui-buttons .btn-anis:hover::after {
	right: 50%;
}
.ui-elements .ui-buttons .btn-anis.btn-outline:hover {
	transition: all .25s, background-color .1s .7s, color .3s .5s;
}
.ui-elements .ui-buttons .btn-primary.btn-anis::before, 
.ui-elements .ui-buttons .btn-primary.btn-anis::after {
	background-color: #2ca46d;
}
.ui-elements .ui-buttons .btn-primary.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-primary.btn-anis.btn-outline::after {
	background-color: #31c77f;
}
.ui-elements .ui-buttons .btn-secondary.btn-anis::before, 
.ui-elements .ui-buttons .btn-secondary.btn-anis::after {
	background-color: #5a329e;
}
.ui-elements .ui-buttons .btn-secondary.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-secondary.btn-anis.btn-outline::after {
	background-color: #6b39bd;
}
.ui-elements .ui-buttons .btn-success.btn-anis::before, 
.ui-elements .ui-buttons .btn-success.btn-anis::after {
	background-color: #649922;
}
.ui-elements .ui-buttons .btn-success.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-success.btn-anis.btn-outline::after {
	background-color: #77ba21;
}
.ui-elements .ui-buttons .btn-info.btn-anis::before, 
.ui-elements .ui-buttons .btn-info.btn-anis::after {
	background-color: #1475be;
}
.ui-elements .ui-buttons .btn-info.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-info.btn-anis.btn-outline::after {
	background-color: #138ce4;
}
.ui-elements .ui-buttons .btn-warning.btn-anis::before, 
.ui-elements .ui-buttons .btn-warning.btn-anis::after {
	background-color: #c57d15;
}
.ui-elements .ui-buttons .btn-warning.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-warning.btn-anis.btn-outline::after {
	background-color: #f19711;
}
.ui-elements .ui-buttons .btn-danger.btn-anis::before, 
.ui-elements .ui-buttons .btn-danger.btn-anis::after {
	background-color: #bd2354;
}
.ui-elements .ui-buttons .btn-danger.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-danger.btn-anis.btn-outline::after {
	background-color: #e72660;
}
.ui-elements .ui-buttons .btn-light.btn-anis::before, 
.ui-elements .ui-buttons .btn-light.btn-anis::after {
	background-color: #cacacd;
}
.ui-elements .ui-buttons .btn-light.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-light.btn-anis.btn-outline::after {
	background-color: #f7f7f7;
}
.ui-elements .ui-buttons .btn-dark.btn-anis::before, 
.ui-elements .ui-buttons .btn-dark.btn-anis::after {
	background-color: #44444e;
}
.ui-elements .ui-buttons .btn-dark.btn-anis.btn-outline::before, 
.ui-elements .ui-buttons .btn-dark.btn-anis.btn-outline::after {
	background-color: #171724;
}
.ui-elements .ui-buttons .btn-secondary.btn-outline {
    color: #6b39bd;
    border-color: #6b39bd;
    background-color: transparent;
}
.ui-elements .ui-buttons .btn-secondary.btn-outline:hover {
	color: #fff;
	background-color: #6b39bd;
}
.ui-elements .ui-buttons .btn-warning.btn-outline {
    color: #f19711;
    border-color: #f19711;
    background-color: transparent;
}
.ui-elements .ui-buttons .btn-warning.btn-outline:hover {
	color: #fff;
	background-color: #f19711;
}
.ui-elements .ui-buttons .btn-danger.btn-outline {
    color: #e72660;
    border-color: #e72660;
    background-color: transparent;
}
.ui-elements .ui-buttons .btn-danger.btn-outline:hover {
	color: #fff;
	background-color: #e72660;
}
.ui-elements .ui-buttons .btn-dark.btn-outline {
    color: #171724;
    border-color: #171724;
    background-color: transparent;
}
.ui-elements .ui-buttons .btn-dark.btn-outline:hover {
	color: #fff;
	background-color: #171724;
}
.ui-elements .ui-buttons .btn .fas {
    font-size: 1em;
    margin: 0 .47058em;
}
.ui-elements .ui-buttons .btn-lg, 
.ui-elements .ui-buttons .btn-group-lg > .btn {
    padding: 1rem 2.5rem;
    font-size: 1.0625rem;
    line-height: 1.47;
    border-radius: 0.375rem;
}
.ui-elements .ui-buttons .btn-sm, 
.ui-elements .ui-buttons .btn-group-sm > .btn {
    padding: 0.375rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.47;
    border-radius: 0.375rem;
}
.ui-elements .ui-buttons .link .fas {
    color: #31c77f;
}
.ui-elements section.headings-2 {
  padding:  6rem 0 6.5rem 0;
}
/** Buttons **/

/** Form Elements **/
.ui-elements input[type="text"], 
.ui-elements input[type="password"] {
    background: #f5f5f5;
    border: none;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    border-radius: 0;
}
.ui-elements textarea {
    width: 100%;
    border: none;
    background: #f5f5f5;
    margin-bottom: 24px;
    border-radius: 0;
    padding: 16px 20px;
}
.ui-elements select {
    height: 50px;
    background: #f5f5f5;
    width: 100%;
    border-radius: 0;
    border: none;
    outline: none;
    padding-left: 20px;
    position: relative;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: #777;
    transition: all 0.3s ease;
    cursor: pointer;
}
.ui-elements .select-option {
    position: relative;
    cursor: pointer;
    height: 50px;
    overflow: hidden;
    margin-bottom: 24px;
}
.ui-elements .mb24 {
    margin-bottom: 24px;
}
.ui-elements .checkbox-option, 
.ui-elements .radio-option {
    display: inline-block;
    width: 50px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #47b475;
    cursor: pointer;
    user-select: none;
    margin-bottom: 24px;
}
.ui-elements .pull-right {
    float: right !important;
}
.ui-elements .checkbox-option .inner, 
.ui-elements .radio-option .inner {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    border: 1px solid #47b475;
    position: relative;
    top: 2px;
    left: 2px;
    display: inline-block;
    transition: all 0.2s ease;
}
.ui-elements .checkbox-option input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
}
.ui-elements  h4 {
    letter-spacing: 2px;
    margin-right: -2px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    color: #444;
    font-weight: 400;
    margin-bottom: 24px;
}
.ui-elements .radio-option {
    width: 25px;
    height: 25px;
    text-align: left;
}
.ui-elements .radio-option.checked .inner {
    background: #47b475;
}
.ui-elements .radio-option input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
}
.ui-elements .the-check-list {
    display: flex;
}
.ui-elements .radio-option + span {
    font-size: 13px;
    top: 0;
}
.ui-elements .radio-option .inner {
    border: none;
    width: 19px;
    height: 19px;
    left: 2px;
    transform: scale(0);
}
.ui-elements .checkbox-option.checked .inner {
    transform: translate3d(25px, 0, 0);
    background: #47b475;
}
.ui-elements .radio-option.checked .inner {
    transform: scale(1);
}
.ui-elements input[type="submit"],
.ui-elements button[type="submit"] {
    height: 50px;
    line-height: 48px;
    border: 2px solid #47b475;
    background: #47b475;
    color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 0 !important;
}
/** Form Elements **/

/** Progress Bars **/
.ui-elements .skills {
  padding-top: 17px;
}
.ui-elements .skills h4 {
  font-weight: 600;
}
.ui-elements .skills h4::after {
  display: block;
  height: 3px;
  font-weight: bold;
  background-color: #e72660;
  content: " ";
  width: 50px;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}
.ui-elements .barfiller {
  width: 95%;
  height: 12px;
  background: #0f1341;
  border: none;
  position: relative;
  margin-bottom: 2rem;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ui-elements .barfiller:last-child {
  margin-bottom: 0px;
}
.ui-elements .barfiller .fill {
  display: block;
  position: relative;
  width: 0px;
  height: 100%;
  background: #e72660 !important;
  z-index: 1;
}
.ui-elements .barfiller .tipWrap {
  display: none;
}
.ui-elements .barfiller .tip {
  margin-top: -30px;
  padding: 2px 4px;
  font-size: 11px;
  color: #fff;
  left: 0px;
  position: absolute;
  z-index: 2;
  background: #0f1341;
}
.ui-elements .barfiller .tip:after {
  border: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent;
  border-width: 6px 6px 0 6px;
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  top: 100%;
  z-index: 9;
}
@media screen and (max-width: 991px) {
  .ui-elements .sks {
    padding-top: 5rem;
  }

  .ui-elements .skills .bar2 {
    margin-top: 1.5rem;
  }
}
.ui-elements .skills .skil1 ul li {
  list-style: none;
  text-align: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
.ui-elements .skills .skil1 .fa {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #666;
  text-align: center;
}
.ui-elements .skills .skil1 li:last-child {
  text-align: center;
  font-size: 1.1rem;
}
.ui-elements .skills h3 {
  font-size: 1rem;
}
@media screen and (max-width: 400px) {
  .ui-elements .skills h3 {
    font-size: .9rem;
  }
}
.ui-elements .skills .sk1 {
  padding-bottom: 6.5rem;
}
.ui-elements .skil1 ul,
.ui-elements .skil1 h3 {
  margin-bottom: 0px;
}
/** Progress Bars **/

/** CountDowns **/
.ui-elements .countdown-boxed .countdown-block {
    padding: 0 10px;
}
.ui-elements .countdown-boxed .countdown-wrap {
    border-radius: 0.375rem;
    box-shadow: 5px 8px 28px rgba(23, 23, 36, 0.1);
}
.ui-elements .countdown-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ui-elements .countdown-boxed {
    display: flex;
    justify-content: left;
    margin: 0 -15px;
    line-height: 1.2;
    text-align: center;
}
@media (min-width: 768px) {
.ui-elements .countdown-boxed .countdown-counter {
    min-width: 100px;
    font-size: 32.4px;
    }
}
.ui-elements .countdown-boxed .countdown-counter {
    min-width: 80px;
    padding: 15px 10px;
    font-size: 32.4px;
    line-height: 1;
    color: #fff;
    background-color: #6b39bd;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
}
.ui-elements .countdown-boxed .countdown-title {
    padding: 5px;
    font-size: 1.0625rem;
    font-weight: 400;
    color: #171724;
    text-transform: uppercase;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.ui-elements .slider_amount {
    color: #000;
    font-weight: 400;
}
.ui-elements .ui-widget.ui-widget-content {
    background: #e72660;
    width: 440px;
}
.ui-elements .hvr-bounce-to-right {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-top: 2rem;
    font-size: 14px;
    cursor: pointer;
    background: #e72660;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    padding: 12px 22px;
    border-radius: 0;
    transition: all .5s ease;
}
.ui-elements .hvr-bounce-to-right:hover {
    background: #0f1341;
}
.ui-elements .main-search-field-2 input[type="text"] {
    background: #fff;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
}
@media screen and(max-width: 991px) {
    .ui-elements .ui-widget.ui-widget-content {
        width: 340px;
    }
}
@media screen and(max-width: 767px) {
    .ui-elements .theskills {
        margin: 70px 0;
    }
    .ui-elements .ui-widget.ui-widget-content {
        width: 440px;
    }
}
@media screen and(max-width: 575px) {
    .ui-elements .theskills {
        margin: 70px 0;
    }
    .ui-elements .ui-widget.ui-widget-content {
        width: 340px;
    }
}
/** CountDowns **/

/*-----------------------------------*/
/*------ 70. PAGE SHOP ------*/
/*-----------------------------------*/
.shop section.blog-section {
    background: #f7f7f7
}
.shop .blog .blog-info {
    padding: 20px 25px;
    background: #fff;
    height: 217px;
}
.shop .blog .blog-pots h3 {
    font-weight: bold;
    font-size: 1.3rem;
    margin: 0;
}
.shop .product-old-price {
    color: #858585;
    text-decoration: line-through;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 22px;
}
.shop .starts {
    margin: 0px;
}
.shop .starts li {
    margin-bottom: 0px;
}
.shop p.recent-price {
    color: #fe4f4f;
    font-weight: bold;
    margin-bottom: 30px;
}
.shop .btn1 {
    background: #fe4f4f;
    border: 1px solid #fe4f4f;
    border-radius: 8px;
    text-shadow: none;
    text-decoration: none;
    padding: 15px;
    border-right: 8px;
    box-shadow: none;
    color: #fff;
    border: 1px solid #e6e6e6;
    transition: all .3s ease;
}
.shop .btn1:hover {
    background: #fff;
    color: #fe4f4f;
    border: 1px solid #fe4f4f;
}
.shop .fa-shopping-cart {
    margin-right: .5rem;
}
.shop .blog-section .news-item {
    background: none;
    box-shadow: none;
}
.single-p-info.shop h2 {
    font-weight: 600;
    
}
.checkout section.payment-method {
    padding: 6rem 0 !important;
}
@media screen and(max-width: 767px) {
    .sin-1 .thumax-2 {
        margin-bottom: 30px;
    }
    .single-p-info h2 {
        margin-top: 20px;
    }
}

/*-----------------------------------*/
/*------ 71. MEDIAS QUERIES ------*/
/*-----------------------------------*/
@media screen and(min-width: 992px) {
    .search-button {
        margin-left: 4rem;
        margin-right: 0;
        order: 2;
    }
    #bloq-search {
        position: absolute;
        width: 20rem;
        right: 17.5rem;
        top: 2.5rem;
        z-index: 99999;
        order: 3;
        transition: all .5s ease;
    }
}
@media screen and(max-width: 768px) {
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 1.6rem;
    }
    .main-search-field h3 {
        font-size: 1.5rem;
    }
    .portfolio .port-item img {
        width: 100%;
    }
    .team .team-pro img {
        width: 100%;
    }
    .team .team-pro {
        padding-right: 1.1rem;
        padding-left: 1.1rem;
    }
    .blog .blog-pots img {
        width: 100%;
    }
    .second-footer .container {
        flex-direction: column;
        align-items: center;
    }
    .second-footer .container h3 {
        margin-bottom: .8rem;
    }
    .box.bg-3.hed-3 {
        padding-top: 0px;
    }
    .inner-pages .commented img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
    }
    .inner-pages .offices h2 {
        margin-top: 4rem;
    }
    .inner-pages .box.bg-3.mt-5 {
        padding-top: 0px;
    }
    .parallax-search .welcome-text h1 {
        font-size: 30px;
    }
    .agents-grid {
        margin-right: 0px;
    }
}
@media screen and (max-width: 992px) {
    h3 {
        font-size: 1.2rem;
    }
    .headings .text-heading p {
        font-size: 1rem;
    }
    .header-top .container {
        flex-direction: column;
        align-items: center;
    }
    .top-info {
        margin-bottom: 1rem;
    }
    .who-we-are .who img {
        height: 415px;
    }
    .blog .blog-pots h3 {
        font-size: 1.2rem;
    }
    .portfolio img {
        width: 100%;
    }
    .team .team-info .artl {
        padding-bottom: 1.3rem;
    }
    .testimonials .test-1 p::before {
        left: 110px;
    }
}
@media screen and (max-width: 576px) {
    body {
        font-size: 15px;
    }
    h1 {
        font-size: 1.6rem;
    }
    .section-title h3 {
        font-size: 16px;
    }
    .section-title h2 {
        font-size: 30px;
    }
    .who-we-are .who img {
        height: 300px;
    }
    .counterup .countr p {
        font-size: 26px;
    }
    .testimonials .test-1 p {
        padding: 0px 1rem;
    }
    .team .team-pro {
        padding-right: 0;
        padding-left: 0;
    }
    .banner-inner h1 {
        font-size: 28px;
        line-height: 37px;
    }
    .banner-inner .sub-title {
        font-size: 17px;
    }
    .inner-pages .services {
        margin: 0px;
    }
    .inner-pages article.faq h2 {
        font-size: 1.4rem;
    }
    .inner-pages .panel .panel-heading a {
        font-size: 1rem;
    }
    .first-footer h2 {
        margin-top: 2rem;
    }
    .inner-pages .blog .sorting-options h5 {
        font-size: 1.2rem;
        margin-top: 0px;
    }
    .inner-pages .blog .block-heading .sorting-options {
        margin-top: 0px;
    }
    .inner-pages .blog .sorting-options h6 {
        font-size: 0.95rem;
    }
    .inner-pages .blog .homes-content.details-2 span {
        font-size: .9rem;
    }
    .inner-pages .headings h2 a,
    .inner-pages .headings h2 {
        font-size: 1rem;
    }
    .inner-pages .contact-us .offices {
        margin-top: 2rem;
    }
    .headings-7 .text-heading p.sorry {
        font-size: 1.1rem;
    }
    .font-36 {
        font-size: 26px;
    }
}
