.main-search-field {
    [type="submit"] {
        padding: 5px 20px !important;
    }
}

.amenities-filter {
    .form-group {
        margin-bottom: 0.1rem;
    }
}