.webform-no-label {
    .form-group:not(.show-label) {
        label {
            display: none;
        }
    }
}

.inner-pages .property-form-group p input[type="checkbox"] {
    height: 13px;
    width: auto;
}

.btn-primary-full {
    background: #274abb  none repeat scroll 0 0;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    transition: all 0.4s ease 0s;
    width: 100%;
    border-radius: 0;

    &:hover, &:focus {
        background: #121B22 none repeat scroll 0 0;
        color: #fff;
    }
}

form {
    .control-label {
        &.required::after {
        content:'';
        vertical-align:super;
        display:inline-block;
        background-image: url('../images/required.svg');
        background-repeat:no-repeat;
        background-size:6px 6px;
        width:6px;
        height:6px;
        margin:0 0.3em;
        }
    }
}

.royal-add-property-area {
    .nav-item a {
        font-weight: bold;
        font-size: 20px;
    }
}

input[type="checkbox"] ~ label {
    display: inline;
}

#property-form {
    .features {
        display: flex;
        flex-wrap: wrap;

        .choice-wrapper {
            flex: 0 1 25%;
            flex-grow: 1;
            width: 25%;
            margin-bottom: 10px;
            max-width: 25%;

            label, input {
                display: inline;
            }
        }

        @media screen and (max-width: 768px) {
            .choice-wrapper {
                flex: 0 1 33%;
                width: 33%;
            }
        }

        @media screen and (max-width: 468px) {
            .choice-wrapper {
                flex: 0 1 100%;
                width: 100%;
            }
        }
    }
}