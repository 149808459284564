// Project list page
.portfolio {
    .project-single, .project-head {
        border-radius: 0 !important;
    }

    .homes-content {
        padding: 20px 0 0 0 !important;
        background: #fafafa !important;
    }

    .project-single {
        background: #fafafa !important;
        box-shadow: none !important;
    }

    .project-head img {
        padding: 10px !important;
        background-color: #f0f1f1 !important;
        max-height: 260px;

        @media screen and (min-width: 768px) {
            height: 260px;
        }
    }

    .project-head {
        background: #fafafa !important;
    }

    .price-properties {
        display: block !important;
    }

    .homes .button {
        background-color: #08519c !important;
        left: 0;
        display: inline-block;
        text-transform: uppercase;
        margin-top: 0;
        border-radius: 0;
        padding: 5px;
        font-weight: normal;
        width: auto !important;
    }
}


.project-single {
    .footer {
        text-align: center;
        
        h3.title, h3.title a {
            color: #000;
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 3px;
        }
    }

    &.is-featured .footer {
        background-color: #FFD33F !important;
    }

	.featured-sign {
		position: absolute;
		right: 0;
		top: 0;
		padding: 5px;
		font-size: .8rem;
		color: #fff;
		text-transform: uppercase;
		font-size: 0.75rem;
		display: inline-block;
		z-index: 99;

		&.featured-sign-new {
			background-color: #631D76;
		}

		&.featured-sign-oportunity {
			background-color: #019CA1;
		}

		&.featured-sign-featured {
			background-color: #4CB944;
		}

		&.featured-sign-investor {
			background-color: #ff6500;
		}
	}
}

.new-property-page {
    .choice-wrapper {
        display: inline-block;
        padding: 5px 10px;

        label {
            font-weight: lighter;
        }
    }

    select {
        height: 50px;
        border-radius: 0;
    }
}

.single-proper {
    #panorama {
        height: 380px;
    }
}

.royal-add-property-area {
    .dz-preview {
        display: none;
    }
    
    .dropzone.dz-clickable.dz-started {
        display: flex;
        flex-wrap: wrap;
    }

    .dz-default.dz-message {
        flex: 0 0 100%;
    }

    .dz-preview.dz-image-preview.dz-processing.dz-complete {
        margin: 0 5px;
    }
}


// Gallery https://codepen.io/sendstufftodanny/pen/PZXRdP
$light-grey: #e6e6e6;

.gallery-slider {
	position: relative;
	overflow: hidden;
  background-color: $light-grey;

	// __images
	&__images {
		margin: 0;
		position: relative;
		.item {
			padding: 0;
			.img-fill {
				text-align: center;
				padding: 10px;
				height: 200px;
				@media screen and (min-width: 576px) {
					height: 300px;
				}
				@media screen and (min-width: 720px) {
					height: 400px;
				}

				img {
					display: inline-block;
					position: relative;
					max-width: 100%;
					max-height: 100%;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		.next-arrow, .prev-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background: #274abb;
			border: 0 none;
			text-align: center;
			color: #FFF;
			z-index: 5;
			opacity: 1;
			transition: all 0.5s;
			outline: 0;
            box-shadow: 0px 0px 0px 9px rgb(255 255 255 / 40%);
			svg {
				top: 0;
			}
		}
		.next-arrow {
			right: 25px;
		}
		.prev-arrow {
			left: 25px;
		}
    .caption {
         display: none;
			width: 100%;
			position: relative;
			text-align: center;
			opacity: 1;
			transition: opacity 0.15s;
			&.hide {
				opacity: 0;
			}
		}
	}

	// __thumbnails
	&__thumbnails {
		position: relative;
		top: auto;
		left: 0px;
		width: 100%;
		z-index: 4;
		transition: all 0.8s;
		margin: 0;
		padding: 13px 0;
		&:before, &:after {
			content: '';
			display: block;
			width: 100px;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: 10;
			pointer-events: none;
		}
		&:before {
			left: 0;
			background: linear-gradient(to right, rgba($light-grey,1) 0%,rgba($light-grey,0) 100%);
		}
		&:after {
			right: 0;
			background: linear-gradient(to right, rgba($light-grey,0) 0%,rgba($light-grey,1) 100%);
		}
		.item {
			.img-fill {
				height: 70px;
				background: white;
				cursor:pointer;
				// border: 5px solid white;
				text-align: center;
				img {
					opacity: 0.5;
					transition: all 0.5s;

					display: inline-block;
					position: relative;
					max-width: 100%;
					max-height: 100%;
					top: 50%;
					transform: translateY(-50%);
					
				}
			}
			&:hover .img-fill img {
				opacity: 1;
			}
			&.slick-center {
				img {
					opacity: 1;
				}
			}
		}
		.next-arrow, .prev-arrow {
			position: absolute;
			width: 40px;
			height: 40px;
			top: 50%;
			transform: translateY(-50%);
			background: #274abb;
			color: #FFF;
			border: 0 none;
			z-index: 15;
			svg {
				top: 2px;
			}
		}
		.next-arrow {
			right: 0px;
		}
		.prev-arrow {
			left: 0px;
		}
	}

	// generic arrows
	.next-arrow, .prev-arrow {
    cursor: pointer;
		svg {
			width: 18px;
			height: 18px;
			position: relative;
		}
	}

	// image fills
	.img-fill {
		width: 100%;
		display: block;
		overflow: hidden;
		position: relative;
		text-align: center
	}

	// slick overrides
	.slick-slider {
		margin: 0 -7.5px;
		padding: 0!important;
	}
	.slick-slide {
		float: left;
		padding: 0 7.5px;
    outline: none;
	}

}