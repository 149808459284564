.first-footer .top-footer {
    background-color: #ebecec !important;
}

.first-footer h3 {
    color: #000 !important;
}

.first-footer .netabout p {
    color: rgba(0, 0, 0, 0.8) !important;
}