.section-home {
    .title {
        font-weight: 300;
        font-size: 36px;
        font-family: 'Roboto condensed';
    }
}

.services-home {
    background: linear-gradient(rgba(33, 40, 44, 0.4), rgba(33, 40, 44, 0.4)), url(../images/home/bg-counter.jpg) no-repeat center center;
    color: #fff;
    font-size: 62px;
    font-weight: bold;
    line-height: 1;
    padding: 10rem 0 !important;

    .first-title {
        font-size: 52px;
    }

    span {
        color: map-get($colors, 'yellow');
    }

    .contact-button {
        text-align: right;
    }

    a.btn {
        font-size: 18px;
        font-weight: bold;
    }
    
    a.btn-default {
        background-color: #ffd33f;
        border-radius: 0;
        padding: 10px;
    }
}

.property-type-home {
    font-family: 'Roboto Condensed';

    .property-home {
        padding-bottom: 10px;
        border-bottom: 1px solid #979797;
    }

    * {
        font-family: 'Roboto Condensed';
    }

    .media-type {
        position: relative;
        background-color: map-get($colors, 'grey');

        h4 {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 16px;
            background-color: rgba(0, 0, 0, .6);
            color: #fff;
            font-weight: bold;
            font-size: 30px;
        }
    }

    .property-links {
        a {
            color: #000;
            text-transform: uppercase;
            font-size: 20px;
            display: inline-block;
            padding: 0 20px;
            position: relative;

            &:first-child::after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 18px;
                background-color: #000;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.contact-home {
    background-attachment: fixed;
    background: linear-gradient(rgba(33, 40, 44, 0.8), rgba(33, 40, 44, 0.4)), url(../images/home/bg-contact.jpg) no-repeat center center;
    background-size: cover;
    color: #fff;
    font-size: 30px;

    .contact-title {
        font-family: 'Roboto Condensed';
    }

    .contact-subtitle {
        font-weight: bold;
        font-size: 34px;
    }

    .contact-form-home {
        padding: 40px 60px;
        background-color: rgba(3,49,97, .8);

        label {
            display: none;
        }
    }

    .form-control {
        border-radius: 0;
    }

    .btn-default {
        background-color: #ffd33f;
        border-radius: 0;
    }
}