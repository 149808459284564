.widget-socials {
    padding: 0;


    li {
        padding-right: 20px;
    }

    a {
        color: #08519c;
    }
}