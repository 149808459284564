.portfolio .homes-content ul, .portfolio .homes-content p, .portfolio .homes-content h3 {
    padding: 0 20px !important;
}

.portfolio .footer {
    padding: 0 20px !important;
    background-color: #f0f1f1;
}

.inner-pages section.portfolio, section.headings-2 {
    background: #fff !important;
}

section.single-proper {
    background: #fff !important;
}

.inner-pages .blog .blog-info.details, .listing-details-sliders.mb-30, .homes-content.details.mb-30, .property-location, .reviews.comments, .reviews.leve-comments, .wprt-image-video.w50.pro, .ag-de .similar-property, .ag-de .portfolio.py-0.age, .det .similar-property {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.inner-pages .blog .homes-content h5::after, .inner-pages .blog .blog-info.details h5::after, .inner-pages .blog .property-location h5::after, .inner-pages .blog .team.assigned h5::after, .inner-pages .carousel h5::after {
    background-color: #f0b900 !important;
}

.inner-pages .blog .homes-content h5, .inner-pages .blog .property-location h5, .inner-pages .blog .team.assigned h5, .similar-property h5, .ag-de .blog-section h5, .inner-pages .carousel h5, .inner-pages .blog .blog-info.details h5, .inner-pages .carousel h5 {
    color: #3F4950 !important;
}




.second-footer {
    background-color: #3f4950 !important;
}

a.button.border, a.button {
    font-size: 14px !important;
}

.homes-tag.featured {
    width: 120px !important;
}

.portfolio .homes-content .homes-list li {
    text-align: center;
}

.portfolio .homes-content .homes-list li span {
    color: #08519c !important;
}

.portfolio .homes-content .homes-list li img {
    display: block;
    margin: 0 auto;
}

.inner-pages .blog .homes-content.details span {
    color: #3F4950 !important;
    font-weight: lighter !important;
}

.inner-pages .blog .blog-info.details, .listing-details-sliders.mb-30, .homes-content.details.mb-30, .property-location, .reviews.comments, .reviews.leve-comments, .wprt-image-video.w50.pro, .ag-de .similar-property, .ag-de .portfolio.py-0.age, .det .similar-property {
    border-color: #DBDDDF !important;
}

.inner-pages .blog .blog-info.details {
    border-bottom: 1px solid #DBDDDF !important;
}

.widget-boxed {
    background-color: #fafafa !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.author__whatsapp a {
    color: #4caf50;
    font-weight: bold;
}

.featured-properties-title {
    padding: 22px 0;
    font-weight: 300;
    background-color: #FFD33F;
    margin-bottom: 20px;
}

section.headings-2 {
    padding-bottom: 15px;
}

.listing-details-sliders.mb-30 {
    background-color: #f0f1f1;
    border: none;
    padding: 10px !important;
}

.mini-description {
    font-size: 12px;
    color: #58626A;
}

.project-single .homes-list li {
    width: 25% !important;
}

.inner-pages .blog .homes-content.details .primary-properties li span {
    color: #08519c !important;
}

.details p {
    color: #3F4950 !important;
    font-weight: lighter !important;
}

.primary-properties {
    
}

.primary-properties ul {
    padding: 0;
}

.primary-properties li {
    list-style: none;
    width: 24%;
    display: inline-block;
    text-align: center;
}



.property-bs {
    border-bottom: 1px solid #DBDDDF;
}

.property-ts {
    border-top: 1px solid #DBDDDF;
}

.primary-properties li img {
    display: block;
    margin: 0 auto;
}

/** TABS **/
.details .nav-tabs {
    border-bottom: 1px solid #DBDDDF !important;
}

.details .nav-tabs .nav-link {
    border: none;
    position: relative;
    color: #06417e;
    font-size: 16px;
    display: inline-block;
    padding: 1em !important;
}

/* .details .nav-tabs .nav-link.active, .details .nav-tabs .nav-item.show .nav-link {
    border: none;
    position: relative;
    color: #06417e;
    font-size: 16px;
    display: inline-block;
} */

.details .nav-tabs .nav-link.active::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #f0b900;
}

.details .tab-content {
    padding: 0 1.5em 1.5em 1.5em;
}

/** END TABS **/

.inner-pages .blog .blog-info.details, .listing-details-sliders.mb-30, .homes-content.details.mb-30, .property-location, .reviews.comments, .reviews.leve-comments, .wprt-image-video.w50.pro, .ag-de .similar-property, .ag-de .portfolio.py-0.age, .det .similar-property {
    padding: 0 !important;
}

/** Agencies **/
.agencies-page {

}

.agencies-page .page-title, .generic-page .page-title {
    text-transform: none;
    font-weight: 300;
    padding: 20px 0;
    background-color: #fafafa;
    width: 100%;
    font-family: 'Roboto condensed';
}

.agencies-page .agency {
    padding: 15px;
    border: 1px solid #DBDDDF;
    margin: 5px 0;
}

.agencies-page .agency .agency__name {
    font-weight: bold;
    color: #000;
}

.agencies-page .agency .agency__logo {
    text-align: center;
    max-height: 160px;

    img {
        max-height: 160px;
    }
}

.agencies-page .agency .agency__name {
    font-weight: bold;
    color: #000;
}

.widget {
    padding: 15px;
    background-color: #fafafa;
}

.amenities-filter a {
    color: #3f4950;
    font-weight: bold;
    font-size: 1.1em;
}

.amenities-filter label {
    font-size: .9em;
}

.title-filter .btn:hover {
    background-color: #121B22 !important;
}

.title-filter .btn {
    background-color: #274abb !important;
    background: #274abb !important;
}

.listing-title-bar .btn {
    color: #fff !important;
}

.agencies-page .agency .agency__logo {
    height: 160px;
}

.property-box-meta-content .item-head .item-title {
    font-size: inherit !important;
    margin-bottom: 10px !important;
}

.property-box-meta-content .item-head .item-title a {
    font-size: 22px !important;
}

.inner-pages .blog .hvr-bounce-to-right:hover {
    background: #ffd33f !important;
}

