#navigation.style-1 ul a {
    padding: 6px;
}

#navigation.style-1 .current {
    padding: 5px;
}

#navigation.style-1 ul li {
    margin: 0 5px;
}

.widget-menu-footer {
    padding: 0;
    
    @media screen and (min-width: 768px) {
        li {
            position: relative;
            
            &::before {
                content: "";
                display: inline-block;
                width: 1px;
                height: 15px;
                border: 1px solid #fff;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
    
            &:first-child {
                a {
                    margin-left: 0;
                }
    
                &::before {
                    display: none;
                }
            }
        }
    }
    
    @media screen and (max-width: 767px) {
        li {
            display: block;
            text-align: center;
        }
    }

    a {
        color: #fff;
        margin-right: 15px;
        margin-left: 15px;

        &:hover {
            text-decoration: underline;
        }
    }
}